import { Box, Grid, ListItem, Typography } from "@mui/material";
import React, { useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import { HashLoader } from "react-spinners";
import { COLORS } from "@/constants/Colors/colors";
import { api_url } from "@/constants/base_url";
import useCustomQuery from "../../../../hooks/useCustomQuery";
import { useSelector } from "react-redux";
import fileStyle from "./CourseSummaries.module.css"
import { FaAngleRight } from "react-icons/fa6";
import { FONTS } from '@/constants/Fonts/fonts';


export function CourseSummaries() {
  const { id, mid } = useParams();
  const globalState = useSelector((state) => state?.global);
  const lang = globalState?.lang;
  const isDarkMode = globalState?.isDarkMode;

  const {
    data: filesData,
    isLoading: isfilesLoading,
    error: filesError,
    refetch: refetchfiles,
  } = useCustomQuery({
    url: `drive?type=module&id=${mid}`,
    lang,
    // data:{type: "Module",id:mid},
    key: ["module files", mid, lang],
    select: (data) => data?.data?.data,
  });
  // if (isModuleFilesError)
  const empty = (
    <Typography
      fontSize={32}
      fontFamily={lang === 'en' ? FONTS.ENGLISH : ""}
      textAlign={"center"}
      sx={{
        color: globalState?.isDarkMode
          ? COLORS.WHITE
          : COLORS.DARK_GRAY,
      }}
    >
      {lang == "ar" ? "لا يوجد ملازم" : "There is no File"}
    </Typography>
  );
  const spinner = (
    <div
      style={{
        width: "100%",
        display: "flex",
        justifyContent: "center",
      }}
    >
      <HashLoader color={COLORS.PRIMARY.MAIN} />
    </div>
  );

  const content = (
    filesData?.map((file, index) => (
      <a
        className={`${fileStyle.fileCard} ${lang === 'ar' ? fileStyle.fileCardArabic : ""}`}
        key={index}
        download={file.name}
        href={api_url.slice(0, -7) + file.url}
        target="_blank"
        rel="noreferrer"
        style={{ textDecoration: "none", color: COLORS.DARK_GRAY }}
      >
        <div>
          <p>
            {index < 9 ? `0${index + 1}` : index + 1}
          </p>
          <p style={{ color: isDarkMode ? COLORS.WHITE : COLORS.BLACK }}>
            {file.name}
          </p>
        </div>
        <FaAngleRight className={isDarkMode ? fileStyle.darkSvg : ""} />

      </a>
    ))
  );

  return (
    <>
      <div>
        {isfilesLoading ? spinner : filesData?.length > 0 ? content : empty}
      </div>
    </>
  );
}

// export default CourseSummaries;
