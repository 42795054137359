import {
  Box,
  Button,
  FormHelperText,
  InputAdornment,
  TextField,
  ThemeProvider,
  Typography,
  createTheme,
} from "@mui/material";
import HomeWorkIcon from "@mui/icons-material/HomeWork";
import { COLORS } from "@/constants/Colors/colors";
import Modal from "@shared/Modal/Modal";
import { Controller, FormProvider, useForm } from "react-hook-form";
import ColorButton from "@shared/Buttons/ColorButton/ColorButton";
import { HashLoader } from "react-spinners";
import { useState } from "react";
import { currentUser } from "@shared/Helpers/encryptionHelpers";
import { useMutation } from "@tanstack/react-query";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DateTimePicker, LocalizationProvider } from "@mui/x-date-pickers";
import dayjs from "dayjs";
import axios from "axios";
import { api_url } from "@/constants/base_url";
import { Question } from "./Question&answer/Question";
import { queryClient } from "@config/queryClient.config";
import { notifyError, notifySuccess } from "@shared/Toast/toast";
import { useSelector } from "react-redux";
import { Padding } from "@mui/icons-material";

export const AddHomework = ({ module, closeParent }) => {
  const language = useSelector((state) => state?.global);
  const lang = language?.lang;
  const [open, setOpen] = useState(false);
  const [update, setUpdate] = useState(false);
  const globalState = useSelector((state) => state?.global);
  const methods = useForm();

  const {
    handleSubmit,
    register,
    formState: { errors },
    watch,
    getValues,
    control,
    setValue,
    setError,
    clearErrors,
  } = methods;
  const { mutate, isPending } = useMutation({
    mutationFn: (data) =>
      axios.post(`${api_url}homeWork/createHomeWork`, data, {
        headers: {
          Authorization: `Bearer ${currentUser()?.token}`,
          lang: lang,
        },
      }),
    onSuccess: () => {
      queryClient.invalidateQueries(["module", "homework"]);
      notifySuccess(
        lang == "ar"
          ? "تم اضافة واجب بنجاح"
          : "The Homework has been Added successfully "
      );
      setOpen(false);
      closeParent();
      queryClient.refetchQueries({
        queryKey: ["module homework", lang],
      });
    },
    onError: (err) => {
      console.log(err);
      notifyError(
        lang == "ar"
          ? "حدث خطأ ما, الرجاء المحاولة لاحقا"
          : "An error occurred while executing the task"
      );
    },
  });

  function submit(data) {
    const currentMoment = new Date();
    const startsAtMoment = new Date(data.starts_at);
    if (startsAtMoment <= currentMoment) {
      setError("starts_at", {
        type: "manual",
        message:
          lang == "ar"
            ? "يجب اختيار تاريخ في المستقبل"
            : "Choose date in future",
      });
      return;
    }
    clearErrors();

    const formData = new FormData();
    formData.append("title", data?.title);
    formData.append(
      "starts_at",
      dayjs(data.starts_at).format("YYYY-MM-DD hh:mm:ss A")
    );
    formData.append(
      "ends_at",
      dayjs(data.ends_at).format("YYYY-MM-DD hh:mm:ss A")
    );
    formData.append("lesson_module_id", module?.id);
    for (let i = 0; i < data?.questions?.length; i++) {
      data?.questions[i].title &&
        formData.append(`questions[${i}][title]`, data?.questions[i].title);
      data?.questions[i].image &&
        formData.append(`questions[${i}][image]`, data?.questions[i].image[0]);
      formData.append(`questions[${i}][grade]`, data?.questions[i].grade);
      for (let j = 0; j < data?.questions[i]?.answers?.length; j++) {
        formData.append(
          `questions[${i}][answers][${j}][title]`,
          data.questions[i].answers[j].title
        );
        formData.append(
          `questions[${i}][answers][${j}][isCorrect]`,
          Number(data?.questions[i]?.correctAnswer) === j ? 1 : 0
        );
      }
    }

    mutate(formData);
  }

  const [questionsArray, setQuestionsArray] = useState([
    <Question key={Math.random()} index={1} />,
  ]);

  return (
    <>
      <Button
        sx={{
          bgcolor: "#d0b821",
          display: "block",
          color: COLORS.WHITE,
          width: 150,
          height: 150,
          "&:hover": { bgcolor: "#d0b821", opacity: "0.9" },
        }}
        onClick={() => {
          setOpen(true);
        }}
      >
        <HomeWorkIcon fontSize="large" />
        <Typography>{lang == "ar" ? "واجب" : "Homework"}</Typography>
      </Button>

      <Modal
        zIndex={"1300"}
        open={open}
        closeFn={() => setOpen(false)}
        maxWidth={"sm"}
      >
        <FormProvider {...methods}>
          <form
            onSubmit={handleSubmit(submit)}
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              flexDirection: "column",
              gap: "20px",
              width: "60%",
              margin: "auto",
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Box style={{ width: "85%"}}>
                <ThemeProvider theme={theme}>
                  <TextField
                    inputProps={{
                      style: {
                        WebkitBoxShadow: `0 0 0 1000px ${
                          globalState?.isDarkMode ? "transparent" : COLORS.WHITE
                        } inset`,
                        WebkitTextFillColor: globalState?.isDarkMode
                          ? COLORS.WHITE
                          : "#2a2a2a",
                      },
                    }}
                    {...register("title", {
                      required:
                        lang == "ar"
                          ? "ادخل اسم الواجب"
                          : "Enter Homework name",
                    })}
                    error={errors?.title}
                    helperText={errors?.title?.message}
                    placeholder={
                      lang == "ar" ? "ادخل اسم الواجب" : "Enter homework name"
                    }
                    label={lang == "ar" ? "اسم الواجب" : "Homework name"}
                    size="small"
                    fullWidth
                    sx={{
                      "& .MuiFormLabel-root.Mui-focused": {
                        color: "#2f8bc8",
                      },
                      "& label": {
                        left: lang == "ar" ? "unset" : "0",
                        right: lang == "ar" ? "1.75rem" : "unset",
                        transformOrigin: lang == "ar" ? "right" : "left",
                        fontSize: "0.8rem",
                        color: "#2f8bc8",
                       },
                      "& legend": {
                        textAlign: lang == "ar" ? "right" : "left",
                        fontSize: "0.6rem",
                      },
                      direction: "rtl",
                    }}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="end"></InputAdornment>
                      ),
                    }}
                  />
                </ThemeProvider>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                <Box sx={{ width: "50%", mt: "10px", mb: "10px" }}>
                  <ThemeProvider theme={theme}>
                    <Controller
                      control={control}
                      name="starts_at"
                      rules={{
                        required:
                          lang == "ar"
                            ? "ادخل تاريخ البداية"
                            : "Enter start date",
                      }}
                      render={({ field }) => (
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                          <DateTimePicker
                            disablePast
                            sx={{
                              // flexDirection: "row-reverse",
                              "& .MuiButtonBase-root": {
                                position: "absolute",
                                left: 0,
                              },
                              "& .MuiInputBase-input": {
                                textAlign: "right",
                              },
                              "& .MuiFormLabel-root.Mui-focused": {
                                color: globalState?.isDarkMode
                                  ? COLORS.WHITE
                                  : "#2f8bc8",
                              },
                              "& label": {
                                left: lang == "ar" ? "unset" : "0",
                                right: lang == "ar" ? "1.75rem" : "unset",
                                transformOrigin:
                                  lang == "ar" ? "right" : "left",
                                fontSize: "0.8rem",
                                color: "#2f8bc8",
                                       },
                              "& legend": {
                                textAlign: lang == "ar" ? "right" : "left",
                                fontSize: "0.6rem",
                              },
                              "& input::placeholder": {
                                textAlign: "right",
                              },
                              direction: "ltr",
                            }}
                            InputProps={{
                              style: {
                                display: "flex",
                                flexDirection: "row-reverse",
                                justifyContent: "space-between",
                                alignItems: "center",
                              },
                            }}
                            placeholder={
                              lang == "ar"
                                ? "ادخل  تاريخ البداية"
                                : "Enter start date"
                            }
                            label={lang == "ar" ? "من" : "from"}
                            value={field.value}
                            onChange={field.onChange}
                            renderInput={(params) => (
                              <TextField
                                inputProps={{
                                  style: {
                                    WebkitBoxShadow: `0 0 0 1000px ${
                                      globalState?.isDarkMode
                                        ? COLORS.DARK_GRAY
                                        : COLORS.WHITE
                                    } inset`,
                                    WebkitTextFillColor: globalState?.isDarkMode
                                      ? COLORS.WHITE
                                      : "#2a2a2a",
                                  },
                                }}
                                {...params}
                              />
                            )}
                            {...field}
                          />
                        </LocalizationProvider>
                      )}
                    />
                  </ThemeProvider>
                  {errors?.starts_at && (
                    <FormHelperText sx={{ color: "red", textAlign: "right" }}>
                      {errors?.starts_at?.message}
                    </FormHelperText>
                  )}
                </Box>
                <Box sx={{ width: "47%", mt: "20px", mb: "20px" }}>
                  <ThemeProvider theme={theme}>
                    <Controller
                      control={control}
                      name="ends_at"
                      rules={{
                        required:
                          lang == "ar"
                            ? "ادخل تاريخ النهاية"
                            : "Enter end date",
                      }}
                      render={({ field, formState }) => (
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                          <DateTimePicker
                            disablePast
                            minDate={dayjs(watch("starts_at")).add(1, "day")}
                            sx={{
                              // flexDirection: "row-reverse",
                              "& .MuiButtonBase-root": {
                                position: "absolute",
                                left: 0,
                              },
                              "& .MuiInputBase-input": {
                                textAlign: "right",
                              },
                              "& .MuiFormLabel-root.Mui-focused": {
                                color: "#2f8bc8",
                              },
                              "& label": {
                                left: lang == "ar" ? "unset" : "0",
                                right: lang == "ar" ? "1.75rem" : "unset",
                                transformOrigin:
                                  lang == "ar" ? "right" : "left",
                                fontSize: "0.8rem",
                                color: "#2f8bc8",
                                       },
                              "& legend": {
                                textAlign: lang == "ar" ? "right" : "left",
                                fontSize: "0.6rem",
                              },
                              "& input::placeholder": {
                                textAlign: "right",
                              },
                              direction: "ltr",
                            }}
                            InputProps={{
                              style: {
                                display: "flex",
                                flexDirection: "row-reverse",
                                justifyContent: "space-between",
                                alignItems: "center",
                              },
                            }}
                            placeholder={
                              lang == "ar"
                                ? "ادخل  تاريخ النهاية"
                                : "Enter end date"
                            }
                            label={lang == "ar" ? "الى" : "To"}
                            value={field.value}
                            onChange={(event) => {
                              field.onChange(event);
                            }}
                            renderInput={(params) => (
                              <TextField
                                error={formState.errors?.ends_at}
                                helperText={formState.errors?.ends_at?.message}
                                inputProps={{
                                  style: {
                                    // color: "black",
                                    WebkitBoxShadow: "0 0 0 1000px white inset",
                                    WebkitTextFillColor: "#2a2a2a",
                                  },
                                }}
                                {...params}
                              />
                            )}
                            {...field}
                          />
                        </LocalizationProvider>
                      )}
                    />
                  </ThemeProvider>
                  {errors?.ends_at && (
                    <FormHelperText sx={{ color: "red", textAlign: "right" }}>
                      {errors?.ends_at?.message}
                    </FormHelperText>
                  )}
                </Box>
              </Box>
              {questionsArray.map((item, index) => (
                <Question
                  key={index}
                  watch={watch}
                  register={register}
                  errors={errors}
                  index={index}
                  getValues={getValues}
                  control={control}
                  setUpdate={setUpdate}
                />
              ))}
            </Box>
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                gap: "0.5rem",
              }}
            >
              <Box
                sx={{
                  my: "30px",
                }}
              >
                <ColorButton
                  variant="contained"
                  sx={{ bgcolor: "#2f8bc8", marginLeft: "20px" }}
                  onClick={() => {
                    // if (questionsArray.length >= 4) return;

                    setQuestionsArray([
                      ...questionsArray,
                      <Question
                        key={Math.random()}
                        watch={watch}
                        register={register}
                        errors={errors}
                        index={Math.random()}
                        getValues={getValues}
                        control={control}
                        setUpdate={setUpdate}
                      />,
                    ]);
                  }}
                >
                  {lang == "ar" ? " اضف سؤال" : "Add question"}
                </ColorButton>
              </Box>
              <Box
                sx={{
                  my: "30px",
                }}
              >
                <ColorButton
                  variant="contained"
                  sx={{ bgcolor: "#2f8bc8", marginLeft: "20px" }}
                  onClick={() => {
                    if (questionsArray.length <= 1) return;

                    setQuestionsArray(questionsArray.slice(0, -1));
                    const allQuestions = getValues("questions");
                    setValue("questions", allQuestions.slice(0, -1));
                  }}
                >
                  {lang == "ar" ? "احذف سؤال" : "Delete question"}
                </ColorButton>
              </Box>
            </Box>
            <Box
              sx={{
                my: "30px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              {console.log(errors)}
              <ColorButton
                disabled={
                  isPending ||
                  (Object.keys(errors).length !== 0 &&
                    Object.keys(errors)[0] !== "starts_at")
                }
                type="submit"
                variant="contained"
                sx={{ bgcolor: "#2f8bc8", marginLeft: "20px" }}
              >
                {isPending ? (
                  <HashLoader color={COLORS.WHITE} size={20} />
                ) : lang == "ar" ? (
                  "حفظ الواجب"
                ) : (
                  "Save homework"
                )}
              </ColorButton>
            </Box>
          </form>
        </FormProvider>
      </Modal>
    </>
  );
};

const theme = createTheme({
  components: {
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          "& .MuiOutlinedInput-notchedOutline": {
            border: "1px solid #2f8bc8", // Change the border color
          },
          "&:hover .MuiOutlinedInput-notchedOutline": {
            border: "1px solid #2f8bc8", // Change the border color on hover
          },
          "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
            border: "2px solid #2f8bc8", // Change the border color when focuu
          },
        },
      },
    },
  },
});
