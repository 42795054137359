import React, { useState } from "react";
import { useParams } from "react-router-dom";
import { Box, Typography, IconButton } from "@mui/material";
import { COLORS } from "@/constants/Colors/colors";
import DeleteIcon from "@mui/icons-material/Delete";
import { DeleteCourseFile } from "../DeleteCourseFile";
import { useSelector } from 'react-redux';
import { FaTrashAlt } from "react-icons/fa";
import fileStyle from "./CourseFileBox.module.css"

export default function CourseFileBox({ item, index }) {
  const globalState = useSelector((state) => state?.global);
  const lang = globalState?.lang;
  const isDarkMode = globalState?.isDarkMode;

  const [del, setDel] = useState(false);
  const { id } = useParams();
  return (
    <>
      <div
        className={fileStyle.fileCard}
        key={item.id}
        style={{backgroundColor: isDarkMode ? COLORS.DARK_GRAY : COLORS.LIGHT_GRAY}}
      >
        <div>
          <p>{index < 9 ? "0" + (index + 1) : index + 1}</p>
          <p>{item.name}</p>
        </div>

        <button
          onClick={() => setDel(true)}
        >
          <FaTrashAlt />
        </button>
      </div>
      <DeleteCourseFile
        // filename={item?.url?.split("/")?.pop()}
        filename={item?.name}
        id={item?.id}
        isConfirmOpen={del}
        setIsConfirmOpen={setDel}
        confirmMessage={
          lang == "ar" ? `هل تريد حذف ملف ` : "Do you want to delete a file?"
        }
        deleteName={item.name}
      />
    </>
  );
}
