// import { CourseHeader } from "../../components/CourseHeader";
// import { Box } from "@mui/material";
// import { CourseModules } from "../../components/CourseModules";
// import { CourseFiles } from "../../components/CourseFiles";
// import { CourseExams } from "../../components/CourseExams";
// import { CourseStudents } from "../../components/CourseStudents";
// import useCustomQuery from "@/hooks/useCustomQuery";
// import { useParams } from "react-router-dom";

// export const Course = () => {
//   const { id } = useParams();
//   const { data, isPending } = useCustomQuery({
//     url: `course/courseData?id=${id}`,
//     key: ["course", id],
//     method: "post",
//   });
//   return (
//     <Box sx={{ width: "100%", minHeight: "100vh" }}>
//       <CourseHeader data={data} isPending={isPending} />
//       <Box
//         sx={{
//           width: "100%",
//           minHeight: 400,
//           display: "flex",
//           alignItems: "center",
//           gap: 5,
//         }}
//       >
//         <CourseModules course={data} />
//         <Box
//           sx={{
//             display: "flex",
//             flexDirection: "column",
//             gap: 5,
//             width: "50%",
//             alignItems: "center",
//           }}
//         >
//           <CourseFiles />
//           <CourseExams />
//         </Box>
//       </Box>
//       <CourseStudents courseData={data} />
//     </Box>
//   );
// };






import { CourseHeader } from "../../components/CourseHeader/CourseHeader";
import { Box } from "@mui/material";
import { CourseModules } from "../../components/CourseModules/CourseModules";
import { CourseFiles } from "../../components/CourseFiles/CourseFiles";
import { CourseExams } from "../../components/CourseExams/CourseExams";
import { CourseStudents } from "../../components/CourseStudents";
import useCustomQuery from "@/hooks/useCustomQuery";
import { useParams } from "react-router-dom";
import pageStyle from "./Course.module.css";
import { COLORS } from "@/constants/Colors/colors";
import { FONTS } from "@/constants/Fonts/fonts";
import { useSelector } from 'react-redux';
import { useState } from "react";

export const Course = () => {
  const globalState = useSelector((state) => state?.global);
  const lang = globalState?.lang;
  const isDarkMode = globalState?.isDarkMode;

  const [tabs, setTabs] = useState("lessons")

  const { id } = useParams();
  const { data, isPending } = useCustomQuery({
    url: `course/courseData?id=${id}`,
    key: ["course", id],
    method: "post",
  });
  return (
    <div className={pageStyle.mainContainer} style={{ fontFamily: lang === 'en' ? FONTS.ENGLISH : "" }}>
      <div className={pageStyle.pageContainer}>
        <div>
          <section style={{ backgroundColor: isDarkMode ? COLORS.SOFT_DARK : COLORS.WHITE }}>
            <CourseHeader data={data} isPending={isPending} />
          </section>

          <section style={{ backgroundColor: isDarkMode ? COLORS.SOFT_DARK : COLORS.WHITE }}>
            <div className={pageStyle.tabs}>
              <ul style={{ margin: lang === 'ar' ? "0 20px 0" : "0 0 0 20px" }}>
                <li className={tabs === 'lessons' ? pageStyle.active : ""} onClick={() => setTabs("lessons")}>{lang === 'ar' ? "الدروس" : "Lessons"}</li>
                <li className={tabs === 'files' ? pageStyle.active : ""} onClick={() => setTabs("files")}>{lang === 'ar' ? "الملفات" : "Files"}</li>
                <li className={tabs === 'exams' ? pageStyle.active : ""} onClick={() => setTabs("exams")}>{lang === 'ar' ? "الإمتحانات" : "Exams"}</li>
              </ul>
            </div>
            <div className={pageStyle.page}>
              {tabs === "lessons" && <CourseModules course={data} />}
              {tabs === "files" && <CourseFiles />}
              {tabs === "exams" && <CourseExams />}
            </div>
          </section>
        </div>
        <div>
          <CourseStudents courseData={data} />
        </div>
      </div>
    </div>
  );
};
