// import { TextField, IconButton } from "@mui/material";
// import { useFormContext } from "react-hook-form";
// import { COLORS } from "@/constants/Colors/colors";
// import { useState } from "react";
// import { VisibilityOffOutlined, VisibilityOutlined } from "@mui/icons-material";

// export default function BorderedInput(props) {
//   const { Icon, registerName, placeholder, type = "text", lang } = props;
//   const {
//     register,
//     formState: { errors },
//   } = useFormContext();

//   const [innerType, setInnerType] = useState("password");

//   return (
//     <TextField
//       inputProps={{
//         style: {
//           WebkitBoxShadow: "0 0 0 1000px white inset",
//           WebkitTextFillColor: COLORS.DARK_GRAY,
//         },
//       }}
//       {...register(registerName)}
//       error={!!errors[registerName]}
//       helperText={errors[registerName]?.message}
//       placeholder={placeholder}
//       type={type === "password" ? innerType : type}
//       sx={{
//         width: "100%",
//         "& .MuiFormLabel-root.Mui-focused": {
//           color: COLORS.PRIMARY.MAIN,
//         },
//         "& label": {
//           left: lang == "ar" ? "unset" : "1.75rem",
//           right: lang == "ar" ? "1.75rem" : "unset",
//           transformOrigin: lang == "ar" ? "right" : "left",
//           fontSize: "0.8rem",
//           color: "#ED7014",
//         },
//         "& legend": {
//           textAlign: lang == "ar" ? "right" : "left",
//           fontSize: "0.6rem",
//         },
//         backgroundColor: COLORS.WHITE,
//         direction: lang == "ar" ? "rtl" : "ltr",
//       }}
//       size="small"
//       InputProps={{
//         endAdornment: (
//           <>
//             {type === "password" && (
//               <IconButton
//                 edge="end"
//                 onClick={() =>
//                   setInnerType((t) => (t === "password" ? "text" : "password"))
//                 }
//               >
//                 {innerType === "password" ? (
//                   <VisibilityOutlined />
//                 ) : (
//                   <VisibilityOffOutlined />
//                 )}
//               </IconButton>
//             )}
//             {!!Icon && (
//               <span style={{ paddingLeft: 10, paddingTop: 5 }}>
//                 <Icon />
//               </span>
//             )}
//           </>
//         ),
//       }}
//     />
//   );
// }















import React, { useState, useEffect } from 'react';
import { useFormContext } from "react-hook-form";
import { COLORS } from "@/constants/Colors/colors";
import inputStyle from "./BorderedInput.module.css";
import { VisibilityOffOutlined, VisibilityOutlined } from "@mui/icons-material";

export default function BorderedInput(props) {
  const { Icon, registerName, placeholder, type = "text", lang, isDarkMode, color } = props;
  const { register, formState: { errors } } = useFormContext();

  const [innerType, setInnerType] = useState(type);
  const [isFocused, setIsFocused] = useState(false);
  const [isNotEmpty, setIsNotEmpty] = useState(false);

  useEffect(() => {
    const input = document.querySelector(`input[name="${registerName}"]`);
    setIsNotEmpty(input.value !== '');
  }, [registerName]);

  const handleBlur = (event) => {
    setIsFocused(false);
    setIsNotEmpty(event.target.value !== '');
  };

  return (
    <div className={inputStyle.container}>
      {errors[registerName] && (
        <div className={inputStyle.errorMessage}>
          {errors[registerName]?.message}
        </div>
      )}
      <div>
        <div className={`${inputStyle.inputContainer} ${type === 'password' ? inputStyle.password : ""}`}
          style={{
            borderColor: errors[registerName] ? COLORS.DANGER : isFocused || isNotEmpty ? color : '',
          }}
        >
          <input
            {...register(registerName)}
            type={innerType}
            placeholder={placeholder}
            style={{
              direction: lang === 'ar' ? 'rtl' : 'ltr',
              color: isDarkMode ? COLORS.BLACK : COLORS.WHITE,
            }}
            onFocus={() => setIsFocused(true)}
            onBlur={handleBlur}
          />
          {type === 'password' && (
            <span
              className={inputStyle.showHide}
              onClick={() => setInnerType(innerType === 'password' ? 'text' : 'password')}
            >
              {innerType === 'password' ? <VisibilityOutlined style={{ color: isFocused || isNotEmpty ? color : "" }} /> : <VisibilityOffOutlined style={{ color: isFocused || isNotEmpty ? color : "" }} />}
            </span>
          )}
        </div>
        {!!Icon && <Icon className={inputStyle.mainIcon} style={{ color: isFocused || isNotEmpty ? color : "" }} />}
      </div>
    </div>
  );
}

















export const CustomBorderedInput = ({ Icon, errors, type = "text", isDarkMode, children, color }) => {
  const [innerType, setInnerType] = useState(type);

  const [isFocused, setIsFocused] = useState(false);
  const [isNotEmpty, setIsNotEmpty] = useState(false);

  const handleFocus = () => {
    setIsFocused(true);
  };

  const handleBlur = (e) => {
    setIsFocused(false);
    setIsNotEmpty(e.target.value.trim() !== "");
  };


  return (
    <div className={inputStyle.container}>
      {errors && (
        <div className={inputStyle.errorMessage}>
          {errors.message}
        </div>
      )}

      <div>
        <div className={`${inputStyle.inputContainer} ${type === 'password' ? inputStyle.password : ""}`}
          style={{ borderColor: isFocused || isNotEmpty ? color : '' }}
        >

          {React.cloneElement(children, {
            type: innerType, onFocus: handleFocus, onBlur: handleBlur, ...(children.type === 'select' && {
              style: {
                ...children.props.style,
                color: isNotEmpty ? isDarkMode ? COLORS.BLACK : COLORS.WHITE : 'rgba(128, 128, 128, 0.5)',
              }
            })
          })}

          {type === 'password' && (
            <span
              className={inputStyle.showHide}
              onClick={() => setInnerType(innerType === 'password' ? 'text' : 'password')}
              style={{ color: isFocused || isNotEmpty ? color : '' }}
            >
              {innerType === 'password' ? <VisibilityOutlined /> : <VisibilityOffOutlined />}
            </span>
          )}
        </div>
        {!!Icon && <Icon className={inputStyle.mainIcon} style={{ color: isFocused || isNotEmpty ? color : '' }} />}
      </div>
    </div>
  );
};