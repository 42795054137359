export const getNameValidationRules = (lang) => ({
    required: lang === "ar" ? "يجب ادخال الاسم" : "You must enter a name",
    validate: (name) => {
        const words = name.split(" ");
        const validWords = words.filter((word) => word.trim() !== "");
        if (validWords.length !== 3 && lang !== "ar") {
            return "The name cannot be less or more than 3 words";
        }
        if (validWords.length !== 3 && lang === "ar") {
            return "لا يمكن أن يكون الاسم أقل أو أكثر من 3 كلمات";
        }
        return true;
    },
});


export const getStudentNumberValidationRules = (lang) => ({
    required:
        lang == "ar"
            ? `يجب ادخال رقم الطالب`
            : `You must enter the student number`,
    maxLength: {
        value: 11,
        message:
            lang == "ar"
                ? "يجب ان يكون 11 رقم فقط"
                : "It should only be 11 numbers",
    },
    minLength: {
        value: 11,
        message:
            lang == "ar"
                ? "يجب ان يكون 11 رقم فقط"
                : "It should only be 11 numbers",
    },
    pattern: {
        value: /^(011|010|012|015)\d{8}$/,
        message:
            lang == "ar"
                ? "الرقم غير صحيح"
                : "The figure is incorrect",
    },
});


export const getParentNumberValidationRules = (lang) => ({
    required:
        lang == "ar"
            ? `يجب ادخال رقم ولي الامر`
            : `You must enter the guardian's number.`,
    maxLength: {
        value: 11,
        message:
            lang == "ar"
                ? "يجب ان يكون 11 رقم فقط"
                : "It should only be 11 numbers",
    },
    minLength: {
        value: 11,
        message:
            lang == "ar"
                ? "يجب ان يكون 11 رقم"
                : "It should only be 11 numbers",
    },
    // pattern: {
    //     value: /^(011|010|012|015)\d{8}$/,
    //     message: "الرقم غير صحيح",
    // },
    validate: (value, data) => {
        if (value === data.phone) {
            return lang == "ar"
                ? "رقم ولي الامر لا يمكن ان يساوي رقم الطالب"
                : "The guardian's number cannot be equal to the student's number.";
        } else {
            return true;
        }
    },
});



export const getCityValidationRules = (lang) => ({
    required: lang === "ar" ? "يجب اختيار المحافظة" : "You must choose the City",
    validate: (value) => {
        if (lang === "ar" && (value === "" || value === "اختر المحافظة")) {
            return "يجب اختيار المحافظة";
        } else if (value === "") {
            return "You must choose the City";
        }
        return true;
    }
});



export const getEmailValidationRules = (lang) => ({
    required:
        lang == "ar"
            ? `يجب ادخال البريد الالكتروني`
            : `You must enter an email address`,
    pattern: {
        value:
            /^[a-zA-Z0-9._%+-]*[a-zA-Z][a-zA-Z0-9._%+-]*@[a-zA-Z0-9.-]*[a-zA-Z][a-zA-Z0-9.-]*\.[a-zA-Z]{2,}$/i,
        message:
            lang == "ar"
                ? "البريد الاكتروني غير صالح"
                : "Email is invalid",
    },
});


export const getPasswordValidationRules = (lang) => ({
    required:
        lang == "ar"
            ? `يجب ادخال كلمة السر`
            : "You should Enter Password",
    minLength: {
        value: 8,
        message:
            lang == "ar"
                ? "يجب ادخال علي الاقل 8 قيم"
                : "You must enter at least 8 values",
    },
});

export const getPasswordConfirmationValidationRules = (lang, password) => ({
    required:
        lang == "ar"
            ? `يجب ادخال تأكيد كلمة السر`
            : `You must enter a confirmation password`,
    minLength: {
        value: 8,
        message:
            lang == "ar"
                ? "يجب ادخال علي الاقل 8 قيم"
                : "You must enter at least 8 values",
    },
    validate: (value) => {
        if (value !== password && lang !== "ar") {
            return "Password must match";
        }
        if (value !== password && lang === "ar") {
            return "يجب أن تكون كلمة السر متطابقة";
        }
        return true;
    },


});