import { Box, Typography } from "@mui/material";
import { ClipLoader } from "react-spinners";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import { COLORS } from "@/constants/Colors/colors";
export default function Number({ name, value, hex, icon: Icon = null }) {
  const globalState = useSelector((state) => state?.global);

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        flexWrap: "wrap",
        padding: "30px",
        borderRadius: "0.9rem",
        bgcolor: globalState?.isDarkMode ? COLORS.SOFT_DARK : COLORS.WHITE,
        width: "100%",
      }}
    >
      {Icon && <Icon sx={{ color: hex, fontSize: "3.2rem" }} />}
      <Box
        sx={{
          maxWidth: "100%",
          width: "80%",
          display: "flex",
          flexDirection: "column",
          flexWrap: "wrap",
          alignItems: "center",
          justifyContent: "space-around",
        }}
      >
        {value === undefined && <ClipLoader color={hex} size={60} />}
        {value !== undefined && (
          <Typography
            sx={{
              fontSize: "2.3rem",
              fontWeight: "bold",
              color: hex,
              fontFamily: "Noor-regular",
            }}
          >
            {value}
          </Typography>
        )}
        <Typography sx={{ fontSize: "1.2rem", fontFamily: "Noor-regular" }}>
          {name}
        </Typography>
      </Box>
    </Box>
  );
}

Number.propTypes = {
  name: PropTypes.string,
  value: PropTypes.number,
  hex: PropTypes.string,
  icon: PropTypes.any,
};
