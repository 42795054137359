import { FormControlLabel, Checkbox } from "@mui/material";
import { FONT_SIZES } from "@/constants/Fonts/font_sizes";
import { FONTS } from "@/constants/Fonts/fonts";
import { COLORS } from "@/constants/Colors/colors";

export default function ColorCheckBox({ label, lang, color }) {
  return (
    <FormControlLabel
      label={label}
      control={<Checkbox />}
      sx={{
        mr: -1,
        "& .MuiFormControlLabel-label": {
          fontSize: FONT_SIZES.SMALL,
          fontFamily: lang === 'ar' ? FONTS.BOLD : FONTS.ENGLISH,
          color: color
        },
        "& .MuiSvgIcon-root": { fontSize: FONT_SIZES.REGULAR },
      }}
    />
  );
}
