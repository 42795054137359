import React from "react";
import { COLORS } from "@/constants/Colors/colors";
import CloseIcon from "@mui/icons-material/Close";
import SendIcon from "@mui/icons-material/Send";
import { Box, Button, IconButton, Typography } from "@mui/material";
import ColorButton from "@shared/Buttons/ColorButton/ColorButton";
import useCustomMutation from "@/hooks/useCustomMutation";
import { useQueryClient } from "@tanstack/react-query";
import { useEffect, useState } from "react";
import { HashLoader } from "react-spinners";
import Modal from "@shared/Modal/Modal";
import { useSelector } from 'react-redux';

export default function WalletBox({ open, setOpen, course }) {
  const close = () => setOpen(false);
  const queryClient = useQueryClient();

  const globalState = useSelector((state) => state?.global);
  const lang = globalState?.lang;
  const isDarkMode = globalState?.isDarkMode;



  const { mutate, isPending } = useCustomMutation({
    url: `subscription/subscribeByMoney`,
    invalidate: ["mySubscription", "courses"],
    successMsg:
      lang == "ar" ? "تم الاشتراك بنجاح" : "You have successfully subscribed",
    onSuccess: () => {
      queryClient.invalidateQueries(["mySubscription", "courses"]);
      close();
    },
  });
  return (
    <Box
      sx={{
        position: "fixed",
        top: 0,
        left: 0,
        zIndex: 2000,
        bgcolor: "#11111177",
        width: "100%",
        height: "100vh",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <Box
        width={400}
        height={420}
        sx={{
          bgcolor: isDarkMode? COLORS.SOFT_DARK: COLORS.WHITE,
          borderRadius: "20px",
          position: "relative",
          flexDirection: "column",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          gap: 5,
        }}
      >
        <IconButton
          onClick={close}
          sx={{ position: "absolute", top: 5, right: 5 }}
        >
          <CloseIcon fontSize="medium" sx={{ color: COLORS.PRIMARY.MAIN }} />
        </IconButton>
        <Typography sx={{ fontSize: 20, fontFamily: "Noor-bold", position: 'absolute', transform: `translate(-50%, -50%)`, left: "50%", width: "80%", textAlign: 'center' }}>
          {`${
            lang == "ar" ? "سيتم خصم مبلغ:" : "An amount will be deducted:"
          } ${course?.price} ${
            lang == "ar" ? "لمدة اشتراك " : "For a subscription period"
          } ${course?.subscription_days} ${lang == "ar" ? "يوم" : "day"}`}
        </Typography>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            gap: "1rem",
            transform: `translateY(100%)`
          }}
        >
          {isPending ? (
            <HashLoader size={20} color={COLORS.PRIMARY.MAIN} />
          ) : (
            <ColorButton
              sx={{
                bgcolor: COLORS.PRIMARY.MAIN,
                color: "white",
                padding: "10px 20px",
                marginBottom: "20px",
                transition: "0.3s",
                width: "100%",
              }}
              onClick={() => {
                mutate({
                  subscription_type: "course",
                  course_id: course?.id,
                });
              }}
            >
              {lang == "ar" ? "تاكيد" : "Accept"}
            </ColorButton>
          )}

          <ColorButton
            sx={{
              bgcolor: COLORS.DANGER,
              color: "white",
              padding: "10px 20px",
              marginBottom: "20px",
              transition: "0.3s",
              width: "100%",
            }}
            onClick={() => close()}
          >
            {lang == "ar" ? "الغاء" : "Cancel"}
          </ColorButton>
        </Box>
      </Box>
    </Box>
  );
}
