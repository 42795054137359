// import { Box, Button, ListItem, Typography } from "@mui/material";
// import { useEffect, useState } from "react";
// import { useParams } from "react-router-dom";
// import { useQuery } from "@tanstack/react-query";
// import { CourseExam } from "./atoms/CourseExam";
// import useCustomQuery from "@/hooks/useCustomQuery";
// import { HashLoader } from "react-spinners";
// import { COLORS } from "@/constants/Colors/colors";
// import examStyle from "./CourseExamsList.module.css"

// export function CourseExamsList({ startFunc, setExamId, examId, exam }) {
//   const [countdown, setCountdown] = useState(null);
//   const [disabled, setDisabled] = useState(true);
//   const [started, setStarted] = useState(false);
//   const [diff, setDiff] = useState(null);
//   const startDate = new Date(exam?.data?.starts_at);
//   const currentDate = Date.now();
//   if (diff == null && startDate - currentDate) {
//     setDiff(startDate - currentDate);
//     setCountdown(startDate - currentDate);
//   }
//   //homework?.data?.data?.starts_at
//   useEffect(() => {
//     let timer;

//     if (diff >= 0) {
//       timer = setInterval(() => {
//         if (diff >= 0) {
//           setDiff((d) => d - 1000);
//           setCountdown((c) => c - 1);
//         }
//       }, 1000);
//     } else {
//       setDisabled(false);
//     }
//     return () => clearInterval(timer);
//   }, [diff]);

//   const formatCountdown = () => {
//     const seconds = Math.floor((diff / 1000) % 60);
//     const minutes = Math.floor((diff / 1000 / 60) % 60);
//     const hours = Math.floor((diff / (1000 * 60 * 60)) % 24);
//     const days = Math.floor(diff / (1000 * 60 * 60 * 24));

//     return `${days}d ${hours}h ${minutes}m ${seconds}s`;
//   };

//   const { mid } = useParams();

//   const {
//     isError: isExamsError,
//     isPending: isExamsLoading,
//     error: examsError,
//   } = useCustomQuery({
//     key: ["exams", mid],
//     url: `exam/getExam?lesson_module_id=${mid}`,
//     retry: (count, error) => {
//       if (error.response.data.message === "There's no exam for this lesson")
//         return false;
//       else if (count < 3) return true;
//     },
//     select: (data) => data,
//   });

//   const spinner = (
//     <div
//       style={{
//         width: "100%",
//         display: "flex",
//         justifyContent: "center",
//       }}
//     >
//       <HashLoader color={COLORS.PRIMARY.MAIN} />
//     </div>
//   );

//   const content = (
//     <div>
//       <p className={examStyle.examCard} title={exam.data.title}>
//         {
//           exam?.data?.title
//             ? exam.data.title.length > 30
//               ? `${exam.data.title.slice(0, 30)}...`
//               : exam.data.title
//             : ""
//         }
//       </p>
//         <p className={examStyle.duration}>
//           المده الزمنيه: {exam?.data?.exam_time} دقيقة
//         </p>

//       {!disabled && (
//         <div>
//           <p>
//             {exam?.data?.grade}
//           </p>
//           <span
//             style={{
//               margin: "0px 10px",
//               fontSize: "30px",
//               fontWeight: "700",
//             }}
//           >
//             {" "}
//             /{" "}
//           </span>
//           <p>
//             {/* {index} */}
//             {exam?.data?.examAnswer &&
//               exam?.data?.examAnswer.grade !== undefined
//               ? exam?.data?.examAnswer.grade || "0"
//               : "-"}
//           </p>
//         </div>
//       )}

//       <div>
//         {disabled && (
//           <div>
//             {formatCountdown()}
//           </div>
//         )}
//         {(!exam?.data?.examAnswer ||
//           exam?.data?.examAnswer.grade < exam?.data?.grade / 2) && (
//             <Button
//               onClick={() => {
//                 setStarted(true);
//               }}
//               disabled={disabled}
//             >
//               ابدأ الان
//             </Button>
//           )}
//       </div>
//     </div>
//   );
//   return (
//     <>
//       {isExamsLoading ? (
//         spinner
//       ) : started ? (
//         <CourseExam examId={examId} setStarted={setStarted} />
//       ) : (
//         content
//       )}
//     </>
//   );
// }

import { Box, Button, ListItem, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useQuery } from "@tanstack/react-query";
import { CourseExam } from "./atoms/CourseExam";
import useCustomQuery from "@/hooks/useCustomQuery";
import { HashLoader } from "react-spinners";
import { COLORS } from "@/constants/Colors/colors";
import examStyle from "./CourseExamsList.module.css";
import { useSelector } from "react-redux";
import { FONTS } from "@/constants/Fonts/fonts";
import Modal from "@shared/Modal/Modal";

export function CourseExamsList({ startFunc, setExamId, examId, exam }) {
  const globalState = useSelector((state) => state?.global);
  const lang = globalState?.lang;
  const isDarkMode = globalState?.isDarkMode;
  const [countdown, setCountdown] = useState(null);

  const [timer, setTimer] = useState({ days: 0, hours: 0, mins: 0, secs: 0 });

  const [disabled, setDisabled] = useState(true);
  const [started, setStarted] = useState(false);
  const [diff, setDiff] = useState(null);
  const startDate = new Date(exam?.data?.starts_at);
  const currentDate = Date.now();
  if (diff == null && startDate - currentDate) {
    setDiff(startDate - currentDate);
    setCountdown(startDate - currentDate);
  }

  console.log(exam?.data, "exam?.data");
  //homework?.data?.data?.starts_at

  const formatCountdown = () => {
    const seconds = Math.floor((diff / 1000) % 60);
    const minutes = Math.floor((diff / 1000 / 60) % 60);
    const hours = Math.floor((diff / (1000 * 60 * 60)) % 24);
    const days = Math.floor(diff / (1000 * 60 * 60 * 24));

    // return `${days}d ${hours}h ${minutes}m ${seconds}s`;
    setTimer({ days: days, hours: hours, mins: minutes, secs: seconds });
  };

  useEffect(() => {
    let timer;

    if (diff >= 0) {
      timer = setInterval(() => {
        if (diff >= 0) {
          setDiff((d) => d - 1000);
          setCountdown((c) => c - 1);
        }
      }, 1000);
    } else {
      setDisabled(false);
    }
    formatCountdown();
    return () => clearInterval(timer);
  }, [diff]);

  const { mid } = useParams();

  const {
    isError: isExamsError,
    isPending: isExamsLoading,
    error: examsError,
    refetch,
  } = useCustomQuery({
    key: ["exams", mid],
    url: `exam/getExam?lesson_module_id=${mid}`,
    retry: (count, error) => {
      if (error.response.data.message === "There's no exam for this lesson") {
        return false;
      } else if (count < 3) {
        return true;
      }
    },
    select: (data) => data,
  });

  const spinner = (
    <div
      style={{
        width: "100%",
        display: "flex",
        justifyContent: "center",
      }}
    >
      <HashLoader color={COLORS.PRIMARY.MAIN} />
    </div>
  );

  const content = (
    <div className={examStyle.examContainer}>
      <div className={examStyle.examHeader}>
        <p className={examStyle.examTitle} title={exam.data.title}>
          {exam?.data?.title
            ? exam.data.title.length > 30
              ? `${exam.data.title.slice(0, 30)}...`
              : exam.data.title
            : ""}
        </p>
        <div className={examStyle.duration}>
          <p>{exam?.data?.exam_time}</p>
          <span>{lang === "ar" ? "دقيقة" : "minutes"}</span>
        </div>
      </div>
      <div className={examStyle.buttonContainer}>
        {disabled ? (
          <div className={examStyle.countdownContainer}>
            {/* {formatCountdown()} */}
            <div>
              <span>{timer.days}</span>
              {lang === "ar" ? "يوم" : "days"}
            </div>
            <div>
              <span>{timer.hours}</span>
              {lang === "ar" ? "ساعة" : "hours"}
            </div>
            <div>
              <span>{timer.mins}</span>
              {lang === "ar" ? "دقيقة" : "minutes"}
            </div>
            <div>
              <span>{timer.secs}</span>
              {lang === "ar" ? "ثانية" : "seconds"}
            </div>
          </div>
        ) : !exam?.data?.examAnswer ||
          exam?.data?.examAnswer.grade < exam?.data?.grade / 2 ? (
          <button
            onClick={() => {
              setStarted(true);
            }}
            disabled={disabled}
            style={{ color: isDarkMode ? COLORS.BLACK : COLORS.WHITE }}
          >
            {lang === "ar" ? "ابدأ الآن" : "Start Now"}
          </button>
        ) : (
          <div
            className={examStyle.totalGrade}
            style={{ color: isDarkMode ? COLORS.BLACK : COLORS.WHITE }}
          >
            <p>
              {exam?.data?.examAnswer &&
              exam?.data?.examAnswer.grade !== undefined
                ? exam?.data?.examAnswer.grade || "0"
                : "-"}
            </p>
            <span>/</span>
            <p>{exam?.data?.grade}</p>
          </div>
        )}
      </div>
    </div>
  );

  return (
    <>
      {isExamsLoading ? (
        spinner
      ) : started ? (
        <CourseExam examId={examId} setStarted={setStarted} />
      ) : (
        content
      )}
    </>
  );
}
