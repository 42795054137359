// import { governorates } from "@/hooks/governorates";
// import { COLORS } from "@/constants/Colors/colors";
// import { api_url } from "@/constants/base_url";
// import { Box, Typography } from "@mui/material";
// import { useSelector } from "react-redux";

// export const UserBookOrder = ({ order }) => {
//   const globalState = useSelector((state) => state?.global);
//   const lang = globalState?.lang;
//   const formatAddress = () => {
//     return `${governorates.find((x) => x.value == order.city)?.label},${
//       order?.address
//     }`;
//   };
//   return (
//     <div>
//       {/* <img
//         style={{ height: 300, borderRadius: 6 }}
//         src={`${api_url.slice(0, -7)}${order.reservation_image}`}
//       /> */}
//       <Typography
//         sx={{
//           overflowWrap: "break-word",
//           direction: lang == "ar" ? "rtl" : "ltr",
//         }}
//       >{`${lang == "ar" ? "الاسم:" : "Name : "} ${order.name}`}</Typography>
//       <Typography
//         sx={{
//           overflowWrap: "break-word",
//           direction: lang == "ar" ? "rtl" : "ltr",
//         }}
//       >{`${lang == "ar" ? "الرقم:" : "Phone number : "} ${
//         order.phone
//       }`}</Typography>
//       <Typography
//         sx={{
//           overflowWrap: "break-word",
//           direction: lang == "ar" ? "rtl" : "ltr",
//         }}
//       >{`${lang == "ar" ? "الرقم الثاني: " : "Secondary phone number : "} ${
//         order.secondary_phone
//       }`}</Typography>
//       <Typography
//         sx={{
//           overflowWrap: "break-word",
//           direction: lang == "ar" ? "rtl" : "ltr",
//         }}
//       >{`${
//         lang == "ar" ? "العنوان : " : "Address : "
//       } ${formatAddress()}`}</Typography>
//       {/* <Typography>{`${lang == "ar" ? "رقم الحجز:" : "Reservation number :"} ${
//         order.reservation_phone
//       }`}</Typography> */}
//       <Typography
//         sx={{
//           overflowWrap: "break-word",
//           direction: lang == "ar" ? "rtl" : "ltr",
//         }}
//       >{`${lang == "ar" ? "التاريخ و الوقت: " : "Time & Date : "} ${
//         order.reservation_date
//       }`}</Typography>
//       <Typography
//         sx={{
//           overflowWrap: "break-word",
//           direction: lang == "ar" ? "rtl" : "ltr",
//         }}
//       >{`${lang == "ar" ? "عدد النسخ:" : "Quantity : "} ${
//         order.quantity
//       }`}</Typography>
//       <Typography
//         sx={{
//           textAlign: "center",
//           width: "fit-content",
//           margin: "0 auto",
//           paddingX: 2,
//           paddingY: 1,
//           borderRadius: 2,
//           bgcolor: order.isConfirmed ? COLORS.SUCCESS : "yellow",
//           color: COLORS.DARK_GRAY,
//         }}
//       >
//         {order.isConfirmed ? "تم بنجاح" : "قيد الانتظار"}
//       </Typography>
//     </div>
//   );
// };




import { governorates } from "@/hooks/governorates";
import { COLORS } from "@/constants/Colors/colors";
import { api_url } from "@/constants/base_url";
import { Box, colors, Typography } from "@mui/material";
import { useSelector } from "react-redux";
import { BsTelephone } from "react-icons/bs";
import { IoLocationOutline } from "react-icons/io5";

import cardStyle from "./UserBookOrder.module.css"

export const UserBookOrder = ({ order }) => {
  const globalState = useSelector((state) => state?.global);
  const lang = globalState?.lang;
  const isDarkMode = globalState?.isDarkMode;

  const formatAddress = () => {
    return `${governorates.find((x) => x.value == order.city)?.label}, ${order?.address}`;
  };
  return (
    <div className={cardStyle.cardContainer} style={{ backgroundColor: isDarkMode ? COLORS.SOFT_DARK : COLORS.OFF_WHITE, direction: lang === 'ar' ? 'rtl' : 'ltr' }}>
      <div className={cardStyle.cardHeader}>
        <div>
          {/* <h2>Book Name</h2>
          <p>{order.name}</p> */}
        </div>

        <div className={cardStyle.status} style={{ backgroundColor: order.isConfirmed ? `${COLORS.SUCCESS}3a` : `#fcf2a69c`, color: order.isConfirmed ? COLORS.SUCCESS : isDarkMode ? "yellow" : `#c3ab42` }}>
          {order.isConfirmed ? (lang === 'ar' ? "تم بنجاح" : "Success") : (lang === 'ar' ? "قيد الانتظار" : "Pending")}
        </div>
      </div>

      <div className={cardStyle.dateTime}>
        {order.reservation_date}
      </div>

      <div className={cardStyle.details} style={{ backgroundColor: isDarkMode ? COLORS.DARK_GRAY : COLORS.WHITE }}>
        <span>
          <div className={cardStyle.icon}><BsTelephone /></div>
          <div>
            <span>{lang==='ar' ? "هاتف1" : "Phone1"}: </span>
            0{order.phone}
          </div>
        </span>
        <span>
          <div className={cardStyle.icon}><BsTelephone /></div>
          <div>
            <span>{lang==='ar' ? "هاتف2" : "Phone2"}: </span>
            0{order.secondary_phone}
          </div>
        </span>
        <span>
          <div className={cardStyle.icon}><IoLocationOutline /></div>
          <div>
            <span>{lang==='ar'? "العنوان":"Address"}: </span>
            {formatAddress()}
          </div>
        </span>
      </div>

      <div className={cardStyle.quantity} style={{ color: isDarkMode ? COLORS.SOFT_DARK : COLORS.WHITE }}>{lang==='ar' ? "الكمية":"Quantity"}: {order.quantity}</div>


    </div>
  );
};

