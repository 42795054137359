import { useEffect, useRef, useState } from "react";
import Hls from "hls.js";
import "plyr/dist/plyr.css";
import useCustomQuery from '@/hooks/useCustomQuery';
import { api_url } from '@/constants/base_url';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import axios from "axios"
import { currentUser } from "@shared/Helpers/encryptionHelpers";


export default function CustomVideoPlayer({ quality }) {
    const globalState = useSelector((state) => state?.global);
    const lang = globalState?.lang;

    const { id, mid } = useParams();

    const [VideoLink, setVideoLink] = useState("")


    axios.get(`${api_url}lesson/video/${mid}`, {
        headers: {
            Authorization: `Bearer ${currentUser()?.token}`,
            lang: lang,
        },
    })
        .then(response => {
            console.log('lessonBunny:', response.data.data);
            console.log('lessonBunnyVideo:', response.data.data.videoPlaylistUrl);
            setVideoLink(response.data.data.videoPlaylistUrl);
        })
        .catch(error => {
            console.error('Error:', error);
        });



    console.log(quality, "qq");
    const ref = useRef(null);
    const [player, setPlayer] = useState(null);


    useEffect(() => {
        if (player && VideoLink) {
            player.source = {
                type: "video",
                sources: [
                    {
                        src: VideoLink,
                    },
                ],
            };
            player.quality = quality;
            player.play();
        }
    }, [VideoLink, quality, player]);

    // Update the source when quality changes
    useEffect(() => {
        if (player && VideoLink) {
            const currentTime = player.currentTime;
            const isPaused = player.paused;

            player.source = {
                type: "video",
                sources: [
                    {
                        src: VideoLink,
                    },
                ],
            };

            player.quality = quality;

            if (!isPaused) {
                player.play();
                player.currentTime = currentTime;
            }
        }
    }, [quality]);


    const [currentTime, setCurrentTime] = useState(0);
    const currentTimeRef = useRef(0);
    useEffect(() => {
        const video = ref.current;
        let hls;

        if (Hls.isSupported()) {
            hls = new Hls();
            hls.loadSource(VideoLink);
            hls.attachMedia(video);
            hls.on(Hls.Events.MANIFEST_PARSED, () => {
                video.play();
            });
        } else if (video.canPlayType("application/vnd.apple.mpegurl")) {
            video.src = VideoLink;
            video.addEventListener("loadedmetadata", () => {
                video.play();
            });
        }
        const handleTimeUpdate = () => {
            currentTimeRef.current = video.currentTime; // Update ref
            setCurrentTime(video.currentTime); // Update state
            console.log("Time updated:", video.currentTime);
        };

        const handleKeyDown = (e) => {
            const current = currentTimeRef.current; // Get latest currentTime
            if (e.key === "ArrowRight") {
                const newTime = Math.min(current + 10, video.duration);
                video.currentTime = newTime;
                console.log(
                    "Right arrow pressed. Previous time:",
                    current,
                    "New time:",
                    newTime
                );
            } else if (e.key === "ArrowLeft") {
                const newTime = Math.max(current - 10, 0);
                video.currentTime = newTime;
                console.log(
                    "Left arrow pressed. Previous time:",
                    current,
                    "New time:",
                    newTime
                );
            }
        };

        // Add event listeners
        video.addEventListener("timeupdate", handleTimeUpdate);
        window.addEventListener("keydown", handleKeyDown);

        return () => {
            console.log("Component unmounted");
            if (hls) {
                hls.destroy();
            }
            video.removeEventListener("timeupdate", handleTimeUpdate);
            window.removeEventListener("keydown", handleKeyDown);
        };
    }, [VideoLink]);

    return (
        <div
            className="relative w-auto h-auto"
            style={{
                borderRadius: "20px",
                overflow: "hidden",
                marginBottom: "30px",
            }}
        >
            <video
                id="player"
                ref={ref}
                controls
                autoPlay
                className="h-full w-full relative plyr__video-embed"
                style={{ width: "100%" }}
            />
        </div>
    );
}
