import { ROLES } from "@/constants/roles";
import { Box, List, CssBaseline, Avatar, Drawer } from "@mui/material";
import { currentUser } from "@shared/Helpers/encryptionHelpers";
import { useNavigate, Navigate } from "react-router-dom";
import MyListItem from "./MyListItem";
import home from "@assets/adminImages/home.png";
import code from "@assets/adminImages/code.png";
import bell from "@assets/adminImages/bell.png";
import courses from "@assets/adminImages/courses.png";
import studentIcon from "@assets/adminImages/studentIcon.png";
import bookIcon from "@assets/adminImages/bookIcon.png";
import settings from "@assets/images/settings.png";
import supervisors from "@assets/adminImages/supervisors.png";
import jawAcademyLogo from "@assets/images/logo.png";
import avatar from "@assets/adminImages/avatar.png";
import { useState } from "react";
import { COLORS } from "@/constants/Colors/colors";
import ColorButton from "@shared/Buttons/ColorButton/ColorButton";
import { Outlet } from "react-router-dom";
import AdminLogout from "@components/AdminLogout/AdminLogout";
import { features } from "@config/features";
import { useSelector } from "react-redux";
import useCustomQuery from "@/hooks/useCustomQuery";
import ModeTuggle from "@/modules/settings/components/ModeTuggle";
import LangTuggle from "@/modules/settings/components/LangTuggle/LangTuggle";

export default function SideNav() {
  const lang = useSelector((state) => state?.global?.lang);
  const isDarkMode = useSelector((state) => state?.global?.isDarkMode);
  const globalState = useSelector((state) => state?.global);

  const { name, token } = currentUser();
  const drawerWidth = 260;

  const [adminLogout, setAdminLogout] = useState(false);

  const { data: version } = useCustomQuery({
    url: `version`,
    key: ["version", lang],
    lang: lang,
  });

  if (!token) return <Navigate to={"/login"} replace />;

  return (
    <>
      <Box
        sx={{
          position: "fixed",
          top: "0",
          right: "unset",
          left: "0",
          width: "100%",
          height: "100%",
          bgcolor: "#000000ab",
          zIndex: "1500",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          transition: "0.3s",
          opacity: adminLogout ? "1" : "0",
          visibility: adminLogout ? "visible" : "hidden",
        }}
      >
        <AdminLogout
          closeAdminLogout={() => setAdminLogout(false)}
          adminLogout={adminLogout}
        />
      </Box>
      <Box
        sx={{
          display: "flex",
          width: "100%",
          height: "100%",
          bgcolor: globalState?.isDarkMode
            ? COLORS.DARK_GRAY
            : COLORS.OFF_WHITE,
          direction: lang == "ar" ? "rtl" : "ltr",
        }}
      >
        <CssBaseline />

        <Box component="nav" displayPrint={"none"}>
          <Drawer
            variant="permanent"
            anchor={lang == "ar" ? `right` : "left"}
            sx={{
              "& .MuiDrawer-paper": {
                width: drawerWidth,
                bgcolor: globalState?.isDarkMode
                  ? COLORS.SOFT_DARK
                  : COLORS.WHITE,
              },
            }}
          >
            <ElementDrawer />
            <Box
              sx={{
                width: "100%",
                display: "flex",
                justifyContent: "center",
                alignItems: "end",
                height: "30%",
              }}
            >
              {lang == "ar" ? "الاصدار الحالي :" : "Version :"}{" "}
              {version?.version}
            </Box>
          </Drawer>
        </Box>

        <Box
          component="main"
          sx={{
            // height: "100vh",
            minHeight: "100vh",
            width: `calc(100% - ${drawerWidth}px)`,
            ...(lang == "en"
              ? { ml: `${drawerWidth}px` }
              : { mr: `${drawerWidth}px` }),
          }}
        >
          <Box
            position="fixed"
            sx={{
              width: "100%",
              left: 0,
              px: 2,
              display: "flex",
              alignItems: "center",
              justifyContent: lang == "en" ? "end" : "start",
              "@media print": { display: "none" },
              zIndex: 100,
              bgcolor: globalState?.isDarkMode
                ? COLORS.SOFT_DARK
                : COLORS.WHITE,
              direction: "ltr",
            }}
          >
            <ModeTuggle />
            <LangTuggle />
            <Box
              className={"admin-profile"}
              sx={{
                borderRadius: "50px",
                bgcolor: isDarkMode ? COLORS.DARK_GRAY: "white",
                color: isDarkMode ? "white" : "black",
                width: "200px",
                display: "flex",
                alignItems: "center",
                gap: '20px',
                padding: "5px 10px",
                cursor: "pointer",
                position: "relative",
                transition: "0.4s",
                fontSize: "14px",
                "&:hover": {
                  borderColor: COLORS.PRIMARY.MAIN,
                },
              }}
              variant="contained"
            >
              <Avatar
                alt="Remy Sharp"
                src={avatar}
                sx={{ width: 34, height: 34}}
              />
              {name}

              <Box
                className="admin-list"
                sx={{
                  position: "absolute",
                  top: 41,
                  left: 0,
                  bgcolor: "#EEEEEE",
                  padding: "20px 10px",
                  borderRadius: "0px 0px 10px 10px",
                  borderTop: `2px solid ${COLORS.PRIMARY.MAIN}`,
                  width: "200px",
                  opacity: "0",
                  visibility: "hidden",
                  transform: "translateY(15px)",
                  transition: "0.4s",
                  zIndex: "1000",
                }}
              >
                <ColorButton
                  onClick={() => setAdminLogout(true)}
                  sx={{
                    width: "100%",
                    transition: "0.3s",
                  }}
                >
                  {lang == "ar" ? " تسجيل الخروج" : "Logout"}
                </ColorButton>
              </Box>
            </Box>
          </Box>
          <Box mt={10} px={2} pb={2}>
            <Outlet />
          </Box>
        </Box>
      </Box>
    </>
  );
}

const ElementDrawer = () => {
  const navigate = useNavigate();
  const { id, role } = currentUser();
  const lang = useSelector((state) => state?.global?.lang);
  function navigateHome() {
    switch (role) {
      case ROLES.ADMIN:
        navigate("/dashboard");
        break;
      case ROLES.TEACHER:
        navigate("/dashboard/courses/" + id);
        break;
      default:
        navigate("/dashboard/courses");
    }
  }
  return (
    <>
      <Box
        onClick={navigateHome}
        sx={{
          width: "130px",
          marginX: "auto",
          marginTop: "10px",
          marginBottom: "10px",
          cursor: "pointer",
        }}
      >
        <img
          src={`${jawAcademyLogo}`}
          alt="Logo"
          style={{ width: "100%", padding: "5px" }}
        />
      </Box>

      <List sx={{ width: "80%", marginInline: "auto" }}>
        <MyListItem
          navigatePath="/dashboard"
          locationPath="/dashboard"
          text={lang == "ar" ? "الرئيسية" : "Main"}
          img={home}
          roles={[ROLES.ADMIN]}
        />
        <MyListItem
          navigatePath={`/dashboard/courses`}
          locationPath={[
            `/dashboard/courses`,
            `/dashboard/courses/${id}`,
            "/dashboard/courses/AddSessionInCourse",
          ]}
          text={lang == "ar" ? "الكورسات" : "Courses"}
          img={courses}
          roles={[ROLES.ADMIN]}
        />
        <MyListItem
          navigatePath={
            role !== "Supervisor" ? `/dashboard/courses` : `/dashboard/courses`
          }
          locationPath={[
            `/dashboard/courses`,
            "/dashboard/courses/AddSessionInCourse",
          ]}
          text={lang == "ar" ? "الكورسات" : "Courses"}
          img={courses}
          roles={[ROLES.TEACHER, ROLES.SUPERVISOR]}
        />
        <MyListItem
          navigatePath="coursesCodes"
          locationPath={[
            "/dashboard/coursesCodes",
            "/dashboard/coursesCodes/codes",
          ]}
          text={lang == "ar" ? "الاكواد" : "Codes"}
          img={code}
          roles={[ROLES.ADMIN, ROLES.TEACHER, ROLES.SUPERVISOR]}
        />
        {features.notifications && (
          <MyListItem
            navigatePath="notifications"
            locationPath={"/dashboard/notifications"}
            text={lang == "ar" ? "الإشعارات" : "Notifications"}
            img={bell}
            roles={[ROLES.ADMIN]}
          />
        )}
        {features.supervisors && (
          <MyListItem
            navigatePath="supervisors"
            locationPath={"/dashboard/supervisors"}
            text={lang == "ar" ? "المشرفين" : "Supervisors"}
            img={supervisors}
            roles={[ROLES.ADMIN]}
          />
        )}

        <MyListItem
          navigatePath="students"
          locationPath={"/dashboard/students"}
          text={lang == "ar" ? "الطلاب" : "Students"}
          img={studentIcon}
          roles={[ROLES.ADMIN, ROLES.TEACHER]}
        />

        <MyListItem
          navigatePath="book-store"
          locationPath={"/dashboard/book-store"}
          text={lang == "ar" ? "متجر الكتب" : "Book Store"}
          img={bookIcon}
          roles={[ROLES.ADMIN]}
        />
        <MyListItem
          navigatePath="settings"
          locationPath={"/dashboard/settings"}
          text={lang == "ar" ? "الاعدادات" : "Settings"}
          img={settings}
          roles={[ROLES.ADMIN]}
        />
      </List>
    </>
  );
};
