import { Dialog, DialogTitle, DialogContent, Button } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { COLORS } from "@/constants/Colors/colors";
import { FONTS } from '@/constants/Fonts/fonts';
import { useSelector } from 'react-redux';
import { PiSelectionBackgroundBold } from "react-icons/pi";

export default function Modal({
  zIndex,
  children,
  maxWidth,
  open,
  closeFn,
  ...props
}) {
  const globalState = useSelector((state) => state?.global);
  const lang = globalState?.lang;
  return (
    <Dialog
      maxWidth={maxWidth ? maxWidth : "md"}
      fullWidth
      onClose={closeFn}
      open={open}
      sx={{
        zIndex: zIndex || "1500",
        fontFamily: lang === 'ar' ? "" : FONTS.ENGLISH,
      }}
    >
        <DialogTitle sx={{ borderTop: `${COLORS.SECONDARY.MAIN} 5px solid` }}>
          <Button onClick={closeFn}>
            <CloseIcon
            sx={{color: COLORS.SECONDARY.MAIN}}
            />
          </Button>
        </DialogTitle>{" "}
        <DialogContent
          sx={{
            borderBottom: `${COLORS.SECONDARY.MAIN} 5px solid`,
            display: "flex",
            flexDirection: "column",
            // justifyContent: "center",
            alignItems: "center",
            fontFamily: lang === 'ar' ? "" : FONTS.ENGLISH,
          }}
        >
          {children}
        </DialogContent>
    </Dialog>
  );
}
