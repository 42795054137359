import { Box, Typography } from "@mui/material";
import { useLogout } from "./hooks/logout.hook";
import ColorButton from "@shared/Buttons/ColorButton/ColorButton";
import Row from "@shared/Layout/Row/Row";
import Modal from "@shared/Modal/Modal";
import { COLORS } from "@/constants/Colors/colors";
import { FONTS } from "@/constants/Fonts/fonts";
import { useSelector } from 'react-redux';

function AdminLogout({ adminLogout, closeAdminLogout }) {
  const lang = useSelector((state) => state?.global?.lang);
  const { logout, isLoading } = useLogout();

  return (
    <Box>
      <Modal maxWidth={"sm"} open={adminLogout} closeFn={closeAdminLogout}>
        <Typography
          fontFamily={lang === 'ar' ? "Noor-bold" : FONTS.ENGLISH}
          fontSize={30}
          sx={{
            color: "black",
            fontWeight: 'bold'
          }}
        >
          {lang === "ar" ? "?هل تريد تسجيل الخروج" : "Do you  want to logout?"}
        </Typography>

        <Row mt={5} gap={1} width={"60%"}>
          <ColorButton
            onClick={() => logout()}
            width={"49%"}
            isLoading={isLoading}
          >
            {lang === 'ar' ? "أوافق" : "logout"}
          </ColorButton>
          <ColorButton
            onClick={closeAdminLogout}
            width={"49%"}
            sx={{
              bgcolor: "gray",
            }}
          >
            {lang === 'ar' ? "الغاء" : "Cancel"}
          </ColorButton>
        </Row>
      </Modal>
    </Box>
  );
}

export default AdminLogout;
