import { COLORS } from "@/constants/Colors/colors";
import { Box, Typography } from "@mui/material";
import React, { useState, useEffect } from "react";
import timerStyle from "./Timer.module.css"
import { FONTS } from "@/constants/Fonts/fonts";
import { PiClockCountdown } from "react-icons/pi";

export const Timer = ({ sx, time, lang, color }) => {
  const [countdown, setCountdown] = useState({ days: 0, hours: 0, mins: 0, secs: 0 });

  useEffect(() => {
    const targetDate = new Date(time);

    const updateCountdown = () => {
      const now = new Date();
      const difference = targetDate - now;

      const days = Math.floor(difference / (1000 * 60 * 60 * 24));
      const hours = Math.floor(
        (difference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
      );
      const minutes = Math.floor((difference % (1000 * 60 * 60)) / (1000 * 60));
      const seconds = Math.floor((difference % (1000 * 60)) / 1000);

      const formattedCountdown = `${days}d : ${hours}h : ${minutes}m : ${seconds}s`;
      setCountdown({ days: days, hours: hours, mins: minutes, secs: seconds });
    };

    const intervalId = setInterval(updateCountdown, 1000);

    return () => clearInterval(intervalId);
  }, [time]);

  return (
    <Box
      sx={{
        ...sx,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <div className={timerStyle.timer} style={{ fontFamily: lang === 'en' ? FONTS.ENGLISH : "", color: color }}>
        <PiClockCountdown />
        <ul>
          <li>{countdown.days}<sub>{lang === 'ar' ? "يوم" : "day"}</sub></li>
          <li>{countdown.hours}<sub>{lang === 'ar' ? "ساعة" : "hour"}</sub></li>
          <li>{countdown.mins}<sub>{lang === 'ar' ? "دقيقة" : "min"}</sub></li>
          <li>{countdown.secs}<sub>{lang === 'ar' ? "ثانية" : "sec"}</sub></li>
        </ul>
      </div>
    </Box>
  );
};
