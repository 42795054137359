import { COLORS } from "@/constants/Colors/colors";
import { Box, Container } from "@mui/material";
import { useEffect } from "react";
import { HashLoader } from "react-spinners";
import DisplayAllHeader from "../atoms/DisplayAllHeader";
import NoItemsText from "@shared/NoItemText/NoItemText";
import CourseCard from "@/modules/home/components/atoms/CouresCard/CourseCard";
import { api_url } from "@/constants/base_url";
import { Timer } from "@shared/Timer/Timer";
import { useUserSubsContext } from "../../Context/UserSubsContext";
import { useSelector } from "react-redux";
import NoDataFound from '@/components/NoDataFound/NoDataFound';
import { FONTS } from "@/constants/Fonts/fonts";
export const MySubscriptions = () => {
  const { data, isPending, setPage } = useUserSubsContext();
  const globalState = useSelector((state) => state?.global);
  const lang = globalState?.lang;
  const isDarkMode = globalState?.isDarkMode;
  useEffect(() => {
    setPage(1);
    window.scrollTo(0, 0);
  });

  if (isPending) {
    return (
      <Box
        sx={{
          minHeight: "75.5vh",
          width: "100%",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          paddingY: 5,
        }}
      >
        <HashLoader color={COLORS.PRIMARY.MAIN} />
      </Box>
    );
  }
  if (data?.data?.data?.data?.length == 0) return <></>;


  return (
    <>
      <Box py={10} sx={{ direction: lang == "ar" ? "rtl" : "ltr", backgroundColor: isDarkMode ? "" : "#EEEDF2" }}>
        <DisplayAllHeader
          text={lang == "ar" ? "اشتراكاتي" : "My Subscriptions"}
          link={"/my-subscriptions"}
          color={COLORS.PRIMARY.MAIN}
        />

        {data?.data?.data?.data?.length > 0 ? (
          <div className="cardsContainer">
            {data?.data?.data?.data?.slice(0, 4).map((item, i) => (
              <CourseCard
                key={i}
                color={COLORS.PRIMARY.MAIN}
                // key={item.course.id}
                img={`${api_url.slice(0, -7)}${item.course.image.slice(1)}`}
                name={item.course.name}
                price={item.course.price}
                description={item.course.description}
                type={
                  item.course.isPayByModule
                    ? lang == "ar"
                      ? "بالحصة"
                      : "with lesson"
                    : lang == "ar"
                      ? "بالكورس"
                      : "with course"
                }
                teacher={item.course.teacher}
                link={`/course/${item.course.id}`}
                id={item.course.id}
                bought
                time={item.expires_at}
              />
            ))}
          </div>
        ) : (
          <div style={{ position: 'relative', width: 'calc(100vw - 100px)', fontFamily: lang === 'en' ? FONTS.ENGLISH : "" }}>
            <NoDataFound colored title={
              lang == "ar"
                ? "ليس لديك اشتراكات حتي الآن"
                : "You don't have any subscriptions yet."
            } />
          </div>
        )}
      </Box>
    </>
  );
};
