import { COLORS } from "@/constants/Colors/colors";
import { FONTS } from "@/constants/Fonts/fonts";
import { TableBody, TableCell, TableRow, Typography } from "@mui/material";
import React from "react";
import { useSelector } from "react-redux";

export default function CustomNoData({ numOfCells, rowText, button = null }) {
  const globalState = useSelector((state) => state?.global);
  return (
    <TableRow sx={{ width: "100%" }}>
      <TableCell
        colSpan={numOfCells}
        style={{ textAlign: "center", padding: "30px", width: "100%" }}
      >
        <Typography
          fontFamily={globalState?.lang === 'ar' ? FONTS.BOLD : FONTS.ENGLISH}
          fontSize={30}
          color="black"
          sx={{
            textAlign: "center",
            fontWeight: 'bold',
            color: globalState?.isDarkMode ? COLORS.WHITE : COLORS.BLACK,
          }}
        >
          {rowText}
        </Typography>
        {button}
      </TableCell>
    </TableRow>
  );
}
