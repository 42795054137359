import {
  Box,
  Button,
  FormHelperText,
  InputAdornment,
  Modal,
  Table,
  TableContainer,
  TextField,
} from "@mui/material";
import { useEffect, useRef, useState } from "react";
import SearchIcon from "@mui/icons-material/Search";
import { FONT_SIZES } from "@/constants/Fonts/font_sizes";
import { COLORS } from "@/constants/Colors/colors";
import Row from "@/components/Layout/Row/Row";
import Column from "@/components/Layout/Column/Column";
import ColorButton from "@/components/Buttons/ColorButton/ColorButton";
import CustomTableLoading from "@/components/Layout/TableRowLoading/CustomTableLoading";
import CustomNoData from "@/components/Layout/NoDataTableRow/CustomNoData";
import CustomTableHead from "@/components/Layout/TableHead/CustomTableHead";
import { useNavigate, useParams } from "react-router-dom";
import CustomTableRowCodes from "@/components/Layout/TableRow/CustomTableRowCodes";
import CodeDetails from "./CodeDetails";
import EmptyDiv from "@/Admin/Components/Paper/EmptyDiv";
import { addCodeAtom } from "@/modules/codes/services/codeAtoms/addCodeAtom";
import { FormProvider, useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { createCourseCodeSchema } from "@/shema/createCourseCode.schema";
import { useRecoilState, useSetRecoilState } from "recoil";
import qr from "@assets/images/qr.png";
import dayjs from "dayjs";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { currentUser } from "@shared/Helpers/encryptionHelpers";
import { notifySuccess, notifyError } from "@shared/Toast/toast";
import axios from "axios";
import { api_url } from "@/constants/base_url";
import SharedDateTimeInput from "@/components/Form/SharedDateTimeInput/SharedDateTimeInput";
import jsPDF from "jspdf";
import QRCode from "react-qr-code";
import ReactDOMServer from "react-dom/server";
import ExcelJS from "exceljs";
import { printCodesAtom } from "../services/codeAtoms/printCodeAtom";
import { Close } from "@mui/icons-material";
import useCustomQuery from "@/hooks/useCustomQuery";
import Paginator from "@/components/Pagination/Paginator";
import { useSelector } from "react-redux";

export default function CourseDetails() {
  const lang = useSelector((state) => state?.global?.lang);

  const tableHeader = [
    lang == "ar" ? "رقم الطالب" : "Student number",
    lang == "ar" ? "كود التفعيل" : "Activation code",
    lang == "ar" ? "تاريخ الانشاء" : "Date created",
    lang == "ar" ? "تاريخ التفعيل" : "Activation date",
    lang == "ar" ? "تاريخ الانتهاء" : "Expiry date",
    lang == "ar" ? "المدة" : "Duration",
    lang == "ar" ? "مفعل" : "Active",
  ];

  const { id } = useParams();
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState();
  const navigate = useNavigate();

  const [filter, setFilter] = useState("");
  const [searchKeyword, setSearchKeyword] = useState("");
  const searchInputRef = useRef();

  const [activeComponent, setActiveComponent] = useState("Component1");

  const {
    data: tableData,
    isPending,
    error,
    refetch,
    isSuccess,
  } = useCustomQuery({
    url: filter
      ? `subscription/searchCode?course_id=${id}&type=All&codeQuery=${filter}`
      : `subscription/searchCode?course_id=${id}&type=All&page=${page}`,
    // method: "post",
    key: ["courseCode", id, filter, lang],
    page: page,
    lang,
    // data: { course_id: id },
    select: (data) => data?.data,
  });

  const changeComponent = (handleTeacher) => {
    setActiveComponent(handleTeacher);
  };

  const handleCourseCodeDetailsOpen = (code) => {
    code && changeComponent("Component3");
  };

  const renderComponent = () => {
    switch (activeComponent) {
      case "Component1":
        return <EmptyDiv />;
      case "Component3":
        return (
          <CodeDetails
            // course={tableData?.data?.course}
            handleClose={handleClose}
          />
        );
      default:
        return null;
    }
  };

  const handleClose = () => {
    changeComponent("Component1");
  };
  // console.log(tableData)
  useEffect(() => {
    if (isSuccess) {
      setTotalPages(tableData.data.last_page);
    }
  }, [tableData, isSuccess]);

  const [open, setOpen] = useState(false);
  const globalState = useSelector((state) => state?.global);
  function AddCourseCode() {
    const [downloadExcel, setDownloadExcel] = useState(false);
    const [date, setDate] = useState(new Date());
    const [add, setAdd] = useRecoilState(addCodeAtom);
    const [downloadType, setDownloadType] = useState("");

    const methods = useForm({
      resolver: zodResolver(createCourseCodeSchema),
    });
    const {
      setValue,
      register,
      handleSubmit,
      formState,
      clearErrors,
      control,
      errors,
      watch,
      isLoading,
      reset,
      getValues,
    } = useForm({
      defaultValues: {
        expires_at: "",
        activationDuration: "",
        numberOfCodes: "",
        course_id: id,
        isExcel: "0",
      },
    });

    const queryClient = useQueryClient();
    const { mutate, isPending, error, isError, status } = useMutation({
      mutationFn: (formData) =>
        axios.post(`${api_url}code/createNewCode`, formData, {
          headers: {
            Authorization: `Bearer ${currentUser()?.token}`,
            lang: lang,
          },
        }),
      onSuccess: (data) => {
        queryClient.invalidateQueries(["courseCode"]);
        console.log("Mutation successful", data?.data?.data);
        setOpen(false);
        refetch();
        reset();
        notifySuccess(
          lang == "ar"
            ? "تم انشاء الكود بنجاح"
            : "The code was created successfully"
        );
        setPrintCodes(data?.data?.data);
      },
      onError: (err) => {
        console.log("Mutation error", err);
        if (err.response) {
          console.log("Response data:", err.response.data);
        }
        notifyError(
          lang == "ar"
            ? "حدث خطأ اثناء تنفيذ المهمة"
            : "An error occurred while executing the task"
        );
      },
    });

    const setPrintCodes = useSetRecoilState(printCodesAtom);

    const generateQRCodeAsDataURL = async (data) => {
      return new Promise((resolve, reject) => {
        try {
          const qrCodeComponent = <QRCode value={data} />;

          const svgString = ReactDOMServer.renderToString(qrCodeComponent);

          const image = new Image();
          image.src = `data:image/svg+xml;base64,${btoa(svgString)}`;

          image.onload = () => {
            const canvas = document.createElement("canvas");
            canvas.width = image.width;
            canvas.height = image.height;
            const context = canvas.getContext("2d");
            context.drawImage(image, 0, 0, image.width, image.height);
            resolve(canvas.toDataURL("image/png"));
          };

          image.onerror = (error) => {
            reject(error);
          };
        } catch (error) {
          reject(error);
        }
      });
    };

    const generateAndDownloadPDF = async () => {
      const { expires_at, activationDuration, numberOfCodes, course_id } =
        getValues();

      const formattedExpiresAt = dayjs(expires_at).format(
        "YYYY-MM-DD hh:mm:ss A"
      );
      const formData = {
        expires_at: formattedExpiresAt,
        days: activationDuration,
        numberOfCodes,
        course_id,
      };

      const pdf = new jsPDF();

      for (let i = 0; i < numberOfCodes; i++) {
        if (i > 0) {
          pdf.addPage();
        }

        const qrCodeDataURL = await generateQRCodeAsDataURL(formData);

        pdf.addImage(qrCodeDataURL, "PNG", 10, 10, 100, 100);
      }
      pdf.save("qrcodes.pdf");

      navigate("printCodes");
    };
    const generateAndDownloadExcel = async () => {
      const { numberOfCodes, course_id } = getValues();
      const formData = {
        numberOfCodes,
        course_id,
      };

      const workbook = new ExcelJS.Workbook();
      const worksheet = workbook.addWorksheet("QR Codes");

      for (let i = 0; i < numberOfCodes; i++) {
        const qrCodeDataURL = await generateQRCodeAsDataURL(formData);

        // Add image to the worksheet
        const imageId = workbook.addImage({
          base64: qrCodeDataURL.replace(/^data:image\/png;base64,/, ""),
          extension: "png",
        });

        // Set the image position and size
        worksheet.addImage(imageId, `B${i * 20 + 2}:E${i * 20 + 18}`);
      }

      // Save the workbook
      const buffer = await workbook.xlsx.writeBuffer();
      const blob = new Blob([buffer], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });

      // Create an anchor element with the text "Download Excel"
      const downloadLink = document.createElement("a");
      downloadLink.textContent = "Download Excel";

      // Use FileSaver.js to save the blob as a file
      downloadLink.href = URL.createObjectURL(blob);
      downloadLink.download = `qrcodes.xlsx`;
      downloadLink.click();

      navigate("printCodes");
      setDownloadExcel(false);
    };

    const onSubmit = () => {
      const { expires_at, activationDuration, numberOfCodes, course_id } =
        getValues();
      const formattedExpiresAt = dayjs(expires_at).format(
        "YYYY-MM-DD hh:mm:ss A"
      );

      const formData = {
        expires_at: formattedExpiresAt,
        days: activationDuration,
        numberOfCodes,
        course_id,
        isExcel: "0",
      };

      mutate(formData);
    };
    return (
      <>
        <Modal
          open={open}
          // closefn={() => setOpen(false)}
          sx={{
            display: "flex",
            margin: "auto",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Box
            sx={{
              width: 500,
              display: "flex",
              flexDirection: "column",
              margin: "auto",
              alignItems: "center",
              justifyContent: "center",
              padding: "30px",
              borderRadius: "10px",
              backgroundColor: globalState?.isDarkMode
                ? COLORS.SOFT_DARK
                : COLORS.WHITE,
            }}
          >
            <Box
              sx={{
                width: "100%",
                margin: "auto",
              }}
            >
              <Close
                onClick={() => {
                  setOpen(false);
                }}
                sx={{
                  color: "white",
                  padding: "10px 10px",
                  width: "40px",
                  height: "40px",
                  borderRadius: "5px",
                  transition: "0.4s",
                  bgcolor: "primary.main",
                  cursor: "pointer",
                  "&:hover": {
                    bgcolor: "white",
                    color: COLORS.PRIMARY.MAIN,
                  },
                }}
              />
            </Box>
            <FormProvider {...methods}>
              <form style={{ width: "100%" }} onSubmit={handleSubmit(onSubmit)}>
                <div
                  // data-testid="image-container"
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    marginBottom: "20px",
                  }}
                >
                  <img src={qr} />
                </div>
                <FormHelperText sx={{ color: COLORS.DANGER }}>
                  {errors?.image && errors?.image?.message}
                </FormHelperText>
                <Column gap={2}>
                  <TextField
                    registername={"numberOfCodes"}
                    placeholder={
                      lang == "ar"
                        ? "ادخل عدد الاكواد"
                        : "Enter the number of codes"
                    }
                    type="number"
                    {...register("numberOfCodes", {
                      required:
                        lang == "ar"
                          ? `هذا الحقل مطلوب`
                          : `This field is required`,
                    })}
                    // fullWidth
                    sx={{
                      direction: lang == "ar" ? "rtl" : "ltr",
                    }}
                  />
                  <FormHelperText sx={{ color: COLORS.DANGER }}>
                    {errors?.numberOfCodes && errors?.numberOfCodes?.message}
                  </FormHelperText>
                  <TextField
                    registername={"activationDuration"}
                    placeholder={
                      lang == "ar"
                        ? "ادخل مده التفعيل "
                        : "Enter the activation period"
                    }
                    type="number"
                    {...register("activationDuration", {
                      required:
                        lang == "ar"
                          ? `هذا الحقل مطلوب`
                          : `This field is required`,
                    })}
                    // fullWidth
                    sx={{
                      direction: lang == "ar" ? "rtl" : "ltr",
                    }}
                  />
                  <FormHelperText sx={{ color: COLORS.DANGER }}>
                    {errors?.activationDuration &&
                      errors?.activationDuration?.message}
                  </FormHelperText>

                  <SharedDateTimeInput
                    rules={{
                      required:
                        lang == "ar"
                          ? "هذا الحقل مطلوب"
                          : `This field is required`,
                    }}
                    control={control}
                    name="expires_at"
                    error={errors?.expires_at}
                    helperText={errors?.expires_at?.message}
                    label={lang == "ar" ? "تاريخ الانتهاء" : "Expire date"}
                    registername={"expires_at"}
                    defaultValue={dayjs(new Date())}
                  />
                  <Row width={"100%"} gap={1}></Row>

                  <Row
                    sx={{
                      marginTop: "30px",
                      marginBottom: "30px",
                      width: "100%",
                    }}
                  >
                    {Number(watch("numberOfCodes")) > 1 ? (
                      <>
                        <ColorButton
                          type="submit"
                          disabled={isLoading}
                          variant="contained"
                          sx={{
                            bgcolor: "#2f8bc8",
                            marginLeft: "20px",
                            paddingX: "20px",
                          }}
                          onClick={() => {
                            generateAndDownloadPDF();
                            setDownloadExcel(false);
                          }}
                        >
                          {isLoading && !downloadExcel ? (
                            <ClipLoader color="#000" size={20} />
                          ) : lang == "ar" ? (
                            "انشاء و تحميل pdf"
                          ) : (
                            "Create & download PDF"
                          )}
                        </ColorButton>
                        <ColorButton
                          type="submit"
                          disabled={isLoading}
                          variant="contained"
                          sx={{
                            bgcolor: "#2f8bc8",
                            marginLeft: "20px",
                            paddingX: "20px",
                            width: "100%",
                          }}
                          onClick={() => {
                            setDownloadExcel(true);
                            setDownloadType("excel");
                            generateAndDownloadExcel();
                          }}
                        >
                          {isLoading && downloadExcel ? (
                            <ClipLoader color="#000" size={20} />
                          ) : lang == "ar" ? (
                            "انشاء و تحميل excel"
                          ) : (
                            "Create & download Excel"
                          )}
                        </ColorButton>
                      </>
                    ) : (
                      <ColorButton
                        type="submit"
                        variant="contained"
                        sx={{
                          bgcolor: "#2f8bc8",
                          marginLeft: "20px",
                          paddingX: "30px",
                          width: "fit-content",
                        }}
                      >
                        {isLoading ? (
                          <ClipLoader color="#000" size={20} />
                        ) : lang == "ar" ? (
                          "انشاء"
                        ) : (
                          "Create"
                        )}
                      </ColorButton>
                    )}
                  </Row>
                </Column>
              </form>
            </FormProvider>
          </Box>
        </Modal>
      </>
    );
  }

  return (
    <>
      <Row
        spacing={"space-between"}
        align={"flex-start"}
        sx={{ direction: lang == "ar" ? "rtl" : "ltr",
          gap: '30px',
         }}
      >
        <Column
          gap={1}
          spacing={"space-between"}
          align={"flex-start"}
          width={"100%"}
          sx={{ direction: lang == "ar" ? "rtl" : "ltr" }}
        >
          <Row spacing={"space-between"} mb={2}>
            <TextField
              sx={{
                borderRadius: "5px",
                display: "flex",
                justifyContent: lang == "ar" ? "flex-end" : "flex-start",
                color: "white",
                backgroundColor: "white",
                "& .MuiInputBase-input": {
                  padding: "7px",
                },
                "& .MuiOutlinedInput-notchedOutline": {
                  border: "none",
                },
                direction: lang == "ar" ? "rtl" : "ltr",
              }}
              value={searchKeyword}
              onChange={(e) => {
                setSearchKeyword(e.target.value);
                if (e.target.value.length == 0) {
                  setFilter("");
                }
              }}
              placeholder={
                lang == "ar" ? "ابحث عن الاكواد" : "Search for codes"
              }
              InputProps={{
                style: {
                  fontSize: FONT_SIZES.REGULAR,
                  backgroundColor: globalState?.isDarkMode ? COLORS.SOFT_DARK : COLORS.WHITE,
                  paddingRight: lang !== "ar" ? "0" : "unset",
                },
                endAdornment: (
                  <InputAdornment position={lang == "ar" ? "start" : "end"}>
                    <Button
                      sx={{
                        color: "white",
                        backgroundColor: COLORS.PRIMARY.MAIN,
                        padding: "8px",
                        "&:hover": {
                          backgroundColor: "green",
                        },
                      }}
                      onClick={() => setFilter(searchKeyword)}
                    >
                      <SearchIcon />
                    </Button>
                  </InputAdornment>
                ),
              }}
              inputRef={searchInputRef}
              // onClick={searchHandler}
            />
            <ColorButton width={"20%"} onClick={() => setOpen(true)}>
              {lang == "ar" ? "اضف الاكواد" : "Add codes"}
            </ColorButton>
          </Row>
          <AddCourseCode />
          <Box
            sx={{
              display: "flex",
              justifyContent: "end",
              alignItems: "center",
              position: "relative",
              margin: "30px 0px",
            }}
          >
            <Paginator page={page} setPage={setPage} totalPages={totalPages} />
          </Box>
          <Column
            gap={1}
            spacing={"space-between"}
            align={"flex-start"}
            width={"100%"}
          >
            <TableContainer>
              <Table>
                <CustomTableHead
                  headBackground={COLORS.PRIMARY.MAIN}
                  tableHeaders={tableHeader}
                />

                {isPending ? (
                  <CustomTableLoading
                    width={"100%"}
                    height={60}
                    numOfCells={7}
                  />
                ) : (
                  <>
                    {tableData?.data?.data?.length > 0 ? (
                      <>
                        {tableData?.data?.data?.map((code, index) => (
                          <CustomTableRowCodes
                            key={code.id || "-"}
                            index={index}
                            handleCourseCodeDetailsOpen={
                              handleCourseCodeDetailsOpen
                            }
                            codeData={code}
                            studentNumber={
                              code?.subscription?.user?.phone || "-"
                            }
                            code={code?.code}
                            createdAt={code?.created_at}
                            dateActivated={
                              code?.subscription?.updated_at || "-"
                            }
                            expiresAt={code?.expires_at}
                            days={code?.days}
                            refetch={refetch}
                          />
                        ))}
                      </>
                    ) : (
                      <CustomNoData
                        rowText={
                          lang == "ar" ? "لا يوجد اكواد" : "There are no codes"
                        }
                        numOfCells={7}
                      />
                    )}
                  </>
                )}
              </Table>
            </TableContainer>
          </Column>
        </Column>
        {renderComponent()}
      </Row>
    </>
  );
}
