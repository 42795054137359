import React from 'react'
import img from '../../assets/images/NoData.png'
import imgblue from '../../assets/images/blueNoData.png'
import style from "./NoDataFound.module.css"

const NoDataFound = ({ title, children, colored }) => {
    return (
        <div className={style.container}>
            <img src={colored ? imgblue : img} alt="No Data" />
            <h2>{title}</h2>
            <div>{children}</div>
        </div>
    )
}

export default NoDataFound