import Empty from "@shared/Layout/Empty/Empty";
import { Typography, FormHelperText, Checkbox } from "@mui/material";
import Column from "@shared/Layout/Column/Column";
import Row from "@shared/Layout/Row/Row";
import { useRecoilState } from "recoil";
import { activeCourseAtom } from "@/modules/courses/services/RecoilAtoms/ActiveCourse.atom";
import CourseStateIconSmall from "@shared/Course/CourseStateIconSmall/CourseStateIconSmall";
import student from "@assets/adminImages/student.png";
import course from "@assets/adminImages/course.png";
import getImageUrl from "@shared/Helpers/image/getImageUrl";
import money from "@assets/adminImages/money.png";
import book from "@assets/adminImages/book.png";
import { currentUser } from "@shared/Helpers/encryptionHelpers";
import EditButton from "@/components/Buttons/EditButton/EditButton";
import { FONTS } from "@/constants/Fonts/fonts";
import TextButton from "@shared/Buttons/TextButton/TextButton";
import { useState, useEffect } from "react";
import { FONT_SIZES } from "@/constants/Fonts/font_sizes";
import { COLORS } from "@/constants/Colors/colors";
import Modal from "@shared/Modal/Modal";
import ColorButton from "@/components/Buttons/ColorButton/ColorButton";
import { FormProvider, useForm } from "react-hook-form";
import { useDropzone } from "react-dropzone";
import BorderedInput from "@/components/Form/BorderedInput/BorderedInput";
import emptyImage from "@assets/adminImages/emptyImg.png";
import CloseButton from "@/components/Buttons/CloseButton/CloseButton";
import { dropzoneConfig } from "@/hooks/useDropZoneConfig";
import useAddCourse from "../../../../hooks/useAddCourse.hook";
import { addCourseAtom } from "@/modules/courses/services/RecoilAtoms/addCourseAtom";
import useUpdateCourse from "../../../../hooks/useUpdateCourse";
import useDeleteCourse from "../../../../hooks/useDeleteCourse";
import { zodResolver } from "@hookform/resolvers/zod";
import { updateCourseSchema } from "../../../../Admin/Components/Course/schema/updateCourse.schema";
import { createCourseSchema } from "../../../../Admin/Components/Course/schema/createCourse.schema";
import { SharedTextField } from "@shared/SharedTextField/SharedTextField";
import { useSelector } from "react-redux";
import { api_url } from "@/constants/base_url";
import { PiExam } from "react-icons/pi";
import { TfiRulerPencil } from "react-icons/tfi";
import { RiFolderVideoLine } from "react-icons/ri";
import { FaDollarSign } from "react-icons/fa6";
import { IoQrCodeOutline } from "react-icons/io5";
import { GiSandsOfTime } from "react-icons/gi";
import style from "./CourseDetailsSideBar.module.css"

export default function CourseDetailsSideBar({ refetch }) {
  const globalState = useSelector((state) => state?.global);
  const lang = globalState?.lang;
  const isDarkMode = globalState?.isDarkMode;

  const [activeCourse, setActiveCourse] = useRecoilState(activeCourseAtom);
  const [addCourse, setAddCourse] = useRecoilState(addCourseAtom);
  const [isEditing, setIsEditing] = useState(false);

  function close() {
    setActiveCourse(null);
    setAddCourse(false);
    setIsEditing(false);
  }


  return (
    <Empty
      sx={{
        p: 2,
        overflowY: "auto",
        bgcolor: isDarkMode ? COLORS.SOFT_DARK : COLORS.WHITE,
      }}

      close={activeCourse || addCourse}
      onClose={close}
    >

      {(() => {
        if (isEditing) {
          return <AddOrEditCourseUI close={close} refetch={refetch} />;
        }
        else if (activeCourse) {
          return (
            <CourseDataUI
              close={close}
              setIsEditing={setIsEditing}
              activeCourse={activeCourse}
              refetch={refetch}
            />
          );
        }
        else if (addCourse) {
          return <AddOrEditCourseUI close={close} refetch={refetch} />;
        }
        else if (!activeCourse) return null;

      })()}
    </Empty>
  );
}

function AddOrEditCourseUI({ close, refetch }) {
  const lang = useSelector((state) => state?.global?.lang);
  const isDarkMode = useSelector((state) => state?.global?.isDarkMode);
  const globalState = useSelector((state) => state?.global);
  const [add] = useRecoilState(addCourseAtom);
  const [activeCourse] = useRecoilState(activeCourseAtom);
  const [availabeSubscribtionDays, setAvailabeSubscriptionDays] =
    useState(false);
  const methods = useForm({
    resolver: zodResolver(add ? createCourseSchema : updateCourseSchema),
  });
  const {
    setValue,
    handleSubmit,
    formState,
    clearErrors,
    errors,
    register,
    getValues,
  } = methods;
  const [imageSrc, setImageSrc] = useState(null);

  const { getRootProps, getInputProps } = useDropzone(
    dropzoneConfig({
      setValue,
      setImageSrc,
      clearErrors,
    })
  );

  function initData() {
    setImageSrc({ url: getImageUrl(activeCourse.image) });
    setValue("name", activeCourse.name);
    setValue("description", activeCourse.description);
    setValue("duration", activeCourse.duration);
    setValue("price", activeCourse.price);
    setValue("subscription_days", activeCourse.subscription_days);
  }

  if (activeCourse && !imageSrc && !add) {
    initData();
  }
  console.log(refetch, "from parent");

  const { updateCourse, isPending: updatePending } = useUpdateCourse({
    refetch,
    courseId: activeCourse?.id,
    onSettled: close,
  });
  const { addCourse, isPending: addPending } = useAddCourse({
    refetch,
    onSettled: close,
  });

  return (
    <>
      <FormProvider {...methods}>
        <form className={style.container} style={{ fontFamily: lang === 'en' ? FONTS.ENGLISH : "" }} onSubmit={handleSubmit(add ? addCourse : updateCourse)}>
          <div
            // data-testid="image-container"
            {...getRootProps()}
            className={style.imageContainer}
          >
            <img
              data-testid="image"
              src={
                imageSrc?.url || getImageUrl(activeCourse?.image) || emptyImage
              }
              style={{ width: "80%", cursor: "pointer", objectFit: "cover", borderRadius: "20px" }}
              alt=""
            />
            <input data-testid="image-input" {...getInputProps()} />
          </div>
          <FormHelperText sx={{ color: COLORS.DANGER }}>
            {formState.errors?.image && formState.errors?.image?.message}
          </FormHelperText>
          <Column gap={1} sx={{ mt: 2 }}>
            <BorderedInput
              registerName={"name"}
              placeholder={
                lang == "ar" ? "ادخل اسم الكورس" : "Enter course name"
              }
              lang={lang}
              isDarkMode={!isDarkMode}
              color={COLORS.SECONDARY.MAIN}
            />
            <BorderedInput
              registerName={"description"}
              placeholder={
                lang == "ar" ? "ادخل الوصف " : "Enter course description"
              }
              lang={lang}
              isDarkMode={!isDarkMode}
              color={COLORS.SECONDARY.MAIN}
            />
            <Row spacing="space-between" width={"100%"} gap={1}>
              <BorderedInput
                registerName={"duration"}
                placeholder={
                  lang == "ar" ? "ادخل المده " : "Enter course duration"
                }
                type="number"
                lang={lang}
                isDarkMode={!isDarkMode}
                color={COLORS.SECONDARY.MAIN}
              />
              <Typography>{lang == "ar" ? "ساعة" : "Hour"}</Typography>
            </Row>

            <BorderedInput
              registerName={"price"}
              placeholder={lang == "ar" ? "ادخل السعر " : "Enter Price"}
              type="number"
              lang={lang}
              isDarkMode={!isDarkMode}
              color={COLORS.SECONDARY.MAIN}
            />

            <Row spacing="space-between" width={"100%"} gap={1}>
              <Checkbox
                sx={{
                  color: COLORS.PRIMARY.MAIN,
                  "&.Mui-checked": {
                    color: COLORS.SECONDARY.MAIN,
                  },
                }}
                defaultChecked={false}
                checked={availabeSubscribtionDays}
                onChange={() => {
                  if (availabeSubscribtionDays) {
                    setValue("subscription_days", 0);
                  }
                  setAvailabeSubscriptionDays(!availabeSubscribtionDays);
                }}
              />
              <SharedTextField
                placeholder={
                  lang == "ar" ? "ايام الدفع بالمحفظة" : "Pay by wallet days"
                }
                label={lang == "ar" ? "ايام الدفع بالمحفظة " : "Pay by wallet days"}
                register={register("subscription_days", {
                  validate: (value) => {
                    return !availabeSubscribtionDays ||
                      value > 0 ||
                      lang == "ar"
                      ? "ايام الدفع بالمحفظة يجب ان تكون اكبر من صفر او صفر"
                      : "pay by wallet days must be greater than zero";
                  },
                })}
                dark={globalState?.isDarkMode}
                extra={{
                  disabled: !availabeSubscribtionDays,
                  type: "number",
                  fullWidth: true,
                  // error: errors.subscription_days,
                  // helperText: errors.subscription_days?.message,
                }}
              />
              <FormHelperText sx={{ color: COLORS.DANGER }}>
                {formState.errors?.subscription_days &&
                  formState.errors?.subscription_days?.message}
              </FormHelperText>
            </Row>

            <div className={style.buttons}>
              <ColorButton
                type="submit"
                isLoading={addPending || updatePending}
              >
                {lang === "ar"
                  ? add
                    ? "اضافة"
                    : "تعديل"
                  : add
                    ? "Add"
                    : "Edit"}
              </ColorButton>
              {!add && (
                <ColorButton
                  onClick={initData}
                  isLoading={addPending || updatePending}
                >
                  {lang == "ar" ? "استعادة" : "Reset"}
                </ColorButton>
              )}
              <ColorButton onClick={close}>
                {lang == "ar" ? "اغلاق" : "Close"}
              </ColorButton>
            </div>
          </Column>
        </form>
      </FormProvider>
    </>
  );
}

function CourseDataUI({ close, setIsEditing, activeCourse, refetch }) {
  const lang = useSelector((state) => state?.global?.lang);
  const isDarkMode = useSelector((state) => state?.global?.isDarkMode);

  return (
    <>
      <div className={style.container} style={{ fontFamily: lang === 'ar' ? FONTS.BOLD : FONTS.ENGLISH }}>
        <img
          style={{ objectFit: "cover", borderRadius: "20px" }}
          width={"50%"}
          alt=""
          src={`${api_url.slice(0, -7)}${activeCourse?.image}`}

        />

        <h2 className={style.courseName}>
          {activeCourse?.name}
        </h2>
        <p
          className={style.description}
        >
          {activeCourse?.description}
        </p>

        <div className={style.price}>
          {activeCourse?.price == 0 ? (
            <p>
              {lang == "ar" ? " مجاني" : "Free"}
            </p>
          ) : (
            <p>
              {activeCourse?.price}{lang == "ar" ? "جنيه" : "EGP"}
            </p>)}
        </div>

        <div className={style.infoContainer}>
          <Info color={"#f72d93"} value={activeCourse?.subscription_days} icon={<GiSandsOfTime />}>{lang == "ar" ? "أيام الاشتراك" : "Subscription days"}</Info>
          <Info color={"#3AC73B"} value={activeCourse?.paymentCodesCount} icon={<IoQrCodeOutline />}>{lang == "ar" ? "أكواد" : "Codes"}</Info>
          <Info color={"#118BF9"} value={activeCourse?.subscriptionsCount} icon={<FaDollarSign />}>{lang == "ar" ? "إشتراكات" : "Subscription"}</Info>
          <Info color={"#FD9B20"} value={activeCourse?.modulesCount} icon={<RiFolderVideoLine />}>{lang == "ar" ? "حصص" : "Lessons"}</Info>
          <Info color={"#EE3D3D"} value={activeCourse?.homeworkCount} icon={<TfiRulerPencil />}>{lang == "ar" ? "واجبات" : "Homeworks"}</Info>
          <Info color={"#BC5ED2"} value={activeCourse?.examsCount} icon={<PiExam />}>{lang == "ar" ? "إمتحانات" : "Exams"}</Info>
        </div>

        {currentUser()?.role !== "Supervisor" && (
          <div className={style.buttons}>
            <button
              style={{ color: isDarkMode ? COLORS.SOFT_DARK : COLORS.WHITE }}
              onClick={() => setIsEditing(true)}
            >
              {lang === 'ar' ? "تعديل الكورس" : "Edit Course"}
            </button>

            <DeleteCourse courseId={activeCourse?.id} refetch={refetch} />
          </div>
        )}


      </div>
    </>
  );
}

const Info = ({ children, color, value, icon }) => {
  return (
    <div>
      <span>
        <div className={style.icon} style={{ color: color, backgroundColor: `${color}26` }}>{icon}</div>
        <p>{children}</p>
      </span>
      <span style={{ color: color }}>{value}</span>
    </div>
  )
}


function DeleteCourse({ courseId, refetch }) {
  const lang = useSelector((state) => state?.global?.lang);
  const isDarkMode = useSelector((state) => state?.global?.isDarkMode);

  const [open, setOpen] = useState(false);
  const [_, setActiveCourse] = useRecoilState(activeCourseAtom);

  const { deleteCourse, isPending } = useDeleteCourse({
    id: courseId,
    onSettled: () => {
      setOpen(false);
      setActiveCourse(null);
    },
    refetch,
  });

  return (
    <>
      <button
        style={{ color: isDarkMode ? COLORS.SOFT_DARK : COLORS.WHITE }}
        onClick={() => {
          setOpen(true);
        }}
      >
        {lang == "ar" ? " حذف الكورس" : "Delete Course"}
      </button>

      <Modal open={open} closeFn={() => setOpen(false)} maxWidth={"sm"}>
        <Typography fontFamily={FONTS.BOLD} fontSize={FONT_SIZES.LARGE}>
          {lang == "ar"
            ? "  هل انت متأكد من حذف الكورس؟"
            : "Are you sure about Delete this course"}
        </Typography>
        <Row mt={2} gap={2}>
          <ColorButton
            onClick={() => deleteCourse()}
            isLoading={isPending}
            colorType="danger"
            width="100px"
          >
            {lang == "ar" ? " حذف" : "Delete"}
          </ColorButton>
          <ColorButton
            colorType="secondary"
            width="100px"
            onClick={() => setOpen(false)}
          >
            {lang == "ar" ? "الغاء" : "Cancel"}
          </ColorButton>
        </Row>
      </Modal>
    </>
  );
}

// function LoadingUI() {
//   return (
//     <>
//       <Row align={"start"}>
//         <Column ml={1}>
//           <Row gap={1}>
//             <Typography width={"30px"} variant="h3">
//               <Skeleton sx={{ backgroundColor: "#bbb" }} />
//             </Typography>
//             <Typography width={"30px"} variant="h3">
//               <Skeleton sx={{ backgroundColor: "#bbb" }} />
//             </Typography>
//           </Row>
//           <Row gap={1}>
//             <Typography width={"30px"} variant="h3">
//               <Skeleton sx={{ backgroundColor: "#bbb" }} />
//             </Typography>
//             <Typography width={"30px"} variant="h3">
//               <Skeleton sx={{ backgroundColor: "#bbb" }} />
//             </Typography>
//           </Row>
//         </Column>
//         <Skeleton
//           sx={{ backgroundColor: "#bbb", width: "100%" }}
//           variant="rectangular"
//           height={220}
//         />
//       </Row>
//       <Column mt={4}>
//         <Skeleton
//           sx={{ backgroundColor: "#bbb", width: "300px", height: "60px" }}
//         />
//         <Skeleton
//           sx={{ backgroundColor: "#bbb", width: "300px", height: "40px" }}
//         />
//         <Skeleton
//           sx={{ backgroundColor: "#bbb", width: "300px", height: "60px" }}
//         />
//       </Column>
//     </>
//   );
// }
