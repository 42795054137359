import React, { useEffect, useState } from "react";
import { useUserSubsContext } from "../Context/UserSubsContext";
import { Box, Button, Container, Input } from "@mui/material";
import NoItemsText from "@shared/NoItemText/NoItemText";
import CourseCard from "@/modules/home/components/atoms/CouresCard/CourseCard";
import { api_url } from "@/constants/base_url";
import { Timer } from "@shared/Timer/Timer";
import { currentUser } from "@shared/Helpers/encryptionHelpers";
import { HashLoader } from "react-spinners";
import { COLORS } from "@/constants/Colors/colors";
import Paginator from "@shared/Pagination/Paginator";
import { useAllCoursesContext } from "../Context/AllCoursesContext";
import ColorButton from "@shared/Buttons/ColorButton/ColorButton";
import { Search } from "@mui/icons-material";
import { useSelector } from "react-redux";
import coursesStyle from "./AllCourses.module.css"
import { FONTS } from "@/constants/Fonts/fonts";

export const AllCourses = () => {
  const globalState = useSelector((state) => state?.global);
  const lang = globalState?.lang;
  const { data, isPending, setPage, page, totalPages, setQuery, query } =
    useAllCoursesContext();
  const {
    data: mySubs,
    isPending: isMySubsPending,
    ids,
  } = useUserSubsContext();
  const [keyword, setKeyword] = useState("");
  useEffect(() => {
    window.scrollTo(0, 0);
  });
  useEffect(() => {
    if (!keyword) {
      setQuery("");
    }
  }, [keyword]);
  if (isPending) {
    return (
      <Box
        sx={{
          minHeight: "75.5vh",
          width: "100%",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          paddingY: 5,
        }}
      >
        <HashLoader color={COLORS.PRIMARY.MAIN} />
      </Box>
    );
  }
  return (
    <main style={{ direction: lang === 'ar' ? 'rtl' : 'ltr' }}>
      <div className={`${coursesStyle.pageHeader} pageHeader`}>
        <form className={coursesStyle.searchContainer} onSubmit={(e) => event.preventDefault()}>
          <input
            onChange={(e) => {
              setKeyword(e?.target?.value);
              if (e.target.value === "") {
                setQuery("");
              }
            }}
            onFocus={(e) => {
              if (e.target.value === "") {
                setQuery("");
              }
            }}
            type="text"
            placeholder={
              lang == "ar" ? "ابحث عن كورس" : "Search for Course"
            }
            style={{ fontFamily: lang === 'en' ? FONTS.ENGLISH : "" }}
          />
          <button
            type="submit"
            onClick={() => {
              setQuery(keyword);
            }}
          >
            <Search />
          </button>
        </form>

        <Paginator page={page} setPage={setPage} totalPages={totalPages} />
      </div>
      {data?.data?.data?.length > 0 ? (
        <>
          <div className="cardsContainer">
            {data?.data?.data?.map((item, i) => (
              <CourseCard
                color={COLORS.SECONDARY.MAIN}
                key={i}
                // key={item.id}
                img={`${api_url.slice(0, -7)}${item.image.slice(1)}`}
                name={item.name}
                price={item.price}
                type={item.isPayByModule ? "بالحصة" : "بالكورس"}
                teacher={item.teacher}
                description={item.description}
                link={`/course/${item.id}`}
                id={item.id}
                bought={ids?.includes(+item.id)}
                time={
                  mySubs?.data?.data?.data?.find(
                    (x) => x.course_id == +item.id
                  )?.expires_at
                }
              />
            ))}
          </div>
        </>
      ) : (
        <Box
          sx={{
            width: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            paddingY: 5,
          }}
        >
          <NoItemsText
            text={
              query ? lang === 'ar' ? "لا توجد كورسات بهذا الأسم" : "There are no courses with this name" : lang === 'ar' ? "لم يتم إضافة كورسات بعد" : "No courses have been added yet"
            }
          />
        </Box>
      )}
    </main>
  );
};
