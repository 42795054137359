import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import { UserCourseHeader } from "./UserCourseHeader/UserCourseHeader";
import useCustomQuery from "@/hooks/useCustomQuery";
import { UserCourseDescription } from "./UserCourseDescription/UserCourseDescription";
import { UserCourseModules } from "./UserCourseModules/UserCourseModules";
import { HashLoader } from "react-spinners";
import { Box, Grid } from "@mui/material";
import { COLORS } from "@/constants/Colors/colors";
import { useUserSubsContext } from "../Context/UserSubsContext";
import { UserCourseExams } from "./UserCourseExams/UserCourseExams";
import { UserCourseFiles } from "./UserCourseFiles/UserCourseFiles";
import { useSelector } from "react-redux";
import Tabs from "@/components/TabbedPages/Tabs";
import style from "./UserCourse.module.css"
import { FONTS } from "@/constants/Fonts/fonts";

export const UserCourse = () => {
  const globalState = useSelector((state) => state?.global);
  const lang = globalState?.lang;
  const isDarkMode = globalState?.isDarkMode;

  const { id } = useParams();
  const { ids } = useUserSubsContext();
  const { data: course, isPending: isCoursePending } = useCustomQuery({
    key: [id, "course", "user", lang],
    url: `course/${id}`,
    lang,
    select: (data) => data?.data,
  });

  useEffect(() => {
    window.scrollTo(0, 0);
  });
  if (isCoursePending) {
    return (
      <Box
        sx={{
          minHeight: "75.5vh",
          width: "100%",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          paddingY: 5,
        }}
      >
        <HashLoader color={COLORS.PRIMARY.MAIN} />
      </Box>
    );
  }

  const pages = [
    {
      page: <UserCourseModules bought={ids?.includes(+id)} course={course} />,
      title: lang === 'ar' ? "الدروس" : "Lessons"
    },
    {
      page: <UserCourseFiles id={id} bought={ids?.includes(+id)} />,
      title: lang == "ar" ? "الملفات" : "Files"
    }
    ,
    {
      page: <UserCourseExams id={id} bought={ids?.includes(+id)} />,
      title: lang === "ar" ? "الإمتحانات" : "Exams"
    }
  ];

  return (
    <>
      <main style={{ direction: lang === 'ar' ? 'rtl' : "ltr", fontFamily: lang === 'en' ? FONTS.ENGLISH : "" }}>
        <div className={style.container}>
          <UserCourseHeader course={course} bought={ids?.includes(+id)} />
          <Tabs isInCoursePage isDarkMode={isDarkMode} color={COLORS.SECONDARY.MAIN} pages={pages} lang={lang} />
        </div>
      </main>
      <UserCourseDescription description={course?.description} />
    </>
  );
};
