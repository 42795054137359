import { ThemeProvider, TextField } from "@mui/material";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import { Controller } from "react-hook-form";
import FormHelperText from "@mui/material/FormHelperText";

import { createTheme } from "@mui/material";
import { COLORS } from "@/constants/Colors/colors";

const theme = createTheme({
  components: {
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          "& .MuiOutlinedInput-notchedOutline": {
            border: `1px solid ${COLORS.PRIMARY.MAIN}`,
          },
          "&:hover .MuiOutlinedInput-notchedOutline": {
            border: `1px solid ${COLORS.PRIMARY.MAIN}`,
          },
          "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
            border: `1px solid ${COLORS.PRIMARY.MAIN}`,
          },
        },
      },
    },
  },
});

export default function SharedDateTimeInput({
  control,
  rules,
  name,
  label,
  placeholder = "",
  minDate = dayjs(new Date()).add(1, "day").add(5, "minute"),
  minTime = dayjs(new Date()).add(24, "hour").add(5, "minute"),
  defaultValue = dayjs(new Date()).add(24, "hour").add(5, "minute"),
  error,
  helperText,
  extra,
}) {
  return (
    <>
      <ThemeProvider theme={theme}>
        <Controller
          control={control}
          name={name}
          rules={rules}
          render={({ field }) => (
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DateTimePicker
                {...field}
                disablePast
                minDate={minDate}
                minTime={minTime}
                // defaultValue={defaultValue}

                {...extra}
                sx={{
                  "& .MuiButtonBase-root": {
                    position: "absolute",
                    left: 0,
                  },
                  "& .MuiInputBase-input": {
                    textAlign: "right",
                  },
                  "& .MuiFormLabel-root.Mui-focused": {
                    color: COLORS.PRIMARY.MAIN,
                  },
                  "& label": {
                    left: "unset",
                    right: "1.75rem",
                    transformOrigin: "right",
                    fontSize: "0.8rem",
                    color: COLORS.PRIMARY.MAIN,
                  },
                  "& legend": {
                    textAlign: "right",
                    fontSize: "0.6rem",
                  },
                  "& input::placeholder": {
                    textAlign: "right",
                  },
                  direction: "ltr",
                }}
                InputProps={{
                  style: {
                    display: "flex",
                    flexDirection: "row-reverse",
                    justifyContent: "space-between",
                    alignItems: "center",
                  },
                }}
                placeholder={placeholder}
                label={label}
                renderInput={(params) => (
                  <TextField
                    inputProps={{
                      style: {
                        WebkitBoxShadow: "0 0 0 1000px white inset",
                        WebkitTextFillColor: "#2a2a2a",
                      },
                    }}
                    {...params}
                    // {...register}
                    error={error}
                    helperText={helperText}
                  />
                )}
              />
            </LocalizationProvider>
          )}
        />
      </ThemeProvider>
      {error && (
        <FormHelperText sx={{ color: "red", textAlign: "right" }}>
          {helperText}
        </FormHelperText>
      )}
    </>
  );
}
