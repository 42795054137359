import { COLORS } from "@/constants/Colors/colors";
import {
  Box,
  Button,
  TextField,
  InputAdornment,
  Typography,
  Checkbox,
} from "@mui/material";
import useCustomMutation from "@/hooks/useCustomMutation";
import Modal from "@shared/Modal/Modal";
import { notifySuccess } from "@shared/Toast/toast";
import { useQueryClient } from "@tanstack/react-query";
import dayjs from "dayjs";
import React, { useState } from "react";
import { useParams } from "react-router-dom";
import { HashLoader } from "react-spinners";
import { useSelector } from "react-redux";
import style from "./AddModule.module.css"
import Form from "@shared/Form/Form";

export const AddModule = ({ price }) => {
  const globalState = useSelector((state) => state?.global);
  const lang = globalState?.lang;
  const isDarkMode = globalState?.isDarkMode;

  const { id } = useParams();
  const [open, setOpen] = useState(false);
  const [submitted, setSubmitted] = useState(false);
  const [module, setModule] = useState({
    name: "",
    overview: "",
    releaseLater: false,
    releaseLaterDate: "",
    price: 0,
  });
  const queryClient = useQueryClient();
  const { mutate, isPending } = useCustomMutation({
    url: `module/create`,
    lang,
    onSuccess: (data) => {
      notifySuccess(lang == "ar" ? "تم إضافة الحصة بنجاح" : "Lesson created");
      setOpen(false);
      queryClient.invalidateQueries(["course modules"]);
      setModule({
        name: "",
        overview: "",
        releaseLater: false,
        releaseLaterDate: "",
        price: 0,
      });
    },
  });
  const getCurrentDateTime = () => {
    const now = new Date();
    const year = now.getFullYear();
    const month = `${(now.getMonth() + 1).toString().padStart(2, "0")}`;
    const day = `${now.getDate().toString().padStart(2, "0")}`;
    const hours = `${now.getHours().toString().padStart(2, "0")}`;
    const minutes = `${now.getMinutes().toString().padStart(2, "0")}`;
    return `${year}-${month}-${day}T${hours}:${minutes}`;
  };
  const submit = () => {
    let moduleData = module;
    if (!moduleData.name) return;
    if (!moduleData.overview) return;
    if (moduleData.price > 10 && +price <= 50) return;
    if (module.price <= 10 && module.price != 0 && +price > 50) return;
    if (moduleData.releaseLater) {
      if (!moduleData.releaseLaterDate) return;
      else {
        moduleData.releaseLaterDate = dayjs(moduleData.releaseLaterDate).format(
          "YYYY-MM-DD hh:mm:ss A"
        );
      }
    } else {
      delete moduleData.releaseLaterDate;
    }
    mutate({
      ...moduleData,
      course_id: id,
      isAvailableFile: "0",
    });
  };
  return (
    <>
      <div className={style.buttonContainer}>
        <button
          onClick={() => setOpen(true)}
          style={{ color: isDarkMode ? COLORS.DARK_GRAY : COLORS.WHITE }}
        >
          {lang == "ar" ? " اضف حصة" : "Add Lesson"}
        </button>
      </div>
      <Modal maxWidth={"sm"} open={open} closeFn={() => setOpen(false)}>
        <Form title={lang == "ar" ? "إضافة حصة للكورس" : "Add lesson for course"}>
          <Form.TextController
            placeholder={lang == "ar" ? "إسم الحصة" : "Lesson name"}
            value={module.name}
            onChange={(e) => {
              setModule({ ...module, name: e.target.value });
            }}
            error={!module.name && submitted}
            helperText={!module.name && submitted && lang === 'ar' ? "يجب إضافة إسم للحصة" : "Lesson name is required"}
            submitted={submitted}
          />

          <Form.TextController
            placeholder={lang == "ar" ? "وصف الحصة" : "Lesson overview"}
            value={module.overview}
            onChange={(e) => {
              setModule({ ...module, overview: e.target.value });
            }}
            error={!module.overview && submitted}
            helperText={!module.overview && submitted && lang === 'ar' ? "يجب إضافة وصف للحصة" : "Lesson overview is required"}
            submitted={submitted}
          />
          
          <div className={style.subContainer}>
            <Form.PriceController
              label="Enable Input"
              value={module.price}
              placeholder={lang === 'ar' ? "السعر" : "Price"}
              onChange={(e) => {
                setModule({ ...module, price: e.target.value });
              }}
              submitted={submitted}
            />

            <Form.ReleaseDateController
              id="unique-id"
              module={module}
              setModule={setModule}
              submitted={submitted}
              getCurrentDateTime={getCurrentDateTime}
            />
          </div>

          <Form.ButtonController
            disabled={isPending}
            onClick={() => {
              setSubmitted(true);
              submit();
            }}
          >
            {isPending ? (
              <HashLoader size={20} color={COLORS.WHITE} />
            ) : lang == "ar" ? (
              "أنشاء"
            ) : (
              "Create"
            )}
          </Form.ButtonController>

        </Form>
      </Modal>
    </>
  );
};
