import { COLORS } from "@/constants/Colors/colors";
import { TableBody, TableCell, TableRow, Typography } from "@mui/material";
import React from "react";
import { useSelector } from "react-redux";
import { FONTS } from '@/constants/Fonts/fonts';

export default function CustomNoData({ numOfCells, rowText, button = null }) {
  const globalState = useSelector((state) => state?.global);
  return (
    <TableRow sx={{ width: "100%" }}>
      <TableCell
        colSpan={numOfCells}
        style={{ textAlign: "center", padding: "30px", width: "100%" }}
      >
        <Typography
          fontSize={30}
          color={globalState?.isDarkMode ? COLORS.WHITE : COLORS.BLACK}
          sx={{
            textAlign: "center",
            fontFamily: globalState?.lang === 'ar' ? FONTS.BOLD : FONTS.ENGLISH,
            fontWeight: 'bold'
          }}
        >
          {rowText}
        </Typography>
        {button}
      </TableCell>
    </TableRow>
  );
}
