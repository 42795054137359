import { COLORS } from "@/constants/Colors/colors";
import { Box, Typography } from "@mui/material";
import useCustomQuery from "@/hooks/useCustomQuery";
import React from "react";
import { HashLoader } from "react-spinners";
import { UserCourseExam } from "./UserCourseExam/UserCourseExam";
import { useSelector } from "react-redux";
import NoDataFound from '@/components/NoDataFound/NoDataFound';
import generalCourses from "../UserCourse.module.css"


export const UserCourseExams = ({ id, bought }) => {
  const globalState = useSelector((state) => state?.global);
  const lang = globalState?.lang;
  const { data, isPending } = useCustomQuery({
    url: `exam/getCourseExamsStudent?course_id=${id}`,
    key: ["courseExamsForStudent", id, lang],
    lang,
  });
  return (
    <Box sx={{ padding: 5, width: "100%" }}>

      {isPending ? (
        <Box width={"100%"} display={"flex"} justifyContent={"center"}>
          <HashLoader color={COLORS.PRIMARY.MAIN} />
        </Box>
        ) : data?.data?.data?.length > 0 ? (
        data?.data?.data?.map((item, key) => (
          <UserCourseExam key={key} exam={item} index={key} bought={bought} />
        ))
      ) : (
        <div className={generalCourses.noDataImage} style={{ position: 'relative', width: 'calc(100vw - 450px)' }}>
          <NoDataFound title={lang == "ar" ? "لا توجد إمتحانات" : "There are no Exams"} />
        </div>
      )}
    </Box>
  );
};
