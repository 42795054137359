import React, { useState, useEffect } from "react";
import { FormControl, Select, MenuItem, Box } from "@mui/material";
import { styled } from "@mui/system";
import { useDispatch } from "react-redux";
import { setLang } from "@/store/reducers/globalReducer";
import { FONTS } from "@/constants/Fonts/fonts";
import style from "./LangTuggle.module.css"
import USA from "../../../../assets/images/flags/USA.png"
import Egypt from "../../../../assets/images/flags/Egypt.png"

export default function LangTuggle() {
  const StyledFormControl = styled(FormControl)(({ theme }) => ({
    margin: theme.spacing(1),
    minWidth: 90,
  }));

  const dispatch = useDispatch();

  const [language, setLanguage] = useState("en");
  useEffect(() => {
    const savedLanguage = localStorage.getItem("selectedLanguage");
    if (savedLanguage) {
      setLanguage(savedLanguage);
    }
  }, []);

  const handleLanguageChange = (event) => {
    const selectedLanguage = event.target.value;
    setLanguage(selectedLanguage);
    dispatch(setLang(selectedLanguage));
  };

  return (
    <>
      <div className={style.translationContainer}>
        <StyledFormControl>
          <Select
            value={language}
            onChange={handleLanguageChange}
            displayEmpty
            inputProps={{ "aria-label": "Language" }}
            sx={{ fontSize: "0.8rem", fontFamily: FONTS.ENGLISH }}
          >
            <MenuItem value="en">
              <span className={style.flag} >
                <img src={USA} />
                EN
              </span>
            </MenuItem>
            <MenuItem value="ar">
              <span className={style.flag} >
                <img src={Egypt} />
                AR
              </span>
            </MenuItem>
          </Select>
        </StyledFormControl>
      </div>
    </>
  );
}
