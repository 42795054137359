// import React from "react";
// import { useUserSubsContext } from "../Context/UserSubsContext";
// import { Box, Container } from "@mui/material";
// import NoItemsText from "@shared/NoItemText/NoItemText";
// import CourseCard from "@/modules/home/components/atoms/CouresCard/CourseCard";
// import { api_url } from "@/constants/base_url";
// import { Timer } from "@shared/Timer/Timer";
// import { currentUser } from "@shared/Helpers/encryptionHelpers";
// import { HashLoader } from "react-spinners";
// import { COLORS } from "@/constants/Colors/colors";
// import Paginator from "@shared/Pagination/Paginator";
// import { useSelector } from "react-redux";

// export const MySubscriptions = () => {
//   const { data, isPending, setPage, page, totalPages } = useUserSubsContext();
//   const globalState = useSelector((state) => state?.global);
//   if (isPending) {
//     return (
//       <Box
//         sx={{
//           width: "100%",
//           display: "flex",
//           alignItems: "center",
//           justifyContent: "center",
//           paddingY: 5,
//         }}
//       >
//         <HashLoader color={COLORS.PRIMARY.MAIN} />
//       </Box>
//     );
//   }


//   return (
//     <Box sx={{ minHeight: "84vh" }}>
//       {data?.data?.data?.data?.length > 0 ? (
//         <Box paddingY={5}>
//           <Paginator page={page} setPage={setPage} totalPages={totalPages} />
//           <Container
//             maxWidth="2xl"
//             sx={{
//               display: "flex",
//               alignItems: "center",
//               justifyContent: "center",
//               flexWrap: "wrap",
//               gap: 5,
//             }}
//           >
//             {data?.data?.data?.data?.map((item, i) => (
//               <Box sx={{ position: "relative", width: "fit-content" }} key={i}>
//                 <CourseCard
//                   width={"350px"}
//                   key={item.course.id}
//                   img={`${api_url.slice(0, -7)}${item.course.image.slice(1)}`}
//                   name={item.course.name}
//                   price={item.course.price}
//                   type={item.course.isPayByModule ? "بالحصة" : "بالكورس"}
//                   teacher={item.course.teacher}
//                   link={`/course/${item.course.id}`}
//                   id={item.course.id}
//                   description={item.course.description}
//                   bought

//                 />
//                 <Timer
//                   time={item.expires_at}
//                   sx={{ position: "absolute", top: 0, left: 0 }}
//                 />
//               </Box>
//             ))}
//           </Container>
//         </Box>
//       ) : (
//         <Box
//           sx={{
//             width: "100%",
//             display: "flex",
//             alignItems: "center",
//             justifyContent: "center",
//             paddingY: 5,
//           }}
//         >
//           <NoItemsText text={"ليس لديك اشتراكات حتي الآن"} />
//         </Box>
//       )}
//     </Box>
//   );
// };



import React from "react";
import { useUserSubsContext } from "../Context/UserSubsContext";
import { Box, Container } from "@mui/material";
import NoItemsText from "@shared/NoItemText/NoItemText";
import CourseCard from "@/modules/home/components/atoms/CouresCard/CourseCard";
import { api_url } from "@/constants/base_url";
import { Timer } from "@shared/Timer/Timer";
import { currentUser } from "@shared/Helpers/encryptionHelpers";
import { HashLoader } from "react-spinners";
import { COLORS } from "@/constants/Colors/colors";
import Paginator from "@shared/Pagination/Paginator";
import { useSelector } from "react-redux";
import subscriptionsStyle from "./MySubscriptions.module.css"
import NoDataFound from "@/components/NoDataFound/NoDataFound";
import { FONTS } from "@/constants/Fonts/fonts";

export const MySubscriptions = () => {
  const { data, isPending, setPage, page, totalPages } = useUserSubsContext();
  const globalState = useSelector((state) => state?.global);
  const lang = globalState?.lang;
  const isDarkMode = globalState?.isDarkMode;

  if (isPending) {
    return (
      <Box
        sx={{
          width: "100%",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          paddingY: 5,
        }}
      >
        <HashLoader color={COLORS.PRIMARY.MAIN} />
      </Box>
    );
  }


  return (
    <>
      <main style={{ direction: lang === 'ar' ? 'rtl' : 'ltr' }}>
        {data?.data?.data?.data?.length > 0 ? (
          <>
            <div className={`${subscriptionsStyle.paginatorContainer} pageHeader`}>
              <Paginator page={page} setPage={setPage} totalPages={totalPages} />
            </div>

            <div className={`${subscriptionsStyle.cardsContainer} cardsContainer`}>
              <CardsContainer lang={lang} data={data} />
            </div>
          </>
        ) : (
          <div style={{ position: 'relative', top:"40px", width: 'calc(100vw - 100px)', fontFamily: lang === 'en' ? FONTS.ENGLISH : "" }}>
            <NoDataFound title={
              lang == "ar"
                ? "ليس لديك اشتراكات حتي الآن"
                : "You don't have any subscriptions yet."
            } />
          </div>
        )}
      </main>
    </>
  );
};


export const CardsContainer = ({ data }) => {
  return (
    <>
      {data?.data?.data?.data?.map((item, i) => (
        <CourseCard
          key={i}
          // key={item.course.id}
          time={item.expires_at}
          color={COLORS.SECONDARY.MAIN}
          img={`${api_url.slice(0, -7)}${item.course.image.slice(1)}`}
          name={item.course.name}
          price={item.course.price}
          type={item.course.isPayByModule ? "بالحصة" : "بالكورس"}
          teacher={item.course.teacher}
          link={`/course/${item.course.id}`}
          id={item.course.id}
          description={item.course.description}
          bought
        />
      ))}
    </>
  )
}
