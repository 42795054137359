import { COLORS } from "@/constants/Colors/colors";
import { Box } from "@mui/material";
import useCustomQuery from "@/hooks/useCustomQuery";
import NoItemsText from "@shared/NoItemText/NoItemText";
import Paginator from "@shared/Pagination/Paginator";
import React, { useEffect, useState } from "react";
import { HashLoader } from "react-spinners";
import { UserBookOrder } from "./UserBookOrder/UserBookOrder";
import { useSelector } from "react-redux";
import pageStyle from "./UserBookOrders.module.css"
import NoDataFound from "@/components/NoDataFound/NoDataFound";
// import { NoDataFound } from '@/components/NoDataFound/NoDataFound';

export const UserBookOrders = () => {
  const globalState = useSelector((state) => state?.global);
  const lang = globalState?.lang;
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState();
  const { data, isPending, isSuccess } = useCustomQuery({
    url: `bookOrder/showMyOrders`,
    key: ["my orders", lang],
    lang,
  });

  useEffect(() => {
    if (isSuccess) {
      setTotalPages(data?.data?.last_page);
    }
  }, [data, isSuccess]);
  return (
    <Box sx={{ width: "100%", paddingX: 2 }}>
      <div className={`${pageStyle.pageHeader} pageHeader`}>
        <Paginator page={page} totalPages={totalPages} setPage={setPage} />
      </div>
      {isPending ? (
        <Box
          sx={{
            width: "100%",
            paddingY: 5,
            display: "flex",
            justifyContent: "center",
          }}
        >
          <HashLoader color={COLORS.PRIMARY.MAIN} />
        </Box>
      ) : data?.data?.data?.length > 0 ? (
        <div className={`${pageStyle.cardsContainer} cardsContainer`} style={{direction: lang==='ar' ? 'rtl' : 'ltr'}}>
          {data?.data?.data?.map((item) => (
            <>
              <UserBookOrder order={item} key={item.id} />
            </>

          ))}
        </div>
      ) : (
        <div style={{ position: 'relative', width: 'calc(100vw - 100px)' }}>
          <NoDataFound title={lang == "ar" ? "لا توجد طلبات بعد" : "There is no orders yet"} />
        </div>
      )}
    </Box>
  );
};
