import useCustomQuery from "@/hooks/useCustomQuery";
import { useParams } from "react-router-dom";
import { Box, Typography } from "@mui/material";
import { COLORS } from "@/constants/Colors/colors";
import { HashLoader } from "react-spinners";
import CourseFileBox from "../../../../Admin/Pages/Course/CourseFiles/CourseFileBox/CourseFileBox";
import { CreateCourseFile } from "../../../../Admin/Pages/Course/CourseFiles/CreateCourseFile/CreateCourseFile";
import { useSelector } from 'react-redux';
import fileStyle from "./CourseFiles.module.css"

export const CourseFiles = () => {
  const lang = useSelector((state) => state?.global?.lang);
  const { id } = useParams();

  const { data, isPending, isError } = useCustomQuery({
    url: `drive?type=course&id=${id}`,
    method: "get",
    key: [`files`, id, lang],
    lang,
  });

  return (
    <>
      <div className={fileStyle.buttonContainer}>
        <CreateCourseFile id={id} />
      </div>

      {isPending ? (
        <Box marginTop={2}>
          <HashLoader size={20} color={COLORS.PRIMARY.MAIN} />
        </Box>
      ) : isError ? (
        <></>
      ) : data?.data?.length == 0 ? (
        <Typography sx={{ fontSize: 30, fontFamily: "Noor-bold" }}>
          {lang == "ar" ? "لا يوجد ملفات" : "There are no files"}
        </Typography>
      ) : (
        <div className={fileStyle.filesContainer}>
          {data?.data?.map((item, index) => (
            <CourseFileBox key={index} item={item} index={index} />
          ))}
        </div>

      )}
    </>
  );
};
