// import React, { useState } from "react";
// import { Box, Container, Grid, ListItem, Typography } from "@mui/material";
// import videoPlayer from "@assets/images/play.png";
// import book from "@assets/images/Book.png";
// import link from "@assets/images/link.png";
// import doc from "@assets/images/Document.png";
// import { CourseVideo } from "./CourseVideo/CourseVideo";
// import { CourseSummaries } from "./CourseSummaries/CourseSummaries";
// import { CourseHomeWorkList } from "./CourseHomeWorkList/CourseHomeWorkList";
// import { CourseExamsList } from "./CourseExamsList/CourseExamsList";
// import { useParams } from "react-router-dom";
// import useCustomQuery from "@/hooks/useCustomQuery";
// import { api_url } from "@/constants/base_url";
// import { COLORS } from "@/constants/Colors/colors";
// import { HashLoader } from "react-spinners";
// import { useSelector } from "react-redux";
// import moduleStyle from "./Module.module.css"

// export const Module = () => {
//   const globalState = useSelector((state) => state?.global);
//   const lang = globalState?.lang;
//   const [taps, setTaps] = useState("video");
//   const { id, mid } = useParams();

//   const {
//     data: moduleDetailsData,
//     isPending: isModuleDetailsLoading,
//     isError: isModuleDetailsError,
//   } = useCustomQuery({
//     url: `${api_url}module?id=${mid}`,
//     key: ["module", id, mid, lang],
//     lang,
//   });
//   const { data: courseDetailsData } = useCustomQuery({
//     url: `${api_url}course/${id}`,
//     key: ["course", id, lang],
//     lang,
//   });
//   const { data: homeworkData } = useCustomQuery({
//     key: ["homeworkData", mid, lang],
//     url: `${api_url}homeWork/getHomeWork?lesson_module_id=${mid}`,
//     lang,
//   });
//   const { data: examData } = useCustomQuery({
//     key: ["examData", mid, lang],
//     url: `${api_url}exam/getExam?lesson_module_id=${mid}`,
//     lang,
//   });

//   const isEmptyObject = (obj) => {
//     return obj && Object.keys(obj).length === 0 && obj.constructor === Object;
//   };

//   const content = (
//     <Box color={"black"} sx={{ padding: "50px 0px" }}>
//       <Grid container spacing={2}>
//         <Grid item xs={12} md={3}>
//           <Box
//             sx={{
//               backgroundColor: COLORS.DARK_GRAY,
//               borderRadius: "20px 20px 0px 0px",
//             }}
//           >
//             <Typography
//               fontFamily={"Noor-bold"}
//               fontSize={20}
//               sx={{
//                 bgcolor: COLORS.PRIMARY.MAIN,
//                 padding: "15px 20px",
//                 borderRadius: "20px 20px 0px 0px",
//                 color: "white",
//               }}
//             >
//               {moduleDetailsData?.name}
//             </Typography>

//             <ul
//               style={{
//                 listStyle: "none",
//                 margin: "0",
//                 padding: "0",
//                 color: "white",
//               }}
//             >
//               <ListItem
//                 onClick={() => {
//                   setTaps("video");
//                 }}
//                 sx={{
//                   marginBottom: "10px",
//                   display: "flex",
//                   justifyContent: "space-between",
//                   alignItems: "center",
//                   padding: "15px 20px",
//                   transition: "0.4s",
//                   cursor: "pointer",
//                   bgcolor: taps === "video" ? "#326cb36E" : "",
//                   borderRight:
//                     taps === "video"
//                       ? `7px solid ${COLORS.PRIMARY.MAIN}`
//                       : `7px solid ${COLORS.DARK_GRAY}`,
//                   marginTop: "15px",
//                   "&:hover": {
//                     bgcolor: "#326cb36E",
//                     borderRight: `7px solid ${COLORS.PRIMARY.MAIN}`,
//                   },
//                 }}
//               >
//                 <Typography>
//                   {lang == "ar" ? "الفيديوهات" : "Videos"}
//                 </Typography>
//                 <div
//                   style={{
//                     background: COLORS.PRIMARY.MAIN,
//                     padding: "6px 7px",
//                     borderRadius: "50%",
//                     display: "flex",
//                   }}
//                 >
//                   <img
//                     src={videoPlayer}
//                     alt=""
//                     style={{
//                       width: "15px",
//                     }}
//                   />
//                 </div>
//               </ListItem>
//               <ListItem
//                 onClick={() => {
//                   setTaps("summaries");
//                 }}
//                 sx={{
//                   marginBottom: "10px",
//                   display: "flex",
//                   justifyContent: "space-between",
//                   alignItems: "center",
//                   padding: "15px 20px",
//                   transition: "0.4s",
//                   bgcolor: taps === "summaries" ? "#326cb36E" : "",
//                   borderRight:
//                     taps === "summaries"
//                       ? `7px solid ${COLORS.PRIMARY.MAIN}`
//                       : `7px solid ${COLORS.DARK_GRAY}`,
//                   cursor: "pointer",
//                   "&:hover": {
//                     bgcolor: "#326cb36E",
//                     borderRight: `7px solid ${COLORS.PRIMARY.MAIN}`,
//                   },
//                 }}
//               >
//                 <Typography>{lang == "ar" ? "الملازم" : "Files"}</Typography>
//                 <div
//                   style={{
//                     background: COLORS.PRIMARY.MAIN,
//                     padding: "7px 7px",
//                     borderRadius: "50%",
//                     display: "flex",
//                   }}
//                 >
//                   <img
//                     src={book}
//                     alt=""
//                     style={{
//                       width: "16px",
//                     }}
//                   />
//                 </div>
//               </ListItem>
//               <ListItem
//                 onClick={() => {
//                   setTaps("exams");
//                 }}
//                 sx={{
//                   marginBottom: "10px",
//                   display: "flex",
//                   justifyContent: "space-between",
//                   alignItems: "center",
//                   padding: "15px 20px",
//                   transition: "0.4s",
//                   cursor: "pointer",
//                   bgcolor: taps === "exams" ? "#326cb36E" : "",
//                   borderRight:
//                     taps === "exams"
//                       ? `7px solid ${COLORS.PRIMARY.MAIN}`
//                       : `7px solid ${COLORS.DARK_GRAY}`,
//                   "&:hover": {
//                     bgcolor: "#326cb36E",
//                     borderRight: `7px solid ${COLORS.PRIMARY.MAIN}`,
//                   },
//                 }}
//               >
//                 <Typography>{lang == "ar" ? "الامتحان" : "Exam"}</Typography>
//                 <div
//                   style={{
//                     background: COLORS.PRIMARY.MAIN,
//                     padding: "6px 7px",
//                     borderRadius: "50%",
//                     display: "flex",
//                   }}
//                 >
//                   <img
//                     src={link}
//                     alt=""
//                     style={{
//                       width: "15px",
//                     }}
//                   />
//                 </div>
//               </ListItem>
//               <ListItem
//                 onClick={() => {
//                   setTaps("home-work");
//                 }}
//                 sx={{
//                   marginBottom: "10px",
//                   display: "flex",
//                   justifyContent: "space-between",
//                   alignItems: "center",
//                   padding: "15px 20px",
//                   transition: "0.4s",
//                   cursor: "pointer",
//                   bgcolor: taps === "home-work" ? "#326cb36E" : "",
//                   borderRight:
//                     taps === "home-work"
//                       ? `7px solid ${COLORS.PRIMARY.MAIN}`
//                       : `7px solid ${COLORS.DARK_GRAY}`,
//                   "&:hover": {
//                     bgcolor: "#326cb36E",
//                     borderRight: `7px solid ${COLORS.PRIMARY.MAIN}`,
//                   },
//                 }}
//               >
//                 <Typography>{lang == "ar" ? "الواجب" : "Homework"}</Typography>
//                 <div
//                   style={{
//                     background: COLORS.PRIMARY.MAIN,
//                     padding: "5px 7px",
//                     borderRadius: "50%",
//                     display: "flex",
//                   }}
//                 >
//                   <img
//                     src={doc}
//                     alt=""
//                     style={{
//                       width: "14px",
//                     }}
//                   />
//                 </div>
//               </ListItem>
//             </ul>
//           </Box>
//           <div
//             style={{
//               textAlign: "center",
//               marginTop: "15px",
//               color: globalState?.isDarkMode ? COLORS.WHITE : COLORS.BLACK,
//             }}
//           >
//             <Typography fontFamily={"Noor-bold"} fontSize={22}>
//               {lang == "ar" ? "كورس" : "Course"} {courseDetailsData?.name}
//             </Typography>
//           </div>
//         </Grid>
//         <Grid item xs={12} md={9} sx={{ overflow: "hidden" }}>
//           {taps === "video" && (
//             <CourseVideo
//               videos={moduleDetailsData?.lessons}
//               videoApi={moduleDetailsData?.lesson_api_link}
//             />
//           )}
//           {taps === "summaries" && <CourseSummaries />}
//           {taps === "exams" && (
//             <>
//               {!examData?.data && isEmptyObject(examData?.data) ? (
//                 <Typography
//                   fontSize={32}
//                   fontFamily={"Noor-bold"}
//                   textAlign={"center"}
//                   sx={{
//                     color: globalState?.isDarkMode
//                       ? COLORS.WHITE
//                       : COLORS.DARK_GRAY,
//                   }}
//                 >
//                   {lang == "ar" ? "لا يوجد امتحان" : "There is no exam"}
//                 </Typography>
//               ) : (
//                 <CourseExamsList
//                   // setExamId={setExamId}
//                   examId={examData?.data?.id}
//                   // startFunc={startFunc}
//                   exam={examData}
//                 />
//               )}
//             </>
//           )}
//           {taps === "home-work" &&
//             (homeworkData?.data ? (
//               <CourseHomeWorkList homework={homeworkData} />
//             ) : (
//               <Typography
//                 fontSize={32}
//                 fontFamily={"Noor-bold"}
//                 textAlign={"center"}
//                 sx={{
//                   color: globalState?.isDarkMode
//                     ? COLORS.WHITE
//                     : COLORS.DARK_GRAY,
//                 }}
//               >
//                 {lang == "ar" ? "لا يوجد واجب" : "There is no homework"}
//               </Typography>
//             ))}
//         </Grid>
//       </Grid>
//     </Box>
//   );

//   return (
//     <>
//       <Container maxWidth="2xl" sx={{ minHeight: "84vh" }}>
//         {isModuleDetailsLoading ? (
//           <Box
//             sx={{
//               width: "100%",
//               height: "84vh",
//               display: "flex",
//               alignItems: "center",
//               justifyContent: "center",
//             }}
//           >
//             <HashLoader color={COLORS.PRIMARY.MAIN} />
//           </Box>
//         ) : (
//           content
//         )}
//       </Container>
//     </>
//   );
// };

// import React, { useState } from "react";
// import { Box, Container, Grid, ListItem, Typography } from "@mui/material";
// import videoPlayer from "@assets/images/play.png";
// import book from "@assets/images/Book.png";
// import link from "@assets/images/link.png";
// import doc from "@assets/images/Document.png";
// import { CourseVideo } from "./CourseVideo/CourseVideo";
// import { CourseSummaries } from "./CourseSummaries/CourseSummaries";
// import { CourseHomeWorkList } from "./CourseHomeWorkList/CourseHomeWorkList";
// import { CourseExamsList } from "./CourseExamsList/CourseExamsList";
// import { useParams } from "react-router-dom";
// import useCustomQuery from "@/hooks/useCustomQuery";
// import { api_url } from "@/constants/base_url";
// import { COLORS } from "@/constants/Colors/colors";
// import { HashLoader } from "react-spinners";
// import { useSelector } from "react-redux";
// import { FaVideo } from "react-icons/fa6";
// import { MdOndemandVideo } from "react-icons/md";

// import moduleStyle from "./Module.module.css"
// import { FONTS } from "@/constants/Fonts/fonts";
// import  Tabs from '@/components/TabbedPages/Tabs';

// export const Module = () => {
//   const globalState = useSelector((state) => state?.global);
//   const lang = globalState?.lang;
//   const isDarkMode = globalState?.isDarkMode;

//   const [taps, setTaps] = useState("video");
//   const { id, mid } = useParams();

//   const {
//     data: moduleDetailsData,
//     isPending: isModuleDetailsLoading,
//     isError: isModuleDetailsError,
//   } = useCustomQuery({
//     url: `${api_url}module?id=${mid}`,
//     key: ["module", id, mid, lang],
//     lang,
//   });
//   const { data: courseDetailsData } = useCustomQuery({
//     url: `${api_url}course/${id}`,
//     key: ["course", id, lang],
//     lang,
//   });
//   const { data: homeworkData } = useCustomQuery({
//     key: ["homeworkData", mid, lang],
//     url: `${api_url}homeWork/getHomeWork?lesson_module_id=${mid}`,
//     lang,
//   });
//   const { data: examData } = useCustomQuery({
//     key: ["examData", mid, lang],
//     url: `${api_url}exam/getExam?lesson_module_id=${mid}`,
//     lang,
//   });

//   const isEmptyObject = (obj) => {
//     return obj && Object.keys(obj).length === 0 && obj.constructor === Object;
//   };

//   const pages = [
//     {
//       page: <CourseSummaries />,
//       title: lang == "ar" ? "الملازم" : "Files"
//     },
//     {
//       page: <CourseExamsList examId={examData?.data?.id} exam={examData} />,
//       title: lang === 'ar' ? "الإمتحان" : "Exam"
//     }
//     ,
//     {
//       page: <CourseHomeWorkList homework={homeworkData} />,
//       title: lang === 'ar' ? "الواجب" : "Homework"
//     }
//   ];

//   const content = (
//     <div className={moduleStyle.gridContainer}>
//       <div className={moduleStyle.videoSection}>
//         <div className={moduleStyle.moduleName}>
//           <div className={moduleStyle.icon}><FaVideo /></div>
//           <h1>{moduleDetailsData?.name}</h1>
//         </div>
//           <CourseVideo
//             videos={moduleDetailsData?.lessons}
//             videoApi={moduleDetailsData?.lesson_api_link}
//           />
//       </div>
//       <div className={moduleStyle.tabsContainer}>
//         <Tabs InsideCourse isDarkMode={isDarkMode} color={COLORS.SECONDARY.MAIN} pages={pages} lang={lang} />
//       </div>

//     </div>
//   );

//   return (
//     <>
//       <Container maxWidth="2xl" sx={{ minHeight: "84vh" }}>
//         {isModuleDetailsLoading ? (
//           <Box
//             sx={{
//               width: "100%",
//               height: "84vh",
//               display: "flex",
//               alignItems: "center",
//               justifyContent: "center",
//             }}
//           >
//             <HashLoader color={COLORS.PRIMARY.MAIN} />
//           </Box>
//         ) : (
//           <div style={{ direction: lang === 'ar' ? 'rtl' : 'ltr', fontFamily: lang === 'en' ? FONTS.ENGLISH : "" }}>
//             {content}
//           </div>

//         )}
//       </Container>
//     </>
//   );
// };

import React, { useState } from "react";
import { Box, Container, Grid, ListItem, Typography } from "@mui/material";
import videoPlayer from "@assets/images/play.png";
import book from "@assets/images/Book.png";
import link from "@assets/images/link.png";
import doc from "@assets/images/Document.png";
import { CourseVideo } from "./CourseVideo/CourseVideo";
import { CourseSummaries } from "./CourseSummaries/CourseSummaries";
import { CourseHomeWorkList } from "./CourseHomeWorkList/CourseHomeWorkList";
import { CourseExamsList } from "./CourseExamsList/CourseExamsList";
import { useParams } from "react-router-dom";
import useCustomQuery from "@/hooks/useCustomQuery";
import { api_url } from "@/constants/base_url";
import { COLORS } from "@/constants/Colors/colors";
import { HashLoader } from "react-spinners";
import { useSelector } from "react-redux";
import moduleStyle from "./Module.module.css";
import { FaVideo } from "react-icons/fa6";
import { FONTS } from "@/constants/Fonts/fonts";
import UseWindowWidth from "./UseWindowWidth";
import { useEffect } from "react";

export const Module = () => {
  const globalState = useSelector((state) => state?.global);
  const lang = globalState?.lang;
  const isDarkMode = globalState?.isDarkMode;

  const [taps, setTaps] = useState("summaries");
  const { id, mid } = useParams();

  const width = UseWindowWidth();

  useEffect(() => {
    if (width <= 790) {
      setTaps("video");
    } else {
      setTaps("summaries");
    }
  }, [width]);

  const {
    data: moduleDetailsData,
    isPending: isModuleDetailsLoading,
    isError: isModuleDetailsError,
    error,
  } = useCustomQuery({
    url: `${api_url}module?id=${mid}`,
    key: ["module", id, mid, lang],
    lang,
  });

  const { data: courseDetailsData } = useCustomQuery({
    url: `${api_url}course/${id}`,
    key: ["course", id, lang],
    lang,
  });
  const { data: homeworkData } = useCustomQuery({
    key: ["homeworkData", mid, lang],
    url: `${api_url}homeWork/getHomeWork?lesson_module_id=${mid}`,
    lang,
  });
  const { data: examData } = useCustomQuery({
    key: ["examData", mid, lang],
    url: `${api_url}exam/getExam?lesson_module_id=${mid}`,
    lang,
  });

  console.log("module: ", moduleDetailsData);
  console.log("data:", courseDetailsData);
  if (isModuleDetailsError) {
    console.log(error?.response?.data?.errors, "isModuleDetailsError");
    return (
      <div
        className={moduleStyle.container}
        style={{
          direction: lang === "ar" ? "rtl" : "ltr",
          fontFamily: lang === "en" ? FONTS.ENGLISH : "",
          padding: "50px",
          textAlign: "center",
          fontSize: "3rem",
        }}
      >
        <h1>{error?.response?.data?.errors}</h1>
      </div>
    );
  }
  const content = (
    <div
      className={moduleStyle.container}
      style={{
        direction: lang === "ar" ? "rtl" : "ltr",
        fontFamily: lang === "en" ? FONTS.ENGLISH : "",
      }}
    >
      <div className={moduleStyle.gridContainer}>
        <div className={moduleStyle.gridHeader}>
          <div className={moduleStyle.moduleName}>
            <div className={moduleStyle.icon}>
              <FaVideo />
            </div>
            <h1>{moduleDetailsData?.name}</h1>
          </div>

          <ul className={moduleStyle.tabsContainer}>
            <li
              onClick={() => {
                setTaps("summaries");
              }}
              style={{
                backgroundColor:
                  taps === "summaries" ? COLORS.SECONDARY.MAIN : "",
                fontWeight: taps === "summaries" ? "bold" : "",
                color:
                  taps === "summaries"
                    ? isDarkMode
                      ? COLORS.BLACK
                      : COLORS.WHITE
                    : "",
              }}
            >
              <p>{lang == "ar" ? "الملازم" : "Files"}</p>
            </li>
            <li
              onClick={() => {
                setTaps("exams");
              }}
              style={{
                backgroundColor: taps === "exams" ? COLORS.SECONDARY.MAIN : "",
                fontWeight: taps === "exams" ? "bold" : "",
                color:
                  taps === "exams"
                    ? isDarkMode
                      ? COLORS.BLACK
                      : COLORS.WHITE
                    : "",
              }}
            >
              <p>{lang == "ar" ? "الامتحان" : "Exam"}</p>
            </li>
            <li
              onClick={() => {
                setTaps("home-work");
              }}
              style={{
                backgroundColor:
                  taps === "home-work" ? COLORS.SECONDARY.MAIN : "",
                fontWeight: taps === "home-work" ? "bold" : "",
                color:
                  taps === "home-work"
                    ? isDarkMode
                      ? COLORS.BLACK
                      : COLORS.WHITE
                    : "",
              }}
            >
              <p>{lang == "ar" ? "الواجب" : "Homework"}</p>
            </li>
          </ul>
        </div>
        <div className={moduleStyle.videoSection}>
          <CourseVideo videos={moduleDetailsData?.lessons} />
        </div>

        <div className={moduleStyle.tabsSection}>
          <div className={moduleStyle.tabsContentContainer}>
            {taps === "summaries" && <CourseSummaries />}
            {taps === "exams" && (
              <>
                {Object.keys(examData?.data).length ? (
                  <CourseExamsList
                    // setExamId={setExamId}
                    examId={examData?.data?.id}
                    // startFunc={startFunc}
                    exam={examData}
                  />
                ) : (
                  <Typography
                    fontSize={32}
                    fontFamily={lang === "en" ? FONTS.ENGLISH : ""}
                    textAlign={"center"}
                    sx={{
                      color: globalState?.isDarkMode
                        ? COLORS.WHITE
                        : COLORS.DARK_GRAY,
                    }}
                  >
                    {lang == "ar" ? "لا يوجد امتحان" : "There is no exam"}
                  </Typography>
                )}
              </>
            )}
            {taps === "home-work" &&
              (homeworkData?.data != null &&
              homeworkData?.data?.length !== 0 ? (
                <CourseHomeWorkList homework={homeworkData} />
              ) : (
                <Typography
                  fontSize={32}
                  fontFamily={lang === "en" ? FONTS.ENGLISH : ""}
                  textAlign={"center"}
                  sx={{
                    color: globalState?.isDarkMode
                      ? COLORS.WHITE
                      : COLORS.DARK_GRAY,
                  }}
                >
                  {lang == "ar" ? "لا يوجد واجب" : "There is no homework"}
                </Typography>
              ))}
          </div>

          {/* <div
            style={{
              textAlign: "center",
              marginTop: "15px",
              color: globalState?.isDarkMode ? COLORS.WHITE : COLORS.BLACK,
            }}
          >
            <Typography fontFamily={"Noor-bold"} fontSize={22}>
              {lang == "ar" ? "كورس" : "Course"} {courseDetailsData?.name}
            </Typography>
          </div> */}
        </div>
      </div>
    </div>
  );

  const mobileContent = (
    <div
      style={{
        direction: lang === "ar" ? "rtl" : "ltr",
        fontFamily: lang === "en" ? FONTS.ENGLISH : "",
      }}
    >
      <div>
        <div className={moduleStyle.moduleName}>
          <div className={moduleStyle.icon}>
            <FaVideo />
          </div>
          <h1>{moduleDetailsData?.name}</h1>
        </div>
        <ul className={moduleStyle.tabsContainer}>
          <li
            onClick={() => {
              setTaps("video");
            }}
            style={{
              backgroundColor: taps === "video" ? COLORS.SECONDARY.MAIN : "",
              fontWeight: taps === "video" ? "bold" : "",
              color:
                taps === "video"
                  ? isDarkMode
                    ? COLORS.BLACK
                    : COLORS.WHITE
                  : "",
            }}
          >
            <p>{lang == "ar" ? "الفيديوهات" : "Videos"}</p>
          </li>
          <li
            onClick={() => {
              setTaps("summaries");
            }}
            style={{
              backgroundColor:
                taps === "summaries" ? COLORS.SECONDARY.MAIN : "",
              fontWeight: taps === "summaries" ? "bold" : "",
              color:
                taps === "summaries"
                  ? isDarkMode
                    ? COLORS.BLACK
                    : COLORS.WHITE
                  : "",
            }}
          >
            <p>{lang == "ar" ? "الملازم" : "Files"}</p>
          </li>
          <li
            onClick={() => {
              setTaps("exams");
            }}
            style={{
              backgroundColor: taps === "exams" ? COLORS.SECONDARY.MAIN : "",
              fontWeight: taps === "exams" ? "bold" : "",
              color:
                taps === "exams"
                  ? isDarkMode
                    ? COLORS.BLACK
                    : COLORS.WHITE
                  : "",
            }}
          >
            <p>{lang == "ar" ? "الامتحان" : "Exam"}</p>
          </li>
          <li
            onClick={() => {
              setTaps("home-work");
            }}
            style={{
              backgroundColor:
                taps === "home-work" ? COLORS.SECONDARY.MAIN : "",
              fontWeight: taps === "home-work" ? "bold" : "",
              color:
                taps === "home-work"
                  ? isDarkMode
                    ? COLORS.BLACK
                    : COLORS.WHITE
                  : "",
            }}
          >
            <p>{lang == "ar" ? "الواجب" : "Homework"}</p>
          </li>
        </ul>
      </div>

      <div>
        {taps === "video" && (
          <CourseVideo
            videos={moduleDetailsData?.lessons}
            videoApi={moduleDetailsData?.lesson_api_link}
          />
        )}
        {taps === "summaries" && <CourseSummaries />}
        {taps === "exams" && (
          <>
            {Object.keys(examData?.data).length ? (
              <CourseExamsList
                // setExamId={setExamId}
                examId={examData?.data?.id}
                // startFunc={startFunc}
                exam={examData}
              />
            ) : (
              <Typography
                fontSize={32}
                fontFamily={lang === "en" ? FONTS.ENGLISH : ""}
                textAlign={"center"}
                sx={{
                  color: globalState?.isDarkMode
                    ? COLORS.WHITE
                    : COLORS.DARK_GRAY,
                }}
              >
                {lang == "ar" ? "لا يوجد امتحان" : "There is no exam"}
              </Typography>
            )}
          </>
        )}
        {taps === "home-work" &&
          (homeworkData?.data ? (
            <CourseHomeWorkList homework={homeworkData} />
          ) : (
            <Typography
              fontSize={32}
              fontFamily={"Noor-bold"}
              textAlign={"center"}
              sx={{
                color: globalState?.isDarkMode
                  ? COLORS.WHITE
                  : COLORS.DARK_GRAY,
              }}
            >
              {lang == "ar" ? "لا يوجد واجب" : "There is no homework"}
            </Typography>
          ))}
      </div>
    </div>
  );

  return (
    <>
      <div>
        {isModuleDetailsLoading ? (
          <Box
            sx={{
              width: "100%",
              height: "84vh",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <HashLoader color={COLORS.PRIMARY.MAIN} />
          </Box>
        ) : (
          <>
            <div className={moduleStyle.mobileLayout}>{mobileContent}</div>
            <div className={moduleStyle.normalLayout}>{content}</div>
          </>
        )}
      </div>
    </>
  );
};
