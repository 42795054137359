import { COLORS } from "@/constants/Colors/colors";
import { Box, Typography, IconButton } from "@mui/material";
import useCustomQuery from "@/hooks/useCustomQuery";
import { useParams } from "react-router-dom";
import { HashLoader } from "react-spinners";
import ModuleDetailsCollapse from "../../../../Admin/Pages/Course/CourseModules/ModuleDetailsCollapse/ModuleDetailsCollapse/ModuleDetailsCollapse";
import { AddModule } from "../../../../Admin/Pages/Course/CourseModules/AddModule/AddModule";
import useSwapOrder from "../../../../hooks/useSwapOrder";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import { useSelector } from "react-redux";
import courseStyle from "./CourseModules.module.css"
import { FONTS } from "@/constants/Fonts/fonts";

export const CourseModules = ({ course }) => {
  const globalState = useSelector((state) => state?.global);
  const lang = globalState?.lang;
  const isDarkMode = globalState?.isDarkMode;

  // --------------- hooks ---------------
  const { id } = useParams();
  const { data: courseModules, isPending } = useCustomQuery({
    url: `course/${+id}`,
    key: ["course modules", id, lang],
    enabled: !isNaN(+id),
    lang,
  });

  const { mutate: swapOrder, isLoading: isSwapOrderLoading } = useSwapOrder();

  if (isPending) {
    return (
      <Box
        sx={{
          width: "50%",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <HashLoader color={COLORS.PRIMARY.MAIN} />
      </Box>
    );
  }

  return (
    <div>
      <div className={courseStyle.addButton}>
        <AddModule price={courseModules?.price} />
      </div>

      {courseModules?.modules?.length === 0 ? (
        <Typography
          sx={{
            height: 300,
            alignItems: "center",
            display: "flex",
            justifyContent: "center",
          }}
          fontFamily={lang === 'ar' ? "Noor-bold" : FONTS.ENGLISH}
          textAlign={"center"}
          fontSize={30}
          color={COLORS.DARK_GRAY}
        >
          {lang == "ar" ? "  لم يتم إضافة حصص بعد" : "No classes added yet."}
        </Typography>
      ) : (
        <div className={courseStyle.cardsContainer}>
          {courseModules?.modules.map((module, i) => (
            <div
              key={module?.id}
              className={courseStyle.card}
            >
              <div className={courseStyle.orderArrows}>
                {i !== 0 ? (
                  <button
                    disabled={isSwapOrderLoading}
                    style={{ color: isSwapOrderLoading ? COLORS.LIGHT_GRAY : COLORS.SECONDARY.MAIN }}
                    onClick={() =>
                      swapOrder({
                        first_lesson_module_id:
                          courseModules?.modules[i - 1]?.id,
                        second_lesson_module_id: module?.id,
                      })
                    }
                  >
                    <ArrowDropUpIcon />
                  </button>
                ) : null}

                {i !== courseModules?.modules?.length - 1 ? (
                  <button
                    disabled={isSwapOrderLoading}
                    style={{ color: isSwapOrderLoading ? COLORS.LIGHT_GRAY : COLORS.SECONDARY.MAIN }}
                    onClick={() =>
                      swapOrder({
                        first_lesson_module_id: module?.id,
                        second_lesson_module_id:
                          courseModules?.modules[i + 1]?.id,
                      })
                    }
                  >
                    <ArrowDropDownIcon />
                  </button>
                ) : null}
              </div>
              <ModuleDetailsCollapse
                course={course}
                module={module}
                index={i}
              />
            </div>
          ))}
        </div>
      )}
    </div>
  );
};
