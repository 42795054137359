
import { useState } from 'react';
import { useRef } from 'react';
import pageStyle from "./Tabs.module.css"
import { COLORS } from '@/constants/Colors/colors';
import { FONTS } from '@/constants/Fonts/fonts';

const Tabs = ({ pages, isDarkMode, color, lang, isInCoursePage, InsideCourse }) => {
    const [activeTab, setActiveTab] = useState(0);
    const containerRef = useRef(null);

    const handleTabClick = (index) => {
        setActiveTab(index);
        containerRef.current.scrollTo({ left: index * containerRef.current.clientWidth, behavior: 'smooth' });
    };

    return (
        <div className={pageStyle.tabs} style={{backgroundColor: isDarkMode ? "#121212" : "", direction: 'ltr', fontFamily: lang === 'en' ? FONTS.ENGLISH : ""}}>
            <div className={pageStyle.tabButtons}>
                {pages.map((page, index) => (
                    <button
                        key={index}
                        className={activeTab === index ? pageStyle.active : ''}
                        onClick={() => handleTabClick(index)}
                        style={{ backgroundColor: activeTab === index ? (isDarkMode ? COLORS.DARK_GRAY : COLORS.WHITE) : "",
                            color: activeTab === index ? color: ""
                         }}
                    >
                        {page.title}
                    </button>
                ))}
            </div>

            <div className={pageStyle.tabContentContainer} ref={containerRef}>
                <div className={pageStyle.tabContent} style={{ width: `${pages.length * 100}vw` }}>
                    {pages.map((page, index) => (
                        <div key={index} className={`${pageStyle.page} ${lang==='ar' ? isInCoursePage ? pageStyle.pageCourse :"" : ""}`} style={{ width: '100vw', backgroundColor: isDarkMode ? COLORS.DARK_GRAY : COLORS.WHITE, direction: lang==='ar' ? 'rtl' : 'ltr'}}>
                            {page.page}
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
};

export default Tabs;