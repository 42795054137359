import { Box, Button, Typography } from "@mui/material";
import { Close } from "@mui/icons-material";
import { HashLoader } from "react-spinners";
import { useForm } from "react-hook-form";
import { notifyError, notifySuccess } from "@shared/Toast/toast";
import { COLORS } from "@/constants/Colors/colors";
import { useMutate } from "@/hooks/useMutate";
import PropTypes from "prop-types";
import SelectBox from "@/components/SelectBox";
import InstagramIcon from "@mui/icons-material/Instagram";
import FacebookIcon from "@mui/icons-material/Facebook";
import XIcon from "@mui/icons-material/X";
import YouTubeIcon from "@mui/icons-material/YouTube";
import { useSelector } from "react-redux";
import TelegramIcon from "@mui/icons-material/Telegram";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import AndroidSharpIcon from "@mui/icons-material/AndroidSharp";
import IconTiktok from "@shared/Icons/TiktokIcon";
import IconApple from "@shared/Icons/AppleIcon";
import IconHuawei from "@shared/Icons/HuaweiIcon";
import { FONTS } from "@/constants/Fonts/fonts";

export default function CreateLink({ openModal, handleClose, refetch }) {
  const lang = useSelector((state) => state?.global?.lang);
  // form validation
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    defaultValues: {
      type: "",
      link: "",
    },
  });

  const { mutateAsync, isPending } = useMutate();
  const globalState = useSelector((state) => state?.global);

  const onSubmit = (data) => {
    console.log("Data", data);
    mutateAsync({
      url: `socials/create`,
      method: "POST",
      body: data,
    })
      .then(() => {
        notifySuccess(lang == "ar" ? "تم الاضافة بنجاح" : "Added successfully");
        refetch();
        handleClose();
        reset();
      })
      .catch(() => {
        notifyError(
          lang == "ar"
            ? "حدث خطأ اثناء تنفيذ المهمة"
            : "An error occurred while executing the task"
        );
      });
  };

  return (
    <div
      className=""
      style={{
        position: "fixed",
        top: "0",
        left: "0",
        background: "#000000ab",
        width: "100%",
        height: "100vh",
        zIndex: "1200",
        display: "flex",
        padding: "80px 0px",
        alignItems: "center",
        justifyContent: "center",
        flexDirection: "column",
        transition: "0.3s",
        scrollbarColor: COLORS.PRIMARY.MAIN,
        scrollbarWidth: "thin",
        overflow: "hidden",
        opacity: openModal ? 1 : 0,
        visibility: openModal ? "visible" : "hidden",
      }}
    >
      <Box
        className="device-box"
        px={3}
        sx={{
          padding: "20px 30px",
          background: "#F5F5F5",
          borderRadius: "10px",
          width: "70%",
          height: "auto",
          // overflowY: 'auto',
          display: "flex",
          flexDirection: "column",
          transition: "0.3s",
          opacity: openModal ? 1 : 0,
          transform: openModal ? "scale(1)" : "scale(0)",
          backgroundColor: globalState?.isDarkMode
            ? COLORS.DARK_GRAY
            : COLORS.WHITE,
        }}
      >
        <div
          className="close-btn"
          style={{
            position: "absolute",
            top: 5,
            right: 5,
            zIndex: 100,
          }}
        >
          <Close
            onClick={() => {
              handleClose();
              refetch();
              reset();
            }}
            sx={{
              color: "white",
              padding: "8px 10px",
              width: "40px",
              height: "40px",
              borderRadius: "5px",
              transition: "0.4s",
              bgcolor: "primary.main",
              cursor: "pointer",
              "&:hover": {
                bgcolor: "white",
                color: COLORS.PRIMARY.MAIN,
              },
            }}
          />
        </div>

        <Typography
          fontFamily={lang === 'ar' ? "Noor-bold" : FONTS.ENGLISH}
          mb={6}
          mt={4}
          fontSize={32}
          sx={{
            fontWeight: 'bold',
            textAlign: "center",
            color: globalState?.isDarkMode ? COLORS.WHITE : COLORS.BLACK,
          }}
        >
          {lang == "ar" ? "انشاء رابط جديد" : "Create a new link"}
        </Typography>

        <form onSubmit={handleSubmit(onSubmit)} encType="multipart/form-data">
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              gap: 2,
            }}
          >
            <Box
              sx={{
                width: "100%",
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-evenly",
                alignItems: "flex-end",
              }}
            >
              <SelectBox
                label={lang == "ar" ? "نوع الرابط" : "Link type"}
                options={[
                  {
                    label: <InstagramIcon />,
                    value: "instagram",
                  },
                  {
                    label: <FacebookIcon />,
                    value: "facebook",
                  },
                  {
                    label: <XIcon />,
                    value: "twitter",
                  },
                  {
                    label: <YouTubeIcon />,
                    value: "youtube",
                  },
                  {
                    label: <TelegramIcon />,
                    value: "telegram",
                  },
                  {
                    label: <WhatsAppIcon />,
                    value: "whatsapp",
                  },
                  {
                    label: <IconTiktok />,
                    value: "tiktok",
                  },
                  {
                    label: <AndroidSharpIcon />,
                    value: "android",
                  },
                  {
                    label: <IconApple />,
                    value: "iphone",
                  },
                  {
                    label: <IconHuawei />,
                    value: "huawei",
                  },
                ]}
                {...register("type", { required: true })}
              />
              {/* <Box
                sx={{
                  position: "relative",
                  width: "47%",
                }}
              >
                <span
                  style={{
                    position: "absolute",
                    top: "-24px",
                    right: "40px",
                    fontSize: "15px",
                    color: COLORS.PRIMARY.MAIN,
                    background: "#F5F5F5",
                    padding: "10px 20px",
                  }}
                >
                  نوع الرابط
                </span>

                <select
                  name="user_id"
                  id="user_id"
                  placeholder="اختر نوع الرابط"
                  style={{
                    background: "#F5F5F5",
                    border: `1px solid ${COLORS.PRIMARY.MAIN}`,
                    padding: "20px 15px",
                    borderRadius: "5px",
                    outline: "none",
                    fontSize: "16px",
                    width: "100%",
                    color: "black",
                    transition: "0.4s",
                  }}
                  {...register("user_id", {
                    required: `يجب اختيار نوع الرابط`,
                  })}
                >
                  <option value="" disabled selected>
                    اختر نوع الرابط
                  </option>
                  <option value={"انستجرام"}>انستجرام</option>
                  <option value={"فيس بوك"}>فيس بوك</option>
                  <option value={"يوتيوب"}>يوتيوب</option>
                  <option value={"واتساب"}>واتساب</option>
                  <option value={"تويتر"}>تويتر</option>
                </select>
                {errors.stage ? (
                  <Typography
                    fontSize={15}
                    fontFamily={"Noor-bold"}
                    color={"red"}
                    mt={1}
                    mr={1}
                  >
                    {errors.stage?.message}
                  </Typography>
                ) : (
                  ""
                )}
              </Box> */}
              <Box
                sx={{
                  width: "80%",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  position: "relative",
                }}
              >
                <span
                  style={{
                    position: "absolute",
                    top: "-15px",
                    right: lang == "ar" ? "40px" : "unset",
                    left: lang == "ar" ? "unset" : "40px",
                    fontSize: "15px",
                    color: globalState?.isDarkMode
                      ? COLORS.WHITE
                      : COLORS.PRIMARY.MAIN,
                    background: globalState?.isDarkMode
                      ? COLORS.DARK_GRAY
                      : "#F5F5F5",
                    padding: "0px 20px",
                  }}
                >
                  {lang == "ar" ? "اكتب الرابط" : "Type the link"}
                </span>
                <input
                  type="text"
                  placeholder={lang == "ar" ? "اكتب الرابط" : "Type the link"}
                  style={{
                    background: globalState?.isDarkMode
                      ? COLORS.DARK_GRAY
                      : "#F5F5F5",
                    border: `1px solid ${COLORS.PRIMARY.MAIN}`,
                    padding: "20px 15px",
                    borderRadius: "5px",
                    outline: "none",
                    fontSize: "16px",
                    width: "100%",
                    color: globalState?.isDarkMode ? COLORS.WHITE : "black",
                    transition: "0.4s",
                  }}
                  {...register("link", {
                    required:
                      lang == "ar"
                        ? `يجب ادخال اكتب الرابط`
                        : "This field is required",
                  })}
                />
                {errors.link ? (
                  <Typography
                    fontSize={15}
                    fontFamily={"Noor-bold"}
                    color={"red"}
                    mt={1}
                    mr={1}
                  >
                    {errors.link?.message}
                  </Typography>
                ) : (
                  ""
                )}
              </Box>
            </Box>
          </Box>

          <Box
            mt={5}
            sx={{
              display: "flex",
              justifyContent: "end",
            }}
          >
            <Button
              type="submit"
              onClick={""}
              sx={{
                background: COLORS.PRIMARY.MAIN,
                width: "250px",
                padding: "10px 25px",
                color: "white",
                cursor: "pointer",
                transition: "0.4s",
                "&:hover": {
                  background: "white",
                  color: COLORS.PRIMARY.MAIN,
                },
              }}
            >
              {isPending ? (
                <HashLoader color="#ffffff" size={24} />
              ) : lang == "ar" ? (
                "انشاء"
              ) : (
                "Create"
              )}
              {/* <HashLoader color="#ffffff" size={24} /> */}
            </Button>
          </Box>
        </form>
      </Box>
    </div>
  );
}

CreateLink.propTypes = {
  openModal: PropTypes.bool,
  handleClose: PropTypes.func,
  refetch: PropTypes.func,
};
