import { useQuery } from "@tanstack/react-query";
import axios from "axios";

import useLocalStorageToken from "../Admin/Shared/Helpers/axios/useLocalStorageToken";

import { useSelector } from 'react-redux';
import { api_url } from "@/constants/base_url";

export const useGetData = (url, qryKey = "repoData") => {
 const lang = useSelector((state) => state?.global?.lang);
  const token = useLocalStorageToken();
  const query = useQuery({
    queryKey: [qryKey],
    queryFn: async () => {
      try {
        const response = await axios.get(api_url + url, {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token?.token}`,
            lang,
          },
        });
        return response.data;
      } catch (error) {
        console.log(error);
      }
    },
  });

  return { ...query };
};
