import { Box, Button, ListItem, Typography } from "@mui/material";
import React from "react";
import { useState, useEffect } from "react";
import { CourseHomeWorks } from "./atoms/CourseHomeWorks";
import { COLORS } from "@/constants/Colors/colors";
import { useSelector } from "react-redux";
import homeworkStyle from "./CourseHomeWorkList.module.css"

export const CourseHomeWorkList = ({ homework }) => {
  const globalState = useSelector((state) => state?.global);
  const lang = globalState?.lang;
  const isDarkMode = globalState?.isDarkMode;

  const [countdown, setCountdown] = useState(null);
  const [timer, setTimer] = useState({ days: 0, hours: 0, mins: 0, secs: 0 });

  const [disabled, setDisabled] = useState(true);
  const [started, setStarted] = useState(false);
  const [diff, setDiff] = useState(null);
  const startDate = new Date(homework?.data?.starts_at);
  const currentDate = Date.now();
  if (diff == null && startDate - currentDate) {
    setDiff(startDate - currentDate);
    setCountdown(startDate - currentDate);
  }
  //homework?.data?.data?.starts_at
  useEffect(() => {
    let timer;

    if (diff >= 0) {
      timer = setInterval(() => {
        if (diff >= 0) {
          setDiff((d) => d - 1000);
          setCountdown((c) => c - 1);
        }
      }, 1000);
    } else {
      setDisabled(false);
    }
    formatCountdown();
    return () => clearInterval(timer);
  }, [diff]);

  const formatCountdown = () => {
    const seconds = Math.floor((diff / 1000) % 60);
    const minutes = Math.floor((diff / 1000 / 60) % 60);
    const hours = Math.floor((diff / (1000 * 60 * 60)) % 24);
    const days = Math.floor(diff / (1000 * 60 * 60 * 24));

    // return `${days}d ${hours}h ${minutes}m ${seconds}s`;
    setTimer({ days: days, hours: hours, mins: minutes, secs: seconds });
  };

  if (started) {
    return <CourseHomeWorks homeWork={homework} setStarted={setStarted} />;
  }

  const content = (
    <div className={homeworkStyle.homeworkContainer}>
      <div className={homeworkStyle.homeworkHeader}>
        <p className={homeworkStyle.homeworkTitle} title={homework.data.title}>
          {
            homework?.data?.title
              ? homework.data.title.length > 30
                ? `${homework.data.title.slice(0, 30)}...`
                : homework.data.title
              : ""
          }
        </p>
      </div>

      <div className={homeworkStyle.buttonContainer}>
        {disabled ? (
          <div className={homeworkStyle.countdownContainer}>
            {/* {formatCountdown()} */}
            <div><span>{timer.days}</span>{lang === 'ar' ? "يوم" : "days"}</div>
            <div><span>{timer.hours}</span>{lang === 'ar' ? "ساعة" : "hours"}</div>
            <div><span>{timer.mins}</span>{lang === 'ar' ? "دقيقة" : "minutes"}</div>
            <div><span>{timer.secs}</span>{lang === 'ar' ? "ثانية" : "seconds"}</div>
          </div>
        ) :
          (!homework?.data?.homeWorkAnswer ||
            homework?.data?.homeWorkAnswer.grade === null) ? (
            <button
              onClick={() => {
                setStarted(true);
              }}
              disabled={disabled}
              style={{ color: isDarkMode ? COLORS.BLACK : COLORS.WHITE }}
            >
              {lang === 'ar' ? "ابدأ الآن" : "Start Now"}
            </button>) :
            <div className={homeworkStyle.totalGrade} style={{ color: isDarkMode ? COLORS.BLACK : COLORS.WHITE }}>
              <p>
                {homework?.data?.homeWorkAnswer &&
                  homework?.data?.homeWorkAnswer.grade != undefined
                  ? homework?.data?.homeWorkAnswer?.grade
                  : "-"}
              </p>
              <span>/</span>
              <p>
                {homework?.data?.grade}
              </p>
            </div>
        }
      </div>
    </div>)

  return (
    <>
      {started ? (
        <CourseHomeWorks homeWork={homework} setStarted={setStarted} />
      ) : (
        content
      )}
    </>
  );
};
