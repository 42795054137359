import React from 'react'
import { useState } from 'react';
import pageStyle from "./UserBooksLayout.module.css"
import { useSelector } from 'react-redux';
import { UserBookStore } from '../UserBookStore/UserBookStore';
import { UserBookOrders } from '../UserBookOrders/UserBookOrders';
import { useRef } from 'react';
import { COLORS } from '@/constants/Colors/colors';
import { FONTS } from '@/constants/Fonts/fonts';
import Tabs from '../../../components/TabbedPages/Tabs';

const UserBooksLayout = () => {
    const globalState = useSelector((state) => state?.global);
    const lang = globalState?.lang;
    const isDarkMode = globalState?.isDarkMode;

    const pages = [
        {
            page: <UserBookStore />,
            title: lang === 'ar' ? "الكتب" : "Books"
        },
        {
            page: <UserBookOrders />,
            title: lang === 'ar' ? "طلباتي" : "My Orders"
        }
    ];
    return (
        <div style={{ direction: 'ltr', fontFamily: lang === 'en' ? FONTS.ENGLISH : "" }}>
            <Tabs isDarkMode={isDarkMode} color={COLORS.SECONDARY.MAIN} pages={pages} />
        </div>
    )
}

export default UserBooksLayout
