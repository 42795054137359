import { useRecoilState } from "recoil";
import { ThemeProvider } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import QRCode from "react-qr-code";
import dayjs from "dayjs";
import { createTheme } from "@mui/material";
import ColorButton from "@shared/Buttons/ColorButton/ColorButton";
import { printCodesAtom } from "../services/codeAtoms/printCodeAtom";
import { COLORS } from "@/constants/Colors/colors";

export default function PrintCodes() {
  const [printCodes] = useRecoilState(printCodesAtom);
  return (
    <>
      <ColorButton
        sx={{
          transition: "all 0.3s",
          mr: 4,
          my: 3,
          px: 3,
          py: 1.5,
          fontSize: 16,
          "@media print": { display: "none" },
          width: "auto",
        }}
        variant="contained"
        onClick={() => {
          document.title = `${printCodes.label} - ${dayjs(new Date()).format(
            "YYYY/MM/DD"
          )}`;
          window.print();
          document.title = "Demo";
        }}
      >
        {" "}
        طباعة
      </ColorButton>
      <ThemeProvider theme={theme}>
        <TableContainer
          sx={{
            maxWidth: "70%",
            mx: "auto",
            mt: -9,
          }}
        >
          <Table
            sx={{
              direction: "rtl",
              backgroundColor: "transparant",
            }}
            aria-label="simple table"
          >
            <TableHead sx={{ bgcolor: COLORS.PRIMARY.MAIN }}>
              <TableRow>
                <TableCell align="center"></TableCell>
                <TableCell align="center">الكود</TableCell>
                <TableCell align="center">QR</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {printCodes?.length > 0
                ? printCodes?.map((codeData, index) => (
                    <TableRow
                      key={index}
                      sx={{
                        "&:last-child td, &:last-child th": { border: 0 },
                        backgroundColor:
                          index % 2 === 1 ? "#fafafa" : "inherit",
                      }}
                    >
                      <TableCell align="center">{index}</TableCell>
                      <TableCell align="center">{codeData}</TableCell>
                      <TableCell
                        component="th"
                        scope="row"
                        sx={{ textAlign: "center" }}
                      >
                        <QRCode value={codeData} size={80} />
                      </TableCell>
                    </TableRow>
                  ))
                : null}
            </TableBody>
          </Table>
        </TableContainer>
      </ThemeProvider>
    </>
  );
}

const theme = createTheme({
  components: {
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          "& .MuiOutlinedInput-notchedOutline": {
            border: `1px solid ${COLORS.PRIMARY.MAIN}`, // Change the border color
          },
          "&:hover .MuiOutlinedInput-notchedOutline": {
            border: `1px solid ${COLORS.PRIMARY.MAIN}`, // Change the border color on hover
          },
          "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
            border: `2px solid ${COLORS.PRIMARY.MAIN}`, // Change the border color when focuu
          },
        },
      },
    },
  },
});
