import { Close } from "@mui/icons-material";
import { Box, Button, Typography } from "@mui/material";
import { useMutation } from "@tanstack/react-query";
import HashLoader from "react-spinners/HashLoader";
import axios from "axios";
import { currentUser } from "@shared/Helpers/encryptionHelpers";
import { api_url } from "@/constants/base_url";
import { notifyError, notifySuccess } from "@shared/Toast/toast";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import { COLORS } from "@/constants/Colors/colors";
import { FONTS } from "@/constants/Fonts/fonts";

function UnassignCourse({
  closeSubNote,
  noteSubscribe,
  refetch,
  subscribtionId,
  supervisorID,
}) {
  const lang = useSelector((state) => state?.global?.lang);
  const { mutate, isLoading } = useMutation({
    mutationFn: (formData) =>
      axios.post(`${api_url}supervisors/removeCourse`, formData, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${currentUser()?.token}`,
          lang: lang,
        },
      }),
    retry: false,
    onSuccess: () => {
      notifySuccess(
        lang == "ar"
          ? "تم إزالة المشرف من الكورس"
          : "The moderator has been removed from the course"
      );
      refetch();
      closeSubNote();
    },
    onError: (error) => {
      console.log(error);
      notifyError(
        lang == "ar"
          ? "حدث خطأ اثناء تنفيذ المهمة"
          : "An error occurred while executing the task"
      );
    },
  });

  const handleDelete = () => {
    const formData = {
      supervisor_id: supervisorID,
      course_id: subscribtionId,
    };
    mutate(formData);
  };

  return (
    <>
      <Box
        sx={{
          position: "fixed",
          top: "0",
          left: "0",
          width: "100%",
          height: "100%",
          bgcolor: "#000000ab",
          zIndex: "1501",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          transition: "0.3s",
          opacity: noteSubscribe ? "1" : "0",
          visibility: noteSubscribe ? "visible" : "hidden",
        }}
      >
        <Box
          sx={{
            bgcolor: "white",
            padding: "40px 30px",
            borderRadius: "10px",
            width: "450px",
            height: "auto",
            textAlign: "center",
            position: "relative",
            transition: "0.3s",
            opacity: noteSubscribe ? "1" : "0",
            transform: noteSubscribe ? "scale(1)" : "scale(0)",
          }}
        >
          <div
            className="close-btn"
            style={{
              position: "absolute",
              top: 10,
              right: 10,
              zIndex: 100,
            }}
          >
            <Close
              onClick={closeSubNote}
              sx={{
                color: "black",
                width: 30,
                height: 30,
                transition: "0.4s",
                cursor: "pointer",
                "&:hover": {
                  color: COLORS.PRIMARY.MAIN,
                },
              }}
            />
          </div>

          <Typography
            fontFamily={lang === 'ar' ? "Noor-bold" : FONTS.ENGLISH}
            fontSize={30}
            sx={{ fontWeight: 'bold' }}
          >
            {lang == "ar"
              ? "هل تريد إزالة المشرف من الكورس"
              : "Do you want to remove the admin from the course?"}
          </Typography>

          <Box
            mt={5}
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alidngItems: "center",
            }}
          >
            <Button
              onClick={handleDelete}
              sx={{
                padding: "7px 20px",
                width: "49%",
                bgcolor: "darkred",
                color: "white",
                trnsition: "0.4s",
                "&:hover": {
                  bgcolor: "red",
                },
              }}
            >
              {isLoading ? (
                <HashLoader color="#ffffff" size={24} />
              ) : lang == "ar" ? (
                "أوافق"
              ) : (
                "Accept"
              )}
            </Button>
            <Button
              onClick={closeSubNote}
              sx={{
                padding: "7px 20px",
                width: "49%",
                bgcolor: "gray",
                color: "white",
                trnsition: "0.4s",
                "&:hover": {
                  bgcolor: "lightgray",
                },
              }}
            >
              {lang == "ar" ? "الغاء" : "cancel"}
            </Button>
          </Box>
        </Box>
      </Box>
    </>
  );
}

UnassignCourse.propTypes = {
  noteSubscribe: PropTypes.bool,
  closeSubNote: PropTypes.func,
  refetch: PropTypes.func,
  subscribtionId: PropTypes.number,
  supervisorID: PropTypes.number,
};

export default UnassignCourse;
