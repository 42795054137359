// import { COLORS } from "@/constants/Colors/colors";
// import { Box, Container, Grid } from "@mui/material";
// import useCustomQuery from "@/hooks/useCustomQuery";
// import React, { useEffect } from "react";
// import { HashLoader } from "react-spinners";
// import DisplayAllHeader from "../atoms/DisplayAllHeader";
// import NoItemsText from "@shared/NoItemText/NoItemText";
// import CourseCard from "@/modules/home/components/atoms/CourseCard";
// import { api_url } from "@/constants/base_url";
// import { Timer } from "@shared/Timer/Timer";
// import { useUserSubsContext } from "../../Context/UserSubsContext";
// import { useAllCoursesContext } from "../../Context/AllCoursesContext";
// import { useSelector } from "react-redux";
// export const CoursesForYou = () => {
//   // const {data, isPending} = useCustomQuery({
//   //     key:["courses",page=1],
//   //     url:"course/all",
//   //     select:(data) => data
//   // })
//   const globalState = useSelector((state) => state?.global);
//   const lang = globalState?.lang;
//   const isDarkMode = globalState?.isDarkMode;

//   const { data, isPending, setPage, setQuery } = useAllCoursesContext();
//   const {
//     data: mySubs,
//     isPending: isMySubsPending,
//     ids,
//   } = useUserSubsContext();

//   useEffect(() => {
//     setPage(1);
//     setQuery("");
//   });
//   if (isPending) {
//     return (
//       <Box
//         sx={{
//           width: "100%",
//           display: "flex",
//           alignItems: "center",
//           justifyContent: "center",
//           paddingY: 5,
//         }}
//       >
//         <HashLoader color={COLORS.PRIMARY.MAIN} />
//       </Box>
//     );
//   }
//   return (
//     <>
//       <Box py={10} sx={{ direction: lang == "ar" ? "rtl" : "ltr" }}>
//         <DisplayAllHeader
//           text={lang == "ar" ? "الكورسات" : "Courses"}
//           link={"/all-courses"}
//         />
//         {data?.data?.data?.length > 0 ? (
//           <Container maxWidth="100%">
//             <Grid container spacing={3} justifyContent="center">
//               {data?.data?.data?.slice(0, 4).map((item) => (
//                 <Grid item xs={12} sm={6} md={4} lg={3} key={item.id}>
//                   <Box sx={{ position: "relative", width: "100%" }}>
//                     <CourseCard
//                       width={"100%"}
//                       img={`${api_url.slice(0, -7)}${item.image.slice(1)}`}
//                       name={item.name}
//                       price={item.price}
//                       description={item.description}
//                       type={
//                         item.isPayByModule
//                           ? lang == "ar"
//                             ? "بالحصة"
//                             : "Lesson"
//                           : lang == "ar"
//                           ? "بالكورس"
//                           : "Course"
//                       }
//                       teacher={item.teacher}
//                       link={`/course/${item.id}`}
//                       id={item.id}
//                       bought={ids?.includes(+item.id)}
//                     />
//                     {ids?.includes(+item.id) && (
//                       <Timer
//                         time={
//                           mySubs?.data?.data?.data?.find(
//                             (x) => x.course_id == +item.id
//                           )?.expires_at
//                         }
//                         sx={{ position: "absolute", top: 0, left: 0 }}
//                       />
//                     )}
//                   </Box>
//                 </Grid>
//               ))}
//             </Grid>
//           </Container>
//         ) : (
//           <Box
//             sx={{
//               width: "100%",
//               display: "flex",
//               alignItems: "center",
//               justifyContent: "center",
//               paddingY: 5,
//             }}
//           >
//             <NoItemsText
//               text={
//                 lang == "ar"
//                   ? "لا توجد كورسات حتي الآن"
//                   : "There is no courses yet"
//               }
//             />
//           </Box>
//         )}
//       </Box>
//     </>
//   );
// };




import { COLORS } from "@/constants/Colors/colors";
import { Box, Container, Grid } from "@mui/material";
import useCustomQuery from "@/hooks/useCustomQuery";
import React, { useEffect } from "react";
import { HashLoader } from "react-spinners";
import DisplayAllHeader from "../atoms/DisplayAllHeader";
import NoItemsText from "@shared/NoItemText/NoItemText";
import CourseCard from "@/modules/home/components/atoms/CouresCard/CourseCard";
import { api_url } from "@/constants/base_url";
import { Timer } from "@shared/Timer/Timer";
import { useUserSubsContext } from "../../Context/UserSubsContext";
import { useAllCoursesContext } from "../../Context/AllCoursesContext";
import { useSelector } from "react-redux";
import style from "./CoursesForYou.module.css"

export const CoursesForYou = () => {
  // const {data, isPending} = useCustomQuery({
  //     key:["courses",page=1],
  //     url:"course/all",
  //     select:(data) => data
  // })
  const globalState = useSelector((state) => state?.global);
  const lang = globalState?.lang;
  const isDarkMode = globalState?.isDarkMode;

  const { data, isPending, setPage, setQuery } = useAllCoursesContext();
  const {
    data: mySubs,
    isPending: isMySubsPending,
    ids,
  } = useUserSubsContext();

  useEffect(() => {
    setPage(1);
    setQuery("");
  });
  if (isPending) {
    return (
      <Box
        sx={{
          width: "100%",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          paddingY: 5,
        }}
      >
        <HashLoader color={COLORS.PRIMARY.MAIN} />
      </Box>
    );
  }
  return (
    <>
      <div id="landingCourses">
        <Box py={10} sx={{ direction: lang == "ar" ? "rtl" : "ltr", backgroundColor: isDarkMode ? COLORS.BLACK : "" }}>
          <DisplayAllHeader
            text={lang == "ar" ? "الكورسات" : "Courses"}
            link={"/all-courses"}
            color={COLORS.SECONDARY.MAIN}
          />

          {data?.data?.data?.length > 0 ? (
            <div className={`${style.cardsContainer} cardsContainer`}>
              {data?.data?.data?.slice(0, 3).map((item) => (
                <div key={item.id}>
                  <CourseCard
                    color={COLORS.SECONDARY.MAIN}
                    width={"100%"}
                    img={`${api_url.slice(0, -7)}${item.image.slice(1)}`}
                    name={item.name}
                    price={item.price}
                    description={item.description}
                    type={
                      item.isPayByModule
                        ? lang == "ar"
                          ? "بالحصة"
                          : "Lesson"
                        : lang == "ar"
                          ? "بالكورس"
                          : "Course"
                    }
                    teacher={item.teacher}
                    link={`/course/${item.id}`}
                    id={item.id}
                    bought={ids?.includes(+item.id)}
                    time={
                      mySubs?.data?.data?.data?.find(
                        (x) => x.course_id == +item.id
                      )?.expires_at
                    }
                  />
                </div>
              ))}
            </div>
          ) : (
            <Box
              sx={{
                width: "100%",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                paddingY: 5,
              }}
            >
              <NoItemsText
                text={
                  lang == "ar"
                    ? "لا توجد كورسات حتي الآن"
                    : "There is no courses yet"
                }
              />
            </Box>
          )}
        </Box>
      </div>
    </>
  );
};

