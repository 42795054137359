import { COLORS } from "@/constants/Colors/colors";
import {
  Box,
  Button,
  IconButton,
  InputLabel,
  OutlinedInput,
  TextField,
  Typography,
} from "@mui/material";

import InputAdornment from "@mui/material/InputAdornment";
import React, { useEffect, useRef, useState } from "react";
import { notifySuccess, notifyError } from "@shared/Toast/toast";
import axios from "axios";
import { currentUser } from "@shared/Helpers/encryptionHelpers";
import { api_url } from "@/constants/base_url";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { useForm } from "react-hook-form";
import Modal from "@shared/Modal/Modal";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import { FONTS } from "@/constants/Fonts/fonts";
import Form from "@shared/Form/Form";

export default function AddSupervisor({ modal, closeModal, refetch }) {
  const lang = useSelector((state) => state?.global?.lang);
  const [isLoading, setIsLoading] = useState(false);
  const [showPassword, setShowPassword] = React.useState(false);
  const globalState = useSelector((state) => state?.global);

  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const timerRef = useRef();
  const {
    register,
    handleSubmit,
    formState,
    control,
    setError,
    clearErrors,
    reset,
    getValues,
  } = useForm({
    defaultValues: {
      name: "",
      phone: "",
      password: "",
      rePassword: "",
    },
  });

  useEffect(() => {
    const timer = timerRef.current;

    return () => {
      clearTimeout(timer);
    };
  }, []);

  const onSubmit = async () => {
    try {
      setIsLoading(true);

      const { name, phone, password, rePassword } = getValues();

      // if (name.length <= 4) {
      //   setError("name", {
      //     type: "manual",
      //     message:
      //       lang == "ar"
      //         ? "الحروف يجب أن تكون أكثر من 4"
      //         : "Letters must be more than 4",
      //   });
      //   return;
      // } else {
      //   clearErrors("name");
      // }

      // if (!phone.startsWith("01")) {
      //   setError("phone", {
      //     type: "manual",
      //     message:
      //       lang == "ar" ? "رقم الهاتف غير صحيح" : "Phone number is incorrect",
      //   });
      //   return;
      // } else {
      //   clearErrors("phone");
      // }

      // if (password.length <= 8 || password !== rePassword) {
      //   setError("password", {
      //     type: "manual",
      //     message:
      //       lang == "ar"
      //         ? "كلمة المرور غير صحيحة أو يجب أن تكون أكثر من 8 أحرف"
      //         : "The password is incorrect or must be more than 8 characters",
      //   });
      //   setError("rePassword", {
      //     type: "manual",
      //     message:
      //       lang == "ar"
      //         ? "كلمة المرور غير صحيحة أو يجب أن تكون أكثر من 8 أحرف"
      //         : "The password is incorrect or must be more than 8 characters",
      //   });
      //   return;
      // } else {
      //   clearErrors("password");
      //   clearErrors("rePassword");
      // }

      const formData = {
        name,
        phone,
        password,
        password_confirmation: rePassword,
      };

      const config = {
        headers: {
          Authorization: `Bearer ${currentUser()?.token}`,
          "Content-Type": "application/json",
          lang: lang,
        },
      };

      const response = await axios.post(
        `${api_url}supervisors/add`,
        formData,
        config
      );
      console.log(response);
      notifySuccess(lang == "ar" ? "تمت الاضافه بنجاح" : "Added successfully");
      closeModal();
      reset();
      refetch();
      setIsLoading(false);
    } catch (error) {
      console.error("Error sending notification:", error);
      notifyError(
        lang == "ar"
          ? "حدث خطا اثناء التنفيذ"
          : "An error occurred while executing the task"
      );
      setIsLoading(false);
    }
  };
  return (
    <Modal maxWidth={"sm"} open={modal} closeFn={closeModal}>
      <Form title={lang == "ar" ? "انشاء مشرف جديد" : "Create Supervisor"}
        onSubmit={handleSubmit(onSubmit)}
      >
        <Form.TextController
          placeholder={lang == "ar" ? "ادخل الاسم بالكامل " : "Enter full name"}
          register={register}
          registername={"name"}
          validationRules={{
            required: lang == "ar" ? "الاسم مطلوب" : "name is required",
            validate: value => {
              const wordCount = value.trim().split(/\s+/).length;
              const isValidWords = wordCount >= 3;

              if (!value) return lang === "ar" ? "الاسم مطلوب" : "Name is required";
              return isValidWords || (lang === "ar" ? "يجب إدخال الاسم بالكامل" : "You must enter the full name");
            }
          }}
          errorMessage={formState.errors.name?.message}
        />

        <Form.TextController
          placeholder={lang == "ar" ? "ادخل رقم الهاتف " : "Enter phone number"}
          register={register}
          registername={"phone"}
          validationRules={{
            required: lang == "ar" ? "رقم الهاتف مطلوب" : "Phone number is required",
            pattern: {
              value: /^(011|010|012|015)\d{8}$/,
              message:
                lang == "ar"
                  ? "الرقم غير صحيح"
                  : "Phone number is invalid",
            },
          }}
          errorMessage={formState.errors.phone?.message}
        />
        <div style={{
          display: 'flex',
          gap: '20px'
        }}>
          <Form.TextController
            type="password"
            placeholder={lang == "ar" ? "ادخل كلمة السر" : "Enter password"}
            register={register}
            registername={"password"}
            validationRules={{
              required: lang == "ar" ? "كلمة السر مطلوبة" : "Password is required",
              minLength: {
                value: 8,
                message: lang === "ar" ? "يجب أن تحتوي كلمة السر على 8 أحرف على الأقل" : "password must be at least 8 characters"
              },
            }}
            errorMessage={formState.errors.password?.message}
          />

          <Form.TextController
            type="password"
            placeholder={lang == "ar" ? "تأكيد كلمة السر" : "Confirm password"}
            register={register}
            registername={"rePassword"}
            validationRules={{
              required: lang == "ar" ? "تأكيد كلمة السر مطلوب" : "Confirm password is required",
              validate: value => {
                const password = getValues("password");
                return value === password || (lang === "ar" ? "كلمة السر غير متطابقة" : "Passwords do not match");
              }
            }}
            errorMessage={formState.errors.rePassword?.message}
          />
        </div>

        <Form.ButtonController
          type="submit"
          isPending={formState.isLoading}
        >
          {lang == "ar" ? "انشاء" : "Create"}
        </Form.ButtonController>

      </Form>
    </Modal >
  );
}
AddSupervisor.propTypes = {
  modal: PropTypes.bool,
  closeModal: PropTypes.func,
  refetch: PropTypes.func,
};
