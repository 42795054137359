import { useState, useEffect } from "react";
import { useQueryClient } from "@tanstack/react-query";
import { Box } from "@mui/material";
import { COLORS } from "@/constants/Colors/colors";
export default function CourseExamTimer({ remainingTime, setStarted }) {
  const [countdown, setCountDown] = useState(
    remainingTime.seconds +
      remainingTime.minutes * 60 +
      remainingTime.hours * 60 * 60 +
      remainingTime.days * 60 * 60 * 24 +
      remainingTime.months * 60 * 60 * 24 * 30 +
      remainingTime.years * 60 * 60 * 24 * 30 * 12
  );
  const queryClient = useQueryClient();
  useEffect(() => {
    let timer;
    if (countdown > 0) {
      timer = setInterval(() => {
        setCountDown(countdown - 1);
      }, 1000);
    } else {
      queryClient.invalidateQueries(["examData"]);
      setStarted(false);
    }
    return () => clearInterval(timer);
  }, [countdown]);
  function formatTime(seconds) {
    const days = Math.floor(seconds / (3600 * 24));
    const hours = Math.floor((seconds % (3600 * 24)) / 3600);
    const minutes = Math.floor((seconds % 3600) / 60);
    const remainingSeconds = seconds % 60;

    const formattedTime = `${days}d ${hours}h ${minutes}m ${remainingSeconds}s`;
    return formattedTime;
  }

  return (
    <>
      <Box
        sx={{
          bgcolor: COLORS.PRIMARY.MAIN,
          color: "white",
          fontSize: "28px",
          display: "inline-block",
          marginY: "20px",
          padding: "8px 20px",
          borderRadius: "5px",
        }}
      >
        {formatTime(countdown)}
      </Box>
    </>
  );
}
