import { FONTS } from "@/constants/Fonts/fonts";
import { initialSwapState } from "./utils";
import ColorButton from "@shared/Buttons/ColorButton/ColorButton";
import { RiFontFamily } from "react-icons/ri";
import { useSelector } from "react-redux";
import { useState } from "react";
import { COLORS } from "@/constants/Colors/colors";

export const ChangeOrderButton = ({ state, setState, isLoading }) => {
  const language = useSelector((state) => state?.global);
  const lang = language?.lang;
  return (
    <ColorButton
      variant="contained"
      sx={{
        color: "#fff",
        bgcolor: state.active ? COLORS.DANGER : "#2F8BC8",
        "&:hover": {
          color: "#fff",
          bgcolor: state.active ? COLORS.DANGER : "#2F8BC8",
        },
      }}
      disabled={isLoading}
      onClick={() => {
        if (!state.active) {
          setState((s) => {
            return { ...s, active: true };
            
          });
        } else {
          setState(initialSwapState);
        }
      }}
    >
      <p style={{ fontFamily: lang === 'en' ? FONTS.ENGLISH : "" }}>
        {state.active ? (lang==='ar' ? "إلغاء التغييرات" : "Cancel Changes") :(lang == "ar" ? "تغيير الترتيب" : "Change the order")}
      </p>
    </ColorButton>
  );
};
