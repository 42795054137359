import { COLORS } from "@/constants/Colors/colors";
import { Box, Typography } from "@mui/material";
import React from "react";
import { UserCourseFile } from "./UserCourseFile/UserCourseFile";
import { HashLoader } from "react-spinners";
import useCustomQuery from "@/hooks/useCustomQuery";
import { useSelector } from "react-redux";
import NoDataFound from '@/components/NoDataFound/NoDataFound';
import generalCourses from "../UserCourse.module.css"

export const UserCourseFiles = ({ id, bought }) => {
  const globalState = useSelector((state) => state?.global);
  const lang = globalState?.lang;
  const { data, isPending } = useCustomQuery({
    url: `drive?type=course&id=${id}`,
    key: ["courseFilesForStudent", id, lang],
    lang,
  });


  console.log(data)

  return (
    <Box sx={{ padding: 5, width: "100%" }}>
      {/* <UserCourseFile file={"hello"} bought={bought} /> */}

      {isPending ? (
        <Box width={"100%"} display={"flex"} justifyContent={"center"}>
          <HashLoader color={COLORS.PRIMARY.MAIN} />
        </Box>
      ) : data?.data?.length > 0 ? (

        data?.data?.map((item, key) => (
          <UserCourseFile file={item} key={key} bought={bought} />
        ))
        
      ) : (
        <div className={generalCourses.noDataImage} style={{ position: 'relative', width: 'calc(100vw - 450px)' }}>
          <NoDataFound title={lang == "ar" ? " لا يوجد ملفات" : "There are no Files"} />
        </div>
      )}
    </Box>
  );
};
