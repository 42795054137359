import { Box, Button, Grid, Skeleton, Stack, Typography } from "@mui/material";
import useCustomQuery from "@/hooks/useCustomQuery";
import { useState } from "react";
import AddNotifications from "./AddNotifications";
import { COLORS } from "@/constants/Colors/colors";
import { AccessTimeFilled, CalendarMonth } from "@mui/icons-material";
import moment from "moment";
import { useSelector } from "react-redux";
import { FONTS } from "@/constants/Fonts/fonts";

export default function NotificationsPage() {
  const lang = useSelector((state) => state?.global?.lang);
  const globalState = useSelector((state) => state?.global);
  const [page] = useState(1);
  const [openNotificationModal, setOpenNotificationModal] = useState(false);
  const { data, isLoading, refetch } = useCustomQuery({
    url: `getAllNotifications`,
    key: ["getAllNotifications", lang],
    page: page,
    lang,
  });

  return (
    <Box
      backgroundColor={
        globalState?.isDarkMode ? COLORS?.SOFT_DARK : COLORS?.WHITE
      }
      sx={{ height: "77vh" }}
    >
      <Grid item={true} xs={12} sm={12}>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            marginLeft: "20px",
            marginBottom: "20px",
            alignItems: "center",
          }}
        >
          <Box>
            <Stack spacing={2} direction="row">
              <Typography
                fontFamily={"Noor-bold"}
                fontSize={30}
                sx={{
                  color: globalState?.isDarkMode ? COLORS.WHITE : COLORS.BLACK,
                }}
              >
                {lang == "ar" ? "إشعارات سابقة" : "Previous notifications"}
              </Typography>
            </Stack>
          </Box>

          <Box>
            <Button
              onClick={() => setOpenNotificationModal(true)}
              sx={{
                background: COLORS.PRIMARY.MAIN,
                width: "200px",
                padding: "10px 25px",
                color: "white",
                cursor: "pointer",
                transition: "0.4s",
                "&:hover": {
                  background: "white",
                  color: COLORS.PRIMARY.MAIN,
                },
                position: "relative",
              }}
            >
              {lang == "ar" ? "اضف اشعار" : "Add notification"}
            </Button>
            <AddNotifications
              refetch={refetch}
              closeModalNotification={() => setOpenNotificationModal(false)}
              modalNotification={openNotificationModal}
            />
          </Box>
        </Box>

        <Box
          sx={{
            paddingY: "5px",
            paddingX: "10px",
            overflowY: "auto",
            height: "77vh",
          }}
        >
          {isLoading ? (
            <>
              {Array(3)
                .fill(0)
                .map((_, i) => (
                  <Skeleton
                    key={i}
                    height={200}
                    sx={{
                      maxWidth: "100% !important",
                      marginBottom: " -60px",
                      marginTop: `${i === 0 ? "-50px" : "0px"}`,
                    }}
                  />
                ))}
            </>
          ) : (
            <>
              {data?.data?.length > 0 || data.length >= 1 ? (
                <>
                  {data?.data?.map((notification, i) => (
                    <Box key={i} sx={{ marginBottom: "15px" }}>
                      <Box
                        sx={{
                          boxShadow: "none",
                          textAlign: "start",
                          color: "black",
                          background: "white",
                          padding: "10px",
                          borderRadius: "8px",
                        }}
                      >
                        <Box>
                          <Typography
                            color="black"
                            fontFamily={"Noor-bold"}
                            my={"10px"}
                          // backgroundColor={"red"}
                          >
                            {notification.title}
                          </Typography>
                          <Box
                            sx={{
                              display: "flex",
                              justifyContent: "center",
                            }}
                          >
                            <Typography color="black" my={"20px"}>
                              {notification.body}
                            </Typography>
                          </Box>

                          <Box
                            sx={{
                              display: "flex",
                              justifyContent: "end",
                              alignItems: "center",
                            }}
                          >
                            <Box
                              sx={{
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                              }}
                              mx={"10px"}
                            >
                              <AccessTimeFilled
                                sx={{ color: COLORS.PRIMARY.MAIN }}
                              />
                              <Typography
                                color="black"
                                fontSize={"15px"}
                                my={"10px"}
                                mx={"5px"}
                              >
                                {moment(notification.created_at).format(
                                  "h:mm:ss A"
                                )}
                              </Typography>
                            </Box>

                            <Box
                              sx={{
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                              }}
                              mx={"10px"}
                            >
                              <CalendarMonth
                                sx={{ color: COLORS.PRIMARY.MAIN }}
                              />
                              <Typography
                                color="black"
                                fontSize={"15px"}
                                my={"10px"}
                                mx={"5px"}
                              >
                                {moment(notification.created_at).format(
                                  "YYYY-MM-DD"
                                )}
                              </Typography>
                            </Box>
                          </Box>
                        </Box>
                      </Box>
                    </Box>
                  ))}
                </>
              ) : (
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    height: "100%",
                  }}
                >
                  <Typography
                    fontFamily={lang === 'ar' ? "Noor-bold" : FONTS.ENGLISH}
                    mr={2}
                    fontSize={25}
                    sx={{
                      fontWeight: 'bold',
                      color: globalState?.isDarkMode
                        ? COLORS.WHITE
                        : COLORS.BLACK,
                    }}
                  >
                    {lang == "ar" ? "لا توجد اشعارات" : "No notifications"}
                  </Typography>
                </Box>
              )}
            </>
          )}
        </Box>
      </Grid>
    </Box>
  );
}
