import { Box, InputAdornment, TextField, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useMutation } from "@tanstack/react-query";
import { api_url } from "@/constants/base_url";
import axios from "axios";
import { Close } from "@mui/icons-material";
import { ClipLoader } from "react-spinners";
import { FormProvider, useForm } from "react-hook-form";
import { notifyError, notifySuccess } from "@shared/Toast/toast";
import { COLORS } from "@/constants/Colors/colors";
import ColorButton from "@shared/Buttons/ColorButton/ColorButton";
import BorderedInput from "@/components/Form/BorderedInput/BorderedInput";
import { LockOutlinedIcon } from "@mui/icons-material/LockOutlined";
import { zodResolver } from "@hookform/resolvers/zod";
import { updateSupervisorSchema } from "../../../shema/updateSupervisor.schema";
import { currentUser } from "@shared/Helpers/encryptionHelpers";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import { FONTS } from "@/constants/Fonts/fonts";
import Form from "@shared/Form/Form";

export default function UpdateSupervisor({
  noteBook,
  closeNote,
  refetch,
  name,
  supervisorId,
}) {
  const lang = useSelector((state) => state?.global?.lang);
  const isDarkMode = useSelector((state) => state?.global?.isDarkMode);

  const [password, setPassword] = useState("");
  const [firstName, setFirstName] = useState(name);
  const methods = useForm({
    resolver: zodResolver(updateSupervisorSchema),
  });

  const { mutate, isPending, error, isError } = useMutation({
    mutationFn: (formData) =>
      axios.post(`${api_url}supervisors/update`, formData, {
        headers: {
          // 'Content-Type': 'application/json',
          Authorization: `Bearer ${currentUser()?.token}`,
          lang: lang,
        },
      }),
    onSuccess: (data) => {
      console.log(data);
      refetch();
      notifySuccess(
        lang == "ar"
          ? "تم تعديل بيانات المسئول"
          : "Administrator information has been modified"
      );
      closeNote();
    },
    onError: (error) => {
      console.log(error);
      notifyError(
        lang == "ar"
          ? "حدث خطأ اثناء تنفيذ المهمة"
          : "An error occurred while executing the task"
      );
    },
  });

  const { setValue, handleSubmit, formState, clearErrors, getValues } = methods;

  const onSubmit = () => {
    console.log(`supervisorId`, supervisorId);
    const { firstName, password } = getValues();
    const formData = {
      name: firstName,
      password: password.toString(),
      supervisor_id: supervisorId.toString(),
    };
    console.log(formData);
    mutate(formData);
  };

  useEffect(() => {
    setFirstName(name);
    setValue("firstName", name);
  }, [name, setValue]);

  

  return (
    <div
      className=""
      style={{
        position: "fixed",
        top: "0",
        left: "0",
        background: "#000000ab",
        width: "100%",
        height: "100vh",
        zIndex: "1500",
        display: "flex",
        // justifyContent: 'center',
        padding: "80px 0px",
        alignItems: "center",
        justifyContent: "center",
        flexDirection: "column",
        transition: "0.3s",
        scrollbarColor: COLORS.SECONDARY.MAIN,
        scrollbarWidth: "thin",
        overflow: "hidden",
        opacity: noteBook === "update-supervisor" ? 1 : 0,
        visibility: noteBook === "update-supervisor" ? "visible" : "hidden",
      }}
    >
      <Box
        className="device-box"
        px={3}
        sx={{
          padding: "20px 30px",
          background: "#F5F5F5",
          borderRadius: "10px",
          width: "80%",
          height: "auto",
          // overflowY: 'auto',
          display: "flex",
          flexDirection: "column",
          transition: "0.3s",
          opacity: noteBook === "update-supervisor" ? 1 : 0,
          transform: noteBook === "update-supervisor" ? "scale(1)" : "scale(0)",
        }}
      >
        <div
          className="close-btn"
          style={{
            position: "absolute",
            top: 5,
            right: 5,
            zIndex: 100,
          }}
        >
          <Close
            onClick={() => {
              closeNote();
              // reset();
            }}
            sx={{
              color: COLORS.SECONDARY.MAIN,
              padding: "10px 10px",
              width: "40px",
              height: "40px",
              borderRadius: "5px",
              transition: "0.4s",
              cursor: "pointer",
              "&:hover": {
                bgcolor: "white",
                color: COLORS.SECONDARY.MAIN,
              },
            }}
          />
        </div>

        <FormProvider {...methods}>
          <Form title={lang == "ar" ? "تعديل بيانات المسئول" : "Modify Supervisor data"} onSubmit={handleSubmit(onSubmit)}>
            <Form.TextController
              register={methods.register}
              registername={"firstName"}
              placeholder={lang == "ar" ? "ادخل الاسم" : "Enter name"}
              errorMessage={methods.formState.errors.firstName?.message}
            />

            <Form.TextController
              register={methods.register}
              registername={"password"}
              type="password"
              placeholder={lang == "ar" ? "كلمة المرور" : "Password"}
              errorMessage={methods.formState.errors.password?.message}
            />

            <Form.ButtonController
              type="submit"
              onClick={handleSubmit(onSubmit)}
              isPending={isPending}
            >
              {lang == "ar" ? (
                "حفظ"
              ) : (
                "Save"
              )}
            </Form.ButtonController>

          </Form>
        </FormProvider>
      </Box>
    </div>
  );
}

UpdateSupervisor.propTypes = {
  noteBook: PropTypes.bool,
  closeNote: PropTypes.func,
  refetch: PropTypes.func,
  supervisorId: PropTypes.number,
  name: PropTypes.string,
};
