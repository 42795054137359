import React, { useState } from "react";
import { Box, Typography, IconButton } from "@mui/material";
import { COLORS } from "@/constants/Colors/colors";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import { DeleteExamModal } from "../DeleteExamModal/DeleteExamModal";
import { ExamEditor } from "../../ExamEditor/ExamEditor";
import { useParams } from "react-router-dom";
import useCustomQuery from "@/hooks/useCustomQuery";
import { useSelector } from 'react-redux';
import examStyle from "./CourseExamBox.module.css"
import { FaTrashAlt } from "react-icons/fa";

export const CourseExamBox = ({ item, index }) => {
  const globalState = useSelector((state) => state?.global);
  const lang = globalState?.lang;
  const isDarkMode = globalState?.isDarkMode;

  const [del, setDel] = useState(false);
  const [edit, setEdit] = useState(false);
  const { id } = useParams();
  const { data, isPending } = useCustomQuery({
    url: `exam/getExamAdmin?exam_id=${item?.id}`,
    key: ["course exam", item?.id, lang],
    enabled: edit,
    lang,
  });
  return (
    <>
      <div
        className={examStyle.examCard}
        key={item.id}
        style={{ backgroundColor: isDarkMode ? COLORS.DARK_GRAY : COLORS.LIGHT_GRAY }}
      >
        <div>
          <p>{index < 9 ? "0" + (index + 1) : index + 1}</p>
          <p>{item.title}</p>
        </div>

        <Box sx={{ display: "flex", gap: 2 }}>
          <IconButton
            onClick={() => setEdit(true)}
          >
            <EditIcon fontSize="small" />
          </IconButton>
          {new Date(item?.starts_at) - new Date() > 0 && (
            <IconButton
              onClick={() => setDel(true)}
            >
              <FaTrashAlt />
            </IconButton>
          )}
        </Box>
      </div>

      <DeleteExamModal
        id={item.id}
        isConfirmOpen={del}
        setIsConfirmOpen={setDel}
        confirmMessage={
          lang == "ar"
            ? "هل تريد حذف الإمتحان"
            : "Do you want to delete the exam?"
        }
        deleteName={item.title}
      />

      {!isPending && (
        <ExamEditor
          examQuests={data}
          id={id}
          open={edit}
          setOpen={setEdit}
          type={"Course"}
          started={new Date() - new Date(item?.starts_at) >= 0}
        />
      )}
    </>
  );
};
