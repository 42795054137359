import { Box, Typography, Card, CardMedia } from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";
import { FONT_SIZES } from "@/constants/Fonts/font_sizes";
import { FONTS } from "@/constants/Fonts/fonts";
import { COLORS } from "@/constants/Colors/colors";
import Row from "@/components/Layout/Row/Row";
import Column from "@/components/Layout/Column/Column";
import { trimLongText, getImageUrl } from "@/utils/functions";
import useCustomQuery from "@/hooks/useCustomQuery";
import { useRecoilState } from "recoil";
import { activeCourseAtom } from "@/modules/courses/services/RecoilAtoms/ActiveCourse.atom";
import { ClipLoader } from "react-spinners";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import style from "./CourseCard.module.css"

export default function CourseCard({ courseId, parent }) {

  const navigate = useNavigate();
  const { pathname } = useLocation();

  const [_, setActiveCourse] = useRecoilState(activeCourseAtom);
  const globalState = useSelector((state) => state?.global);
  const lang = globalState?.lang;
  const isDarkMode = globalState?.isDarkMode;

  if (pathname?.split("/")[2] === "courses") {
    parent = "courses";
  }

  const { data: course } = useCustomQuery({
    method: "post",
    url: `course/courseData?id=${courseId}`,
    key: ["course", courseId],
  });

  return (
    <div style={{ fontFamily: lang === 'ar' ? "" : FONTS.ENGLISH }}>
      <Card
        onClick={() => {
          if (parent === "courses") {
            setActiveCourse(course);
          } else {
            navigate(`/dashboard/coursesCodes/course/${courseId}`);
          }
        }}
        onDoubleClick={() => {
          navigate(`/dashboard/${pathname?.split("/")[2]}/course/${courseId}`);
        }}
        sx={{
          cursor: "pointer",
          width: "100%",
          borderRadius: "5%",
          position: "relative",
          bgcolor: globalState?.isDarkMode
            ? COLORS.DARK_GRAY
            : COLORS.PRIMARY.MAIN,
          fontFamily: lang === 'ar' ? FONTS.REGULAR : FONTS.ENGLISH,
        }}
      >
        <Box sx={{ position: "relative" }}>
          {getImageUrl(course?.image) ? (
            <CardMedia
              sx={{
                height: 260,
                backgroundPosition: "top",
                backgroundSize: "cover",
                backgroundColor: globalState?.isDarkMode
                  ? COLORS.DARK_GRAY
                  : COLORS.WHITE,
              }}
              image={getImageUrl(course?.image)}
              title="teacher img"
            />
          ) : (
            <Box
              sx={{
                width: "100%",
                height: 264,
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <ClipLoader size={40} color={COLORS.PRIMARY.MAIN} />
            </Box>
          )}

          <Box
            position={"absolute"}
            bottom={5}
            left={5}
            sx={{
              backgroundColor: COLORS.SECONDARY.MAIN,
              zIndex: "2",
              width: "40px",
              height: "40px",
              borderRadius: "50%",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
            }}
            color={"white"}
            fontFamily={"Noor-regular"}
            fontSize={10}
            textAlign={"center"}
          >
            {`${course?.duration} ${lang === 'ar' ? "ساعة" : "hours"}` || 2}
          </Box>
        </Box>
        <Box sx={{ color: COLORS.WHITE }} mt={1} mb={1} px={2}>
          <Row spacing="space-between">
            <h2
              title={course?.name || `${lang === 'ar' ? 'كورس' : 'Course'}`}
              className={style.courseName}
              style={{ fontFamily: lang === 'ar' ? FONTS.BOLD : FONTS.ENGLISH }}
            >
              {course?.name ?
                (course.name.length > 10 ? course.name.slice(0, 10) + '...' : course.name)
                : `${lang === 'ar' ? 'كورس' : 'Course'}`}
            </h2>
            <Box>
              <Row gap={0.5}>
                <Typography
                  fontSize={FONT_SIZES.REGULAR}
                  fontFamily={FONTS.BOLD}
                >
                  {course?.subscriptionsCount || 0}
                </Typography>
                <Typography
                  fontSize={FONT_SIZES.EXTRA_SMALL}
                  fontFamily={lang === 'ar' ? FONTS.REGULAR : FONTS.ENGLISH}
                >
                  {lang === 'ar' ? "طلاب" : "Students"}
                </Typography>
              </Row>
              <Row gap={0.5}>
                <Typography
                  fontSize={FONT_SIZES.REGULAR}
                  fontFamily={FONTS.BOLD}
                >
                  {course?.modulesCount || 0}
                </Typography>
                <Typography
                  fontSize={FONT_SIZES.EXTRA_SMALL}
                  fontFamily={lang === 'ar' ? FONTS.REGULAR : FONTS.ENGLISH}
                >
                  {lang === 'ar' ? "حصص" : "Lessons"}
                </Typography>
              </Row>
            </Box>
          </Row>

          <Column gap={1} spacing="flex-start">
            <Typography fontSize={FONT_SIZES.SMALL} fontFamily={lang === 'ar' ? FONTS.REGULAR : FONTS.ENGLISH} sx={{ color: COLORS.LIGHT_GRAY }}>
              {trimLongText(course?.description, 30)}
            </Typography>
            <Typography
              fontSize={FONT_SIZES.REGULAR}
              fontFamily={lang === 'ar' ? FONTS.REGULAR : FONTS.ENGLISH}
            >
              {course?.price == 0 ? lang === "ar" ? "مجاني" : "Free" : `${course?.price} ${lang === 'ar' ? "جنيه" : "EGP"}`}
            </Typography>
          </Column>
          <button
            className={style.showButton}
            onClick={() => {
              navigate(`/dashboard/${pathname?.split("/")[2]}/course/${courseId}`);
            }}>
            {lang === 'ar' ? "المزيد" : "Show more"}
          </button>
        </Box>
      </Card>
    </div>
  );
}

CourseCard.propTypes = {
  courseId: PropTypes.number,
  parent: PropTypes.string,
};
