import { Box, Typography } from "@mui/material";
import learning from "@assets/images/Learning-bro-blue.png";
import { COLORS } from "@/constants/Colors/colors";
import { useSelector } from "react-redux";
import InsightsIcon from '@mui/icons-material/Insights';
import introStyle from "../../styles/introductionSection.module.css"
import HistoryToggleOffIcon from '@mui/icons-material/HistoryToggleOff';
import ThumbUpAltIcon from '@mui/icons-material/ThumbUpAlt';

export default function Introduction() {
  const globalState = useSelector((state) => state?.global);
  const lang = globalState?.lang;
  const isDarkMode = globalState?.isDarkMode;
  return (

    <>
      <div id="introduction" className={`${introStyle.introduction} ${lang === 'ar' ? "" : "eng"}`}
        style={{ direction: lang === "ar" ? 'rtl' : "ltr", backgroundColor: isDarkMode ? COLORS.PRIMARY.MAIN : COLORS.WHITE }}>

        <img src={learning} alt="" />

        <div className={introStyle.content} style={{ color: COLORS.WHITE, backgroundColor: isDarkMode ? COLORS.BLACK : COLORS.PRIMARY.MAIN }}>
          <h1 style={{ color: COLORS.WHITE }}>{lang === 'ar' ? 'مرحبًا بك في برنامج النسخة التجريبية' : 'Welcome to the Beta Program!'}</h1>
          <p>{lang === 'ar' ? "يسرنا أن نقدم لك الفرصة لاستكشاف منتجنا قبل اتخاذ قرار الشراء." : 'We are pleased to offer you the opportunity to explore our product before making a purchase decision'}</p>
          <ul>
            <li style={{ backgroundColor: isDarkMode ? COLORS.DARK_GRAY : COLORS.WHITE, color: isDarkMode ? COLORS.WHITE : COLORS.PRIMARY.MAIN }}><Bullet icon={<InsightsIcon />} />{lang === 'ar' ? "هدفنا هو ضمان أن تكون تجربتك مع برنامجنا مرضية وتلبي توقعاتك." : 'Our goal is to ensure that your experience with our software is satisfying and meets your expectations.'}</li>
            <li style={{ backgroundColor: isDarkMode ? COLORS.DARK_GRAY : COLORS.WHITE, color: isDarkMode ? COLORS.WHITE : COLORS.PRIMARY.MAIN }}><Bullet icon={<HistoryToggleOffIcon />} />{lang === 'ar' ? 'هذا البرنامج التجريبي يمنحك إمكانية الوصول الكامل إلى جميع الميزات والوظائف لفترة محدودة.' : 'This beta program provides you with full access to all features and functionalities for a limited period.'}</li>
            <li style={{ backgroundColor: isDarkMode ? COLORS.DARK_GRAY : COLORS.WHITE, color: isDarkMode ? COLORS.WHITE : COLORS.PRIMARY.MAIN }}><Bullet icon={<ThumbUpAltIcon />} />{lang === 'ar' ? 'نحثك على تجربة كل جانب من جوانب البرنامج وتقديم أي ملاحظات أو استفسارات قد تكون لديك' : 'We encourage you to try every aspect of the software and provide any feedback or questions you may have.'}</li>
          </ul>
          <p>{lang === 'ar' ? 'يرجى مراعاة أن النسخة التجريبية هي فرصة لاختبار البرنامج وتحديد ما إذا كان يلبي احتياجاتك قبل الشراء النهائي. بعد انتهاء فترة النسخة التجريبية، يمكنك اتخاذ قرار الشراء بثقة. نشكرك على اهتمامك بمنتجنا، ونأمل أن تجد البرنامج ذا فائدة كبيرة لك. إذا كنت بحاجة إلى مساعدة أو لديك أي أسئلة، فلا تتردد في الاتصال بفريق الدعم الفني لدينا. نأمل أن تكون تجربتك إيجابية ومفيدة.' : 'Please note that the beta version is an opportunity to test the software and determine if it meets your needs before the final purchase. After the beta period ends, you can make a confident purchase decision. Thank you for your interest in our product, and we hope you find the software highly beneficial. If you need assistance or have any questions, please do not hesitate to contact our technical support team. We hope your experience is positive and valuable.'}</p>
        </div>
      </div>
    </>
  );
}


const Bullet = ({ icon }) => {
  return (
    <div className={introStyle.bullet}>{icon}</div>
  )
}
