// import { Box, Button, Typography, CardMedia } from "@mui/material";
// import React, { useState, useEffect } from "react";
// import { COLORS } from "@/constants/Colors/colors";
// import { BookReservationForm } from "./BookReservationForm/BookReservationForm";
// import { useSelector } from "react-redux";
// import bookStyle from "./UserBookCard.module.css"

// export const UserBookCard = (props) => {
//   const [open, setOpen] = useState(false);
//   const globalState = useSelector((state) => state?.global);
//   const lang = globalState?.lang;
//   const isDarkMode = globalState?.isDarkMode;

//   const {
//     id,
//     img,
//     title,
//     price,
//     teacherName,
//     isOpenForReservation,
//     available_amount,
//   } = props;

//   const [isSmallScreen, setIsSmallScreen] = useState(window.innerWidth < 500);

//   useEffect(() => {
//     const handleResize = () => {
//       setIsSmallScreen(window.innerWidth < 500);
//     };

//     window.addEventListener('resize', handleResize);
//     return () => {
//       window.removeEventListener('resize', handleResize);
//     };
//   }, []);

//   return (
//     <>
//       <Box
//         sx={{
//           width: "95%",
//           padding: "10px",
//           bgcolor: globalState?.isDarkMode ? COLORS.DARK_GRAY : COLORS.WHITE,
//           borderRadius: "10px",
//           marginBottom: "20px",
//           position: "relative",
//         }}
//       >
//         <div className={bookStyle.image}>
//           <CardMedia
//             sx={{
//               height: isSmallScreen ? "100px" : "200px",
//               backgroundPosition: "center",
//               backgroundSize: "contain",
//               bgcolor: isDarkMode
//                 ? COLORS.SOFT_DARK
//                 : COLORS.WHITE,
//             }}
//             image={img}
//             title="book"
//           />
//         </div>

//         <Box px={1} py={1}>
//           <Box
//             sx={{
//               display: "flex",
//               justifyContent: "space-between",
//               alignItems: "center",
//             }}
//           >
//             <Typography
//               // color={"black"}
//               fontSize={20}
//               fontFamily={"Noor-bold"}
//               mb={0.5}
//             >
//               {title?.length > 15 ? `${title?.slice(0, 15)}...` : title}
//             </Typography>

//             <Typography
//               fontSize={14}
//               fontFamily={"Noor-bold"}
//               sx={{
//                 padding: "5px 10px",
//                 bgcolor: "primary.main",
//                 color: "white",
//                 borderRadius: "5px",
//               }}
//             >
//               {price} {lang == "ar" ? "جنية" : "EGP"}
//             </Typography>
//           </Box>

//           <Typography
//             fontSize={14}
//             sx={{
//               opacity: "0.6",
//             }}
//             mb={2}
//           >
//             {lang == "ar" ? "الكمية المتاحة :" : "Available quantity"}{" "}
//             {available_amount}
//           </Typography>
//           <Typography fontSize={18} color={"primary.main"} mb={1}>
//             {teacherName}
//           </Typography>

//           <Button
//             onClick={() => {
//               setOpen(true);
//             }}
//             sx={{
//               bgcolor: COLORS.PRIMARY.MAIN,
//               color: COLORS.WHITE,
//               width: "100%",
//               "&:hover": { bgcolor: COLORS.PRIMARY.MAIN },
//               "&:disabled": { bgcolor: COLORS.BOX, color: COLORS.DARK_GRAY },
//             }}
//             disabled={!isOpenForReservation}
//           >
//             {isOpenForReservation
//               ? lang == "ar"
//                 ? "احجز الان"
//                 : "Book now"
//               : lang == "ar"
//                 ? "غير متاح للحجز"
//                 : "Unavailable for booking"}
//           </Button>
//         </Box>
//       </Box>
//       <BookReservationForm open={open} setOpen={setOpen} id={id} book={props} />
//     </>
//   );
// };


import { Box, Button, Typography, CardMedia } from "@mui/material";
import React, { useState, useEffect } from "react";
import { COLORS } from "@/constants/Colors/colors";
import { BookReservationForm } from "./BookReservationForm/BookReservationForm";
import { useSelector } from "react-redux";
import bookStyle from "./UserBookCard.module.css"
import { FONTS } from "@/constants/Fonts/fonts";

export const UserBookCard = (props) => {
  const [open, setOpen] = useState(false);

  const {
    id,
    img,
    title,
    price,
    teacherName,
    isOpenForReservation,
    available_amount,
    lang,
    isDarkMode,
    color
  } = props;

  const [isSmallScreen, setIsSmallScreen] = useState(window.innerWidth < 500);

  useEffect(() => {
    const handleResize = () => {
      setIsSmallScreen(window.innerWidth < 500);
    };

    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <>
      <div className={bookStyle.bookCard} style={{ fontFamily: lang === 'en' ? FONTS.ENGLISH : "", backgroundColor: isDarkMode ? COLORS.SOFT_DARK: COLORS.WHITE, boxShadow: isDarkMode? "none" : ""}}>
        <div className={bookStyle.image}>
          <CardMedia
            sx={{
              height: isSmallScreen ? "140px" : "200px",
              backgroundPosition: "center",
              backgroundSize: "contain",
            }}
            image={img}
            title="book"
          />
        </div>

        <div className={bookStyle.details}>
          <div>
            <div className={bookStyle.amountContainer}>
              <div className={bookStyle.amount} style={{ color: available_amount? color: COLORS.DANGER , borderColor: available_amount ? color : COLORS.DANGER }}>
                {lang == "ar" ? "متاح" : "Available"}
                : {available_amount} {lang==='ar' ? "كتاب":"Book"}
              </div>
            </div>
            <h2>
              {title?.length > 15 ? `${title?.slice(0, 15)}...` : title}
            </h2>
            {/* <Typography fontSize={18} color={"primary.main"} mb={1}>
              {teacherName}
            </Typography> */}

          </div>

          <div>
            <div className={bookStyle.price}>
              <p style={{ color: color, borderWidth: lang === 'ar' ? "0 0 0 2px" : "0 2px 0 0" }}>{lang === 'ar' ? "السعر" : "Price"}:</p>
              <p>{price}{lang == "ar" ? " جنية" : "EGP"}</p>
            </div>

            <button
              onClick={() => {
                setOpen(true);
              }}
              disabled={!isOpenForReservation || !available_amount}
              style={{ backgroundColor: color, color: isDarkMode ? COLORS.SOFT_DARK : COLORS.WHITE }}
            >
              {isOpenForReservation && available_amount
                ? lang == "ar"
                  ? "احجز الان"
                  : "Book now"
                : lang == "ar"
                  ? "غير متاح"
                  : "Unavailable"}
            </button>
          </div>
        </div>
      </div>
      <BookReservationForm open={open} setOpen={setOpen} id={id} book={props} />
    </>
  );
};

