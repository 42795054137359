import { COLORS } from "@/constants/Colors/colors";
import { FONTS } from "@/constants/Fonts/fonts";
import { Typography } from "@mui/material";
import { useSelector } from "react-redux";

export default function NoItemsText({ text, sx }) {
  const globalState = useSelector((state) => state?.global);
  const lang = useSelector((state) => state?.global?.lang);

  return (
    <Typography
      sx={{
        fontFamily: lang === 'ar' ? "Noor-bold" : FONTS.ENGLISH,
        fontWeight:'bold',
        fontSize: "30px",
        textAlign: "center",
        color: globalState?.isDarkMode ? COLORS.WHITE : COLORS.BLACK,
        ...sx,
      }}
    >
      {text}
    </Typography>
  );
}
