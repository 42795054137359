import { Box, Typography, Button } from "@mui/material";
import React from "react";
import { COLORS } from "@/constants/Colors/colors";
import { notifyError } from "@shared/Toast/toast";
import { useNavigate } from "react-router-dom";
import { UserCourseModule } from "./UserCourseModule/UserCourseModule";
import { useSelector } from "react-redux";
import NoDataFound from '@/components/NoDataFound/NoDataFound';
import generalCourses from "../UserCourse.module.css"

export const UserCourseModules = ({ bought, course }) => {
  const globalState = useSelector((state) => state?.global);
  const lang = globalState?.lang;
  const navigate = useNavigate();
  return (
    <Box sx={{ padding: 5, width: "100%" }}>
    
      <Box>
        {course?.modules?.length > 0 ? (
          course?.modules.map(({ name, id }, index) => (
            <UserCourseModule
              key={id}
              name={name}
              id={id}
              index={index}
              bought={bought}
              course={course}
            />
          ))
        ) : (
          <div className={generalCourses.noDataImage} style={{ position: 'relative', width: 'calc(100vw - 450px)' }}>
          <NoDataFound title={lang == "ar" ? "لا توجد دروس " : "There are no Lessons"} />
        </div>
        )}
      </Box>
    </Box>
  );
};
