import { useMutation } from "react-query";
import { notifyError, notifySuccess } from "@shared/Toast/toast";
import useAxiosConfig from "@shared/Helpers/axios/useAxiosConfig";
import { useQueryClient } from "@tanstack/react-query";
import * as tus from "tus-js-client";

const useAddVideo = ({
  lang,
  onSuccess,
  setProgressUpload,
  setTitleUpload,
}) => {
  const queryClient = useQueryClient();
  const instance = useAxiosConfig(lang);

  const addModuleVideo = async (videoData) => {
    const formData = new FormData();
    formData.append("name", videoData.name);
    formData.append("link", videoData.link);
    formData.append("module_id", videoData.module_id);

    if (!videoData.link) {
      formData.delete("link");
    }

    try {
      const response_store = await instance.post(`lesson/store`, formData, {
        headers: {
          "Content-Type": "application/json",
        },
      });
      if (videoData.video) {
        const data = response_store.data;

        // Ensure the video data is valid before uploading it
        if (videoData?.video && videoData?.video instanceof File) {
          const videoFile = videoData.video;

          try {
            const res = await instance.get(
              `lesson/video/resumableUpload/${data.id}`
            );
            console.log(res, "res");

            var upload = new tus.Upload(videoFile, {
              endpoint: "https://video.bunnycdn.com/tusupload",
              retryDelays: [0, 3000, 5000, 10000, 20000, 60000, 60000],
              headers: {
                AuthorizationSignature:
                  res?.data?.data?.headers?.AuthorizationSignature, // SHA256 signature
                AuthorizationExpire:
                  res?.data?.data?.headers?.AuthorizationExpire, // Expiration time
                VideoId: res?.data?.data?.headers?.VideoId, // The guid of the video object
                LibraryId: res?.data?.data?.headers?.LibraryId,
              },
              metadata: {
                filetype: videoFile.type,
                title: videoData.name,
                collection: res?.data?.collectionId,
              },
              onError: function (error) {
                console.log("Upload error:", error);
              },
              onProgress: function (bytesUploaded, bytesTotal) {
                setProgressUpload(
                  Math.round((bytesUploaded / bytesTotal) * 100)
                );
                setTitleUpload(videoData.name);
                console.log("progress");
              },
              onSuccess: function () {
                console.log("success");
                queryClient.invalidateQueries(["module"]);
                setProgressUpload(null);
                setTitleUpload(null);
                console.log("Upload completed successfully.");
              },
            });

            // Check if there are any previous uploads to continue.
            const previousUploads = await upload.findPreviousUploads();
            if (previousUploads.length) {
              upload.resumeFromPreviousUpload(previousUploads[0]);
            }

            // Start the upload
            upload.start();
          } catch (error) {
            console.error("Error during the upload process:", error);
            notifyError("An error occurred during the upload process.");
          }
        } else {
          throw new Error("Invalid video file");
        }

        queryClient.invalidateQueries(["course modules"]);
      }
    } catch (error) {
      console.error("Error occurred:", error);
      throw error;
    }
  };

  const mutation = useMutation(addModuleVideo, {
    onSuccess: (data) => {
      notifySuccess(
        lang === "ar" ? "تم رفع الفيديو بنجاح" : "Video uploaded successfully"
      );
      onSuccess(data);
    },
    onError: (error) => {
      console.error("Error uploading video:", error);
      notifyError(error?.response?.data?.message || "An error occurred");
    },
  });

  return {
    addModuleVideo: mutation.mutate,
    isPending: mutation.isLoading,
    error: mutation.error,
  };
};

export default useAddVideo;
