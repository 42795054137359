import {
  Box,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Typography,
} from "@mui/material";
import { useState } from "react";
import { ClipLoader } from "react-spinners";
import { useMutation } from "@tanstack/react-query";
import axios from "axios";
import { useParams } from "react-router-dom";
import { Close } from "@mui/icons-material";
import { Navigate } from "react-router-dom";
import { api_url } from "@/constants/base_url";
import { currentUser } from "@shared/Helpers/encryptionHelpers";
import { notifyError, notifySuccess } from "@shared/Toast/toast";
import { useRecoilState } from "recoil";
import { BookActiveAtom } from "../services/BookActive.atom";
import { ROLES } from "@/constants/roles";
import ColorButton from "@/components/Buttons/ColorButton/ColorButton";
import useCustomQuery from "@/hooks/useCustomQuery";
import { COLORS } from "@/constants/Colors/colors";
import getImageUrl from "@shared/Helpers/image/getImageUrl";
import CustomTableHead from "@/components/Layout/TableHead/CustomTableHead";
import CustomTableLoading from "@/components/Layout/TableRowLoading/CustomTableLoading";
import Paginator from "@/components/Pagination/Paginator";
import EditBookOrders from "../components/EditBookOrders";
import CreateRoundedIcon from "@mui/icons-material/CreateRounded";
import { useSelector } from "react-redux";
import { FONTS } from "@/constants/Fonts/fonts";

export default function BookOrders() {
  const globalState = useSelector((state) => state?.global);
  const lang = globalState?.lang;

  const tableHeader = [
    lang == "ar" ? "اسم الطالب" : "Student name",
    lang == "ar" ? "الرقم الأول" : "First number",
    lang == "ar" ? "الرقم الثاني" : "Second number",
    lang == "ar" ? "العنوان" : "Address",
    lang == "ar" ? "تاريخ الحجز" : "booking date",
    lang == "ar" ? "عدد النسخ" : "Number of copies",
    lang == "ar" ? "طريقة الدفع" : "Payment methods",
    "",
  ];

  const bookData = useRecoilState(BookActiveAtom);

  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState();

  const { id } = useParams();

  const activeBook = JSON.parse(localStorage.getItem("activeBook"));

  const [isEditing, setIsEditing] = useState(false);

  const { data, isPending, error, isError, refetch } = useCustomQuery({
    url: `bookOrder/showByBook?book_id=${id}&page=${page}`,
    method: "get",
    key: ["bookOrder", id, lang],
    page: page,
    lang,
    onSuccess: () => {
      setTotalPages(data?.data?.last_page);
    },
  });
  const {
    data: orders,
    isPending: ordersIsPending,
    refetch: ordersRefetch,
  } = useCustomQuery({
    url: `book/showBook?book_id=${id}`,
    method: "get",
    key: ["bookOrders", id, lang],
    page: page,
    lang,
    onSuccess: () => { },
  });

  const [getBoodData, setGetBookData] = useState();

  const [imageBox, setImage] = useState();
  const showImage = (img) => {
    setImage(img);
  };

  const [openImage, setOpenImage] = useState(false);

  const openIamgeBox = () => {
    setOpenImage(true);
  };

  const closeImageBox = () => {
    setOpenImage(false);
  };

  const { mutate: confirmOrder } = useMutation({
    mutationFn: ({ id, confirm }) =>
      axios.post(
        `${api_url}bookOrder/confirmOrder`,
        { order_id: id, is_confirmed: confirm },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${currentUser()?.token}`,
            lang: lang,
          },
        }
      ),
    onSuccess: () => {
      refetch();
      notifySuccess(
        lang == "ar"
          ? "تم تاكيد الطلب بنجاح"
          : "The order was confirmed successfully"
      );
    },
    onError: () => {
      notifyError(
        lang == "ar"
          ? "حدث خطأ اثناء تاكيد الطلب"
          : "An error occurred while confirming the order"
      );
    },
  });

  if (ROLES == "Admin") {
    return <Navigate to="/dashboard" replace />;
  }
  const [downloadExcel, setDownloadExcel] = useState(true);
  function downloadCSV(data, filename) {
    const csv = convertToCSV(data);
    const link = document.createElement("a");
    // link.href = 'data:text/csv;charset=utf-8,' + encodeURIComponent(csv);
    link.href = "data:text/csv;charset=utf-8,%EF%BB%BF" + encodeURI(csv);
    link.download = filename;

    // Simulate a click on the download link
    link.style.display = "none";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }

  function convertToCSV(data) {
    data = data.map((obj) => {
      const newData = { ...obj };
      delete newData.user_id;
      delete newData.book_id;
      newData.reservation_image = getImageUrl(`/${obj.reservation_image}`);
      return newData;
    });

    const headers = Object.keys(data[0]);
    console.log("data", data);

    const headerRow = headers.join(",") + "\n";

    const escapeCellValue = (value) => {
      if (typeof value !== "string") {
        value = String(value);
      }

      if (value.includes('"')) {
        value = value.replace(/"/g, '""');
      }

      if (value.includes(",") || value.includes("\n")) {
        value = `"${value}"`;
      }
      value = value + String.fromCharCode(8203);
      return value;
    };

    const rows = data.map((obj) => {
      const rowValues = headers.map((key) => {
        let value = obj[key];

        if (typeof value === "string" && /^\d{10}$/.test(value)) {
          value = `'${value}`;
        }

        return escapeCellValue(value);
      });

      return rowValues.join(",") + "\n";
    });
    console.log(rows);
    return headerRow + rows.join("");
  }

  const { mutate, isLoading: excelLoading } = useMutation({
    mutationFn: () =>
      axios.get(`${api_url}excel/excelBookOrder?book_id=${id}&isExcel=0`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${currentUser()?.token}`,
          lang: lang,
        },
      }),
    onSuccess: (data) => {
      console.log(`excel`, data);
      if (data?.data instanceof Array) {
        downloadCSV(data?.data, `${activeBook.title}.csv`);
      }
    },
    onError: (err) => {
      console.log(err);
    },
  });

  const closeEditing = () => {
    setIsEditing(false);
  };

  return (
    <>
      <Box px={3}>
        {isEditing ? (
          <EditBookOrders
            closeEditing={closeEditing}
            bookData={orders?.data}
            refetch={ordersRefetch}
          />
        ) : (
          <Box
            sx={{
              display: "flex",
              // justifyContent: "center",
              padding: "20px",
              borderRadius: "10px",
              bgcolor: COLORS.DARK_GRAY,
            }}
          >
            <Box>
              <img
                style={{
                  width: "400px",
                  height: "300px",
                  borderRadius: "10px",
                  marginLeft: "15px",
                }}
                src={activeBook ? activeBook?.img : bookData[0]?.img}
                alt=""
              />
            </Box>

            <Box
              sx={{
                width: "100%",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  // alignItems: 'center',
                  // marginBottom: "5px",
                }}
              >
                <Typography
                  color={COLORS.WHITE}
                  fontSize={35}
                  fontFamily={"Noor-bold"}
                // sx={{
                //   transition: "0.4s",
                //   "&:hover": { color: "primary.main" },
                // }}
                >
                  {orders?.data?.title}
                </Typography>

                <Box>
                  <Typography
                    fontSize={14}
                    fontFamily={"Noor-bold"}
                    sx={{
                      padding: "5px 10px",
                      bgcolor: COLORS.PRIMARY.MAIN,
                      color: COLORS.WHITE,
                      fontSize: "25px",
                      borderRadius: "5px",
                      mb: 3,
                    }}
                  >
                    {orders?.data?.price} {lang == "ar" ? "جنية" : "EGP"}
                  </Typography>
                  <Typography
                    fontSize={14}
                    fontFamily={"Noor-bold"}
                    sx={{
                      padding: "5px 10px",
                      bgcolor: "secondary.main",
                      color: "white",
                      fontSize: "20px",
                      borderRadius: "5px",
                    }}
                  >
                    {`  ${lang == "ar"
                        ? "اجمالي المدفوعات بالمحفظة :" + " "
                        : "Total payments in the wallet: " + " "
                      } ${orders?.data?.paid_money}`}
                  </Typography>
                  <CreateRoundedIcon
                    sx={{
                      color: "#fff",
                      bgcolor: "#80eead",
                      borderRadius: "50%",
                      padding: "5px",
                      marginRight: "0px",
                      fontSize: "40px",
                      cursor: "pointer",
                      mt: "20px",
                    }}
                    onClick={() => setIsEditing(true)}
                  />
                </Box>
              </Box>
              <Typography
                fontSize={20}
                color={"white"}
                sx={{ opacity: ".8", marginBottom: "30px" }}
              >
                {lang == "ar"
                  ? "الكمية المتاحة :"
                  : "Quantity available :" + " "}
                {orders?.data?.available_amount}
              </Typography>
              <Typography
                color={"white"}
                fontSize={30}
                fontFamily={"Noor-bold"}
              >
                {`${lang == "ar" ? " عدد الطلبات :" : "Number of orders :" + " "
                  } ${orders?.data?.book_orders_count}`}
              </Typography>

              <Typography
                color={"white"}
                fontSize={30}
                fontFamily={"Noor-bold"}
              >
                {orders?.data?.isOpenForReservation &&
                  orders?.data?.available_amount > 0
                  ? lang == "ar"
                    ? "متاح للحجز"
                    : "Available for booking"
                  : lang == "ar"
                    ? "غير متاح للحجز"
                    : "Not available for booking"}
              </Typography>
            </Box>
          </Box>
        )}

        <Box
          my={5}
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Typography
            fontFamily={lang === 'ar' ? "Noor-bold" : FONTS.ENGLISH}
            fontSize={32}
            sx={{ fontWeight: 'bold' }}
          >
            {lang == "ar" ? " طلبات الكتاب" : "Book requests"}
          </Typography>

          <Paginator
            page={page}
            totalPages={data?.last_page}
            setPage={setPage}
          />
        </Box>

        <Box
          sx={{
            position: "fixed",
            top: "0",
            left: "0",
            width: "100%",
            height: "100%",
            bgcolor: "#00000075",
            zIndex: "1600",
            display: "flex",
            justifyContent: "center",
            // alignItems: 'center',
            transition: "0.4s",
            overflowY: "auto",
            visibility: openImage ? "visible" : "hidden",
            opacity: openImage ? 1 : 0,
          }}
        >
          <Box
            sx={{
              bgcolor: "white",
              padding: "20px",
              width: "auto",
              display: "flex",
              height: "60%",
              margin: "30px 0px",
              borderRadius: "5px",
              transition: "0.4s",
              visibility: openImage ? "visible" : "hidden",
              opacity: openImage ? 1 : 0,
              transform: openImage ? "scale(1)" : "scale(0)",
            }}
          >
            <div
              className="close-btn"
              style={{
                position: "absolute",
                top: -10,
                right: -80,
                zIndex: 100,
              }}
            >
              <Close
                onClick={closeImageBox}
                sx={{
                  width: 35,
                  height: 35,
                  transition: "0.4s",
                  cursor: "pointer",
                  padding: "2px 5px",
                  bgcolor: "white",
                  color: "black",
                  borderRadius: "50%",
                  "&:hover": {
                    color: COLORS.SECONDARY.MAIN,
                  },
                }}
              />
            </div>

            {imageBox && (
              <img
                className=""
                width={"100%"}
                src={`${api_url.slice(0, -7)}${imageBox}`}
                alt="صورة الحجز"
                style={{
                  borderRadius: "5px",
                  height: "100%",
                  objectFit: "contain",
                }}
              />
            )}
          </Box>
        </Box>
        <Box
          sx={{
            display: "flex",
            justifyContent: "start",
            alignItems: "center",
            mx: 4,
            my: 1,
          }}
        >
          <ColorButton
            sx={{
              color: "white",
              width: "20%",
            }}
            onClick={() => {
              mutate();
            }}
          >
            {excelLoading ? (
              <ClipLoader color="#000" size={20} />
            ) : lang == "ar" ? (
              "تحميل"
            ) : (
              "Download"
            )}
          </ColorButton>
        </Box>
        <TableContainer component={Paper}>
          <Table
            sx={{
              direction: lang == "ar" ? "rtl" : "ltr",
              color: "white",
              paddingBottom: "20px",
            }}
            aria-label="simple table"
          >
            <CustomTableHead
              headBackground={COLORS.PRIMARY.MAIN}
              tableHeaders={tableHeader}
            />
            <TableBody>
              {isPending ? (
                <>
                  <CustomTableLoading
                    width={"100%"}
                    height={60}
                    numOfCells={7}
                  />
                </>
              ) : (
                <>
                  {data?.data?.data?.length != 0 ? (
                    <>
                      {data?.data?.data?.map((item, i) => (
                        <TableRow
                          key={`a${i}sm`}
                          sx={{
                            backgroundColor: item.isConfirmed
                              ? globalState?.isDarkMode
                                ? COLORS.DARK_GRAY
                                : "#ccffd7"
                              : "",
                            "&:last-child td, &:last-child th": { border: 0 },
                            cursor: "pointer",
                          }}
                        >
                          <TableCell align="center" component="th" scope="row">
                            {item.name}
                          </TableCell>
                          <TableCell align="center">{item.phone}</TableCell>
                          <TableCell align="center">
                            {item.secondary_phone}
                          </TableCell>
                          <TableCell align="center">
                            {`${item.city},${item.address}`}
                          </TableCell>
                          <TableCell align="center">
                            {new Date().toDateString(item.created_at)}
                          </TableCell>
                          <TableCell align="center">{item.quantity}</TableCell>
                          <TableCell align="center">
                            {item.payment_method == "wallet"
                              ? lang == "ar"
                                ? "محفظة"
                                : "Wallet"
                              : lang == "ar"
                                ? "كاش"
                                : "Cash"}
                          </TableCell>
                          <TableCell
                            colSpan={9}
                            style={{ textAlign: "center", padding: "10px" }}
                          >
                            <ColorButton
                              type="submit"
                              // onClick={()=>{
                              //   currentDashboardUser()?.role === "Teacher" && setValue("teacher_id", teacher_id)
                              // }}
                              variant="contained"
                              sx={{ bgcolor: COLORS.PRIMARY.MAIN }}
                              onClick={() => {
                                console.log(item.id, item.isConfirmed);
                                confirmOrder({
                                  id: item.id,
                                  confirm: !item.isConfirmed,
                                });
                              }}
                            >
                              {item.isConfirmed
                                ? lang == "ar"
                                  ? "الغاء"
                                  : "Cancel"
                                : lang == "ar"
                                  ? "تأكيد"
                                  : "Confirm"}
                            </ColorButton>
                          </TableCell>
                        </TableRow>
                      ))}
                    </>
                  ) : (
                    <TableRow>
                      <TableCell
                        colSpan={9}
                        style={{ textAlign: "center", padding: "10px" }}
                      >
                        <Typography
                          fontFamily={lang === 'ar' ? "Noor-bold" : FONTS.ENGLISH}
                          fontSize={30}
                          color={"black"}
                          sx={{
                            fontWeight: 'bold',
                          }}
                        >
                          لا يوجد طلبات
                        </Typography>
                      </TableCell>
                    </TableRow>
                  )}
                </>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
    </>
  );
}
