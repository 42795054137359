import { COLORS } from "@/constants/Colors/colors";
import { api_url } from "@/constants/base_url";
import { Box, Typography } from "@mui/material";
import { notifyError } from "@shared/Toast/toast";
import React from "react";
import { useSelector } from "react-redux";
import fileStyle from "./UserCourseFile.module.css"
import fileIcon from "../../../../../assets/images/folderIcon.png"
import { FaAngleRight } from "react-icons/fa6";

export const UserCourseFile = ({ file, bought }) => {
  const globalState = useSelector((state) => state?.global);
  const lang = globalState?.lang;

  return (
    <a
      className={fileStyle.fileCard}
      onClick={(e) => {
        if (!bought) {
          e.preventDefault();
          notifyError(
            lang == "ar"
              ? "يجب شراء الكورس اولا"
              : "You must purchase the course first."
          );
        }
      }}
      target="blank"
      href={bought && `${file?.link}`}
    >
      <div className={fileStyle.icon}><img src={fileIcon} alt="" /></div>

      <div className={fileStyle.details}>
        <p>
          {file?.name?.length > 20
            ? file?.name?.slice(0, 20) + "..."
            : file?.name}
        </p>
        <FaAngleRight />
      </div>
    </a>
  );
};
