import { COLORS } from "@/constants/Colors/colors";
import { api_url } from "@/constants/base_url";
import { Box, Button, Typography } from "@mui/material";
import ColorButton from "@shared/Buttons/ColorButton/ColorButton";
import useCustomQuery from "@/hooks/useCustomQuery";
import React, { useState } from "react";
import { SubscribeBox } from "../SubscribeBox/SubscribeBox";
import { useSelector } from "react-redux";
import Row from "@shared/Layout/Row/Row";
import Column from "@shared/Layout/Column/Column";
import style from "./UserCourseHeader.module.css"

export const UserCourseHeader = ({ bought, course }) => {
  const globalState = useSelector((state) => state?.global);
  const lang = globalState?.lang;
  const isDarkMode = globalState?.isDarkMode;

  const [subBoxOpen, setSubBoxOpen] = useState(false);

  const isFree = (price) => {
    const numPrice = +price;

    if (numPrice == 0) {
      return true
    } else {
      return false
    }
  };

  console.log("!bought: ", !bought);
  console.log("price" , isFree(course?.price));

  return (
    <>
      <div className={style.container}>

        <div className={style.imageSection}>
          <div className={style.image}>
            <img src={`${api_url.slice(0, -7)}${course?.image}`} />
          </div>

          <h2>{course?.name}</h2>

          {!bought && (
            isFree(course?.price) ?
              <p className={style.free}>{lang === 'ar' ? "مجاناً" : "Free"}</p>
              :
              <p className={style.price}>{course?.price} {lang == "ar" ? "جنيها" : "EGP"}</p>
          )}

          {!bought && !isFree(course?.price) ? (
              <button type="button" onClick={() => setSubBoxOpen(true)} style={{ color: isDarkMode ? COLORS.BLACK : COLORS.WHITE }}>
                {lang == "ar" ? "اشترك الآن" : "Subscribe now"}
              </button>
          ) : ""}


        </div>

        <div className={style.contentSection}>

          <div className={style.subCard}>
            <p>{lang == "ar" ? "حصص" : "Lessons"}</p>
            <h3>{course?.modulesCount}</h3>
          </div>


          <div className={style.subCard}>
            <p>{lang === "ar" ? "ساعات" : "Hours"}</p>
            <h3>{course?.duration}</h3>
          </div>
        </div>


      </div>
      {subBoxOpen && <SubscribeBox setOpen={setSubBoxOpen} course={course} />}
    </>

  );
};

