import { COLORS } from "@/constants/Colors/colors";
import { Box, Button, Typography, IconButton, Drawer, List, ListItem, ListItemText } from "@mui/material";
import React, { useState, useEffect } from "react";
import logo from "@assets/images/logo.png";
import { Link, useNavigate, useMatch, useResolvedPath } from "react-router-dom";
import { currentUser } from "@shared/Helpers/encryptionHelpers";
import { UserAcc } from "./UserAcc/UserAcc";
import UserWallet from "./UserWallet/UserWallet";
import WalletIcon from "@mui/icons-material/Wallet";
import MenuIcon from "@mui/icons-material/Menu";
import { useSelector } from "react-redux";
import ModeTuggle from "@/modules/settings/components/ModeTuggle";
import LangTuggle from "@/modules/settings/components/LangTuggle/LangTuggle";
import { useMediaQuery, useTheme } from "@mui/material";
import navStyles from "./NavBar.module.css"
import { IoWalletOutline } from "react-icons/io5";
import { FONTS } from "@/constants/Fonts/fonts";
import { IoMenu } from "react-icons/io5";
import { Remove } from './UserAcc/Remove';
import { Logout } from './UserAcc/Logout';


export const NavBar = () => {
  const navigate = useNavigate();
  const [menuOpen, setMenuOpen] = useState(false);

  const [walletOpen, setWalletOpen] = useState(false);

  const [delAccBox, setDelAccBox] = useState(false);
  const [logoutBox, setLogoutBox] = useState(false);


  const [drawerOpen, setDrawerOpen] = useState(false);


  const globalState = useSelector((state) => state?.global);
  const lang = globalState?.lang;
  const isDarkMode = globalState?.isDarkMode;



  const openWallet = () => {
    setWalletOpen(true);
  };
  const openLogout = () => {
    setLogoutBox(true);
  };

  const closeLogout = () => {
    setLogoutBox(false);
  };


  const openDelAcc = () => {
    setDelAccBox(true);
  };

  const closeDelAcc = () => {
    setDelAccBox(false);
  };

  const userData = currentUser();

  useEffect(() => {
    if (userData?.role === "Admin") {
      navigate("/dashboard");
      scrollTo(0, 0);
    } else if (!userData) {
      navigate("/");
    }
  }, [userData, navigate]);

  const toggleDrawer = (open) => (event) => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }
    setDrawerOpen(open);
  };


  const handleToggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  const handleCloseMenu = () => {
    setMenuOpen(false);
  };

  const handleNavigation = (path) => {
    navigate(path);
    handleCloseMenu();
  };


  return (
    <header className={navStyles.header} style={{
      direction: lang === 'ar' ? 'rtl' : 'ltr',
      fontFamily: lang === 'ar' ? "" : FONTS.ENGLISH,
      backgroundColor: isDarkMode ? COLORS.SOFT_DARK : COLORS.WHITE
    }}>
      <div className={navStyles.logo}>
        <img src={logo} alt="" />
      </div>


      <nav>
        <ul className={menuOpen ? navStyles.open : ''} style={{ backgroundColor: isDarkMode ? COLORS.SOFT_DARK : COLORS.WHITE }}>
          <CustomLink to="/home" onClick={handleCloseMenu}>{lang === 'ar' ? "الرئيسية" : "Home"}</CustomLink>
          <CustomLink to="/my-subscriptions" onClick={handleCloseMenu}>{lang === "ar" ? " اشتراكاتي" : "Subscriptions"}</CustomLink>
          <CustomLink to="/my-stats" onClick={handleCloseMenu}>{lang === "ar" ? "انجازاتي" : "Achievements"}</CustomLink>
          <CustomLink to="/book-store" onClick={handleCloseMenu}>{lang === "ar" ? "متجر الكتب" : "Book Store"}</CustomLink>
          
          <li className={navStyles.wallet} onClick={() => { handleCloseMenu(); openWallet(); }}>{lang === "ar" ? "محفظتي" : "My Wallet"}</li>
          <li className={navStyles.accountDetails} onClick={() => { handleCloseMenu(); navigate("/my-details"); }}>{lang == "ar" ? "بيانات الحساب" : "Account Details"}</li>
          <li className={navStyles.logout} onClick={() => { handleCloseMenu(); openLogout(); }}>{lang == "ar" ? "تسجيل الخروج" : "Logout"}</li>
        </ul>
      </nav>


      <div className={navStyles.rightSection}>
        <div className={`${navStyles.wallet} ${navStyles.btn}`} style={{ color: COLORS.WHITE }}
          onClick={() => { openWallet(); }}>
          <IoWalletOutline />
          {lang == "ar" ? "المحفظة" : "Wallet"}
        </div>

        <div className={navStyles.profile} style={{ borderColor: isDarkMode ? COLORS.DARK_GRAY : "" }}>
          <UserAcc openLogout={openLogout} openDelAcc={openDelAcc} />
        </div>
        <div className={navStyles.moods}>
          <ModeTuggle />
          <LangTuggle />
          <IoMenu onClick={handleToggleMenu} />
        </div>
      </div>

      {walletOpen && <UserWallet open={walletOpen} setOpen={setWalletOpen} />}

      {logoutBox && <Logout open={logoutBox} setOpen={setLogoutBox} />}
      {delAccBox && <Remove open={delAccBox} setOpen={setDelAccBox} />}
    </header>
  );
};


const CustomLink = ({ to, onClick, children }) => {
  const resolvedPath = useResolvedPath(to);
  const isActive = useMatch({ path: resolvedPath.pathname })
  return (
    <Link to={to} onClick={onClick}>
      <li className={isActive ? navStyles.active : ""}>{children}</li>
    </Link>
  )
}
