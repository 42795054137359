import { ClipLoader } from "react-spinners";
import { ColorButton } from "../ColorButton";
import { useSelector } from 'react-redux';
import { FONTS } from "@/constants/Fonts/fonts";
import { COLORS } from "@/constants/Colors/colors";

export const ConfirmSwapBtn = ({ state, isLoading, swapFn }) => {
  const language = useSelector((state) => state?.global);
  const lang = language?.lang;
  return (
    state?.first &&
    state?.second && (
      <ColorButton disabled={isLoading} onClick={swapFn} sx={{backgroundColor: COLORS.SECONDARY.MAIN}}>
        <p style={{ fontFamily: lang === 'en' ? FONTS.ENGLISH : "", padding: "0 30px" }}>
          {isLoading ? (
            <ClipLoader color="#fff" size={20} />
          ) : lang == "ar" ? (
            "تأكيد"
          ) : (
            "Confirm"
          )}
        </p>
      </ColorButton>
    )
  );
};
