import Modal from "@shared/Modal/Modal";
import React, { useState } from "react";
import {
  TextField,
  Button,
  Typography,
  Checkbox,
  FormControlLabel,
  InputAdornment,
  Box,
  RadioGroup,
  Radio,
  Input,
} from "@mui/material";
import { COLORS } from "@/constants/Colors/colors";
import useCustomMutation from "@/hooks/useCustomMutation";
import { useQueryClient } from "@tanstack/react-query";
import { notifySuccess } from "@shared/Toast/toast";
import { HashLoader } from "react-spinners";
import dayjs from "dayjs";
import { ExamImagePicker } from "./ExamImagePicker/ExamImagePicker";
import { useSelector } from 'react-redux';
import style from "./ExamCreator.module.css"
import Form from "@shared/Form/Form";

export const ExamCreator = ({ open, setOpen, id, closeParent, type }) => {
  const lang = useSelector((state) => state?.global?.lang);
  const queryClient = useQueryClient();
  const { mutate, isPending } = useCustomMutation({
    url: `exam/createExam`,
    lang,
    onSuccess: () => {
      notifySuccess(
        lang == "ar"
          ? "تم إضافة إمتحان بنجاح"
          : "The Exam has been Added successfully "
      );
      queryClient.invalidateQueries(["module exam", "exam of course", id]);
      setOpen(false);
      closeParent();
    },
  });

  const [exam, setExam] = useState({
    title: "",
    starts_at: "",
    ends_at: "",
    exam_time: "",
    isPrerequsite: 1,
    questions: [
      {
        grade: "",
        answers: [
          {
            title: "",
            isCorrect: 1,
          },
          {
            title: "",
            isCorrect: 0,
          },
        ],
      },
    ],
  });
  const [submitted, setSubmitted] = useState(false);

  const handleRadioChange = (questionIndex, answerIndex) => {
    const updatedExam = { ...exam };
    updatedExam.questions[questionIndex].answers.forEach((answer, index) => {
      answer.isCorrect = index === answerIndex ? 1 : 0;
    });
    setExam(updatedExam);
  };

  const addAnswer = (index) => {
    let temp = exam.questions;
    temp[index].answers.push({ title: "", isCorrect: 0 });
    setExam({ ...exam, questions: temp });
  };

  const removeAnswer = (index) => {
    let temp = exam.questions;
    if (temp[index].answers[temp[index].answers.length - 1].isCorrect == 1) {
      temp[index].answers[temp[index].answers.length - 2].isCorrect = 1;
    }
    temp[index].answers = temp[index].answers.slice(0, -1);
    setExam({ ...exam, questions: temp });
  };

  const addQuestion = () => {
    let temp = exam.questions;
    temp.push({
      // title:"",
      grade: "",
      // image:null,
      answers: [
        {
          title: "",
          isCorrect: 1,
        },
        {
          title: "",
          isCorrect: 0,
        },
      ],
    });
    setExam({ ...exam, questions: temp });
  };

  const removeQuestion = () => {
    let temp = exam.questions;
    temp = temp.slice(0, -1);
    setExam({ ...exam, questions: temp });
  };

  const submit = () => {
    let examData = exam;
    if (!examData.title) return;
    if (
      !examData.starts_at ||
      new Date(new Date().setMinutes(new Date().getMinutes() + 60)) >=
      new Date(examData.starts_at)
    )
      return;
    examData.starts_at = dayjs(examData.starts_at).format(
      "YYYY-MM-DD hh:mm:ss A"
    );
    if (
      !examData.ends_at ||
      new Date(examData.ends_at) <= new Date(examData.starts_at)
    )
      return;
    examData.ends_at = dayjs(examData.ends_at).format("YYYY-MM-DD hh:mm:ss A");
    if (!examData.exam_time) return;
    for (let i = 0; i < examData.questions.length; i++) {
      if (!examData.questions[i].title && !examData.questions[i].image) return;
      if (examData.questions[i].image) {
        examData.questions[i].image = examData.questions[i].image[0];
      }
      if (!examData.questions[i].grade) return;
      for (let j = 0; j < examData.questions[i].answers.length; j++) {
        if (!examData.questions[i].answers[j].title) return;
      }
    }

    const reqFormData = new FormData();
    reqFormData.append("title", examData?.title);
    reqFormData.append("starts_at", examData?.starts_at);
    reqFormData.append("ends_at", examData?.ends_at);
    reqFormData.append("exam_time", examData?.exam_time);
    type === "Lesson" && reqFormData.append("lesson_module_id", id);
    type === "Course" && reqFormData.append("course_id", id);
    reqFormData.append("isPrerequsite", String(examData.isPrerequsite));
    reqFormData.append("type", type);
    for (let i = 0; i < examData.questions.length; i++) {
      if (examData.questions[i]?.title) {
        reqFormData.append(
          `questions[${i}][title]`,
          examData.questions[i].title
        );
      }
      reqFormData.append(`questions[${i}][grade]`, examData.questions[i].grade);
      console.log(examData.questions[i].image);
      if (examData.questions[i]?.image) {
        reqFormData.append(
          `questions[${i}][image]`,
          examData.questions[i].image
        );
      }
      for (let j = 0; j < examData.questions[i].answers.length; j++) {
        reqFormData.append(
          `questions[${i}][answers][${j}][title]`,
          examData.questions[i].answers[j].title
        );
        reqFormData.append(
          `questions[${i}][answers][${j}][isCorrect]`,
          +examData.questions[i].answers[j].isCorrect
        );
      }
    }
    console.log(examData);
    mutate(reqFormData);
  };

  return (
    <Modal open={open} closeFn={() => setOpen(false)}>
      <Form title={lang === 'ar' ? "اضف اسئلة الامتحان للكورس" : "Add Course Exam Questions"}>
        <Form.TextController
          placeholder={lang == "ar" ? "العنوان" : "Title"}
          value={exam.title}
          onChange={(e) => {
            setExam({ ...exam, title: e.target.value });
          }}
          error={!exam.title && submitted}
          helperText={
            !exam.title && submitted && lang == "ar"
              ? "يجب إضافة عنوان للإمتحان"
              : "You should add title for exam"
          }
          submitted={submitted}
        />

        <div className={style.subcontainer}>
          <div>
            <h3>{lang === 'ar' ? "تاريخ البدء" : "Start date"}</h3>
            <Form.TextController
              placeholder={lang == "ar" ? "تاريخ البدء" : "Start date"}
              type="datetime-local"
              value={exam?.starts_at}
              onChange={(e) => {
                setExam({ ...exam, starts_at: e.target.value });
              }}
              error={
                (!exam.starts_at && submitted) ||
                (new Date(new Date().setMinutes(new Date().getMinutes() + 60)) >=
                  new Date(exam.starts_at) &&
                  submitted)
              }
              helperText={(() => {
                if (!exam.starts_at && submitted) {
                  return lang == "ar"
                    ? "يجب إضافة تاريخ البدء"
                    : "Add start date";
                }
                if (
                  new Date(new Date().setMinutes(new Date().getMinutes() + 60)) >=
                  new Date(exam.starts_at) &&
                  submitted
                ) {
                  return lang == "ar"
                    ? "يجب إختيار تاريخ علي الاقل بعد ساعة"
                    : "You must choose a success date after the hour.";
                } else {
                  return;
                }
              })()}
              submitted={submitted}
            />
          </div>

          <div>
            <h3>{lang === 'ar' ? "تاريخ الإنتهاء" : "End date"}</h3>
            <Form.TextController
              placeholder={lang == "ar" ? "تاريخ الإنتهاء" : "End date"}
              type="datetime-local"
              value={exam?.ends_at}
              onChange={(e) => {
                setExam({ ...exam, ends_at: e.target.value });
              }}
              error={
                (!exam.ends_at && submitted) ||
                (new Date(exam.ends_at) <= new Date(exam.starts_at) && submitted)
              }
              helperText={(() => {
                if (!exam.ends_at && submitted) {
                  return lang == "ar"
                    ? "يجب إضافة تاريخ الإنتهاء"
                    : "You should add end date";
                }
                if (
                  new Date(exam.ends_at) <= new Date(exam.starts_at) &&
                  submitted
                ) {
                  return lang == "ar"
                    ? "يجب إختيار تاريخ بعد تاريخ البدء"
                    : "You must choose a date after the start date.";
                } else {
                  return;
                }
              })()}
              submitted={submitted}
            />
          </div>
        </div>

        <div className={style.subcontainer}>
          <Form.TextController
            placeholder={lang == "ar" ? "الزمن بالدقائق" : "Time in minutes"}
            type="number"
            value={exam.exam_time}
            onChange={(e) => {
              e.target.value > 0 &&
                setExam({ ...exam, exam_time: e.target.value });
            }}
            error={!exam.exam_time && submitted}
            helperText={
              !exam.exam_time && submitted && lang == "ar"
                ? "يجب تحديد زمن الامتحان"
                : "The exam time must be determined."
            }
            submitted={submitted}
          />

          <Form.CheckController
            label={
              lang == "ar"
                ? "يجب اجتياز امتحان الحصة السابقة"
                : "You must pass the previous semester exam."
            }
            checked
            onChange={(e) => {
              setExam({ ...exam, isPrerequsite: e?.target.checked ? 1 : 0 });
            }}
          />
        </div>

        <Form.SubTitle>{lang == "ar" ? "الأسئلة" : "Questions"}</Form.SubTitle>
        {exam?.questions.map((_, index) => (
          <Box
            key={index}
            fullWidth
            sx={{ display: "flex", flexDirection: "column", gap: 2, marginTop: '20px' }}
          >
            <Typography
              sx={{
                bgcolor: COLORS.SECONDARY.MAIN,
                width: 30,
                height: 30,
                color: COLORS.WHITE,
                borderRadius: "50%",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              {index + 1}
            </Typography>
            <TextField
              label={lang == "ar" ? "عنوان السؤال" : "Question title"}
              fullWidth
              type="text"
              value={exam.questions[index].title}
              onChange={(e) => {
                let temp = exam.questions;
                temp[index].title = e.target.value;
                setExam({ ...exam, questions: temp });
              }}
              error={
                !exam.questions[index].title &&
                !exam.questions[index].image &&
                submitted
              }
              helperText={
                !exam.questions[index].title &&
                  !exam.questions[index].image &&
                  submitted &&
                  lang == "ar"
                  ? "يجب إضافة عنوان للسؤال في حالة عدم إضافة صورة"
                  : "You must add a title to the question if you do not add an image."
              }
              sx={{
                "& .MuiFormLabel-root.Mui-focused": {
                  color: COLORS.SECONDARY.MAIN,
                },
                "& label": {
                  left: lang == "ar" ? "unset" : "0",
                  right: lang == "ar" ? "1.75rem" : "unset",
                  transformOrigin: lang == "ar" ? "right" : "left",
                  fontSize: "0.8rem",
                  color: COLORS.SECONDARY.MAIN,
                },
                "& legend": {
                  textAlign: lang == "ar" ? "right" : "left",
                  fontSize: "0.6rem",
                },
                direction: "rtl",
              }}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="end"></InputAdornment>
                ),
              }}
            />
            <ExamImagePicker exam={exam} setExam={setExam} index={index} />
            <TextField
              type="number"
              value={exam.questions[index].grade}
              onChange={(e) => {
                if (e.target.value > 0) {
                  let temp = exam.questions;
                  temp[index].grade = e.target.value;
                  setExam({ ...exam, questions: temp });
                }
              }}
              label={lang == "ar" ? "درجة السؤال" : "Question score"}
              fullWidth
              error={!exam.questions[index].grade && submitted}
              helperText={
                !exam.questions[index].grade && submitted && lang == "ar"
                  ? "يجب تحديد درجة السؤال"
                  : "The question must be graded"
              }
              sx={{
                "& .MuiFormLabel-root.Mui-focused": {
                  color: COLORS.SECONDARY.MAIN,
                },
                "& label": {
                  left: lang == "ar" ? "unset" : "0",
                  right: lang == "ar" ? "1.75rem" : "unset",
                  transformOrigin: lang == "ar" ? "right" : "left",
                  fontSize: "0.8rem",
                  color: COLORS.SECONDARY.MAIN,
                },
                "& legend": {
                  textAlign: lang == "ar" ? "right" : "left",
                  fontSize: "0.6rem",
                },
                direction: "rtl",
              }}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="end"></InputAdornment>
                ),
              }}
            />
            <RadioGroup
              sx={{ display: "flex", flexDirection: "column", gap: 2 }}
              value={exam.questions[index].answers.findIndex(
                (x) => x.isCorrect == 1
              )}
            >
              {exam.questions[index].answers.map((answer, i) => (
                <Box
                  key={i}
                  sx={{ display: "flex", alignItems: "center", gap: 2 }}
                >
                  <TextField
                    label={`${lang == "ar" ? "إجابة" : "answer"} ${i + 1}`}
                    type="text"
                    value={answer.title}
                    onChange={(e) => {
                      let temp = exam.questions;
                      temp[index].answers[i].title = e.target.value;
                      setExam({ ...exam, questions: temp });
                    }}
                    error={!answer.title && submitted}
                    helperText={
                      !answer.title && submitted && lang == "ar"
                        ? "يجب إضافة نص اللإجابة"
                        : "You must add the answer text"
                    }
                    sx={{
                      "& .MuiFormLabel-root.Mui-focused": {
                        color: COLORS.SECONDARY.MAIN,
                      },
                      "& label": {
                        left: lang == "ar" ? "unset" : "0",
                        right: lang == "ar" ? "1.75rem" : "unset",
                        transformOrigin: lang == "ar" ? "right" : "left",
                        fontSize: "0.8rem",
                        color: COLORS.SECONDARY.MAIN,
                      },
                      "& legend": {
                        textAlign: lang == "ar" ? "right" : "left",
                        fontSize: "0.6rem",
                      },
                      direction: "rtl",
                    }}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="end"></InputAdornment>
                      ),
                    }}
                  />
                  <Radio
                    value={answer.isCorrect.toString()}
                    checked={answer.isCorrect === 1}
                    onChange={() => handleRadioChange(index, i)}
                  />
                </Box>
              ))}
              <Box sx={{ display: "flex", alignItems: "center", gap: 2 }}>
                {exam.questions[index].answers.length < 4 && (
                  <Button
                    sx={{
                      bgcolor: COLORS.SECONDARY.MAIN,
                      color: COLORS.WHITE,
                      "&:hover": { bgcolor: COLORS.SECONDARY.MAIN },
                    }}
                    onClick={() => addAnswer(index)}
                  >
                    {lang == "ar" ? "أضف إجابة" : "Add answer"}
                  </Button>
                )}
                {exam.questions[index].answers.length > 2 && (
                  <Button
                    sx={{
                      bgcolor: COLORS.SECONDARY.MAIN,
                      color: COLORS.WHITE,
                      "&:hover": { bgcolor: COLORS.SECONDARY.MAIN },
                    }}
                    onClick={() => removeAnswer(index)}
                  >
                    {lang == "ar" ? "إحذف إجابة" : "Delete answer"}
                  </Button>
                )}
              </Box>
            </RadioGroup>
          </Box>
        ))}
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            gap: 2,
          }}
        >
          {
            <Button
              sx={{
                bgcolor: COLORS.SECONDARY.MAIN,
                color: COLORS.WHITE,
                "&:hover": { bgcolor: COLORS.SECONDARY.MAIN },
              }}
              onClick={addQuestion}
            >
              {lang == "ar" ? "أضف سؤال" : "Add question"}
            </Button>
          }
          {exam.questions.length > 1 && (
            <Button
              sx={{
                bgcolor: COLORS.SECONDARY.MAIN,
                color: COLORS.WHITE,
                "&:hover": { bgcolor: COLORS.SECONDARY.MAIN },
              }}
              onClick={removeQuestion}
            >
              {lang == "ar" ? "إحذف سؤال" : "Delete question"}
            </Button>
          )}
        </Box>
        <Button
          disabled={isPending}
          sx={{
            bgcolor: COLORS.SECONDARY.MAIN,
            color: COLORS.WHITE,
            "&:hover": { bgcolor: COLORS.SECONDARY.MAIN },
          }}
          onClick={() => {
            console.log(exam);
            setSubmitted(true);
            submit();
          }}
        >
          {isPending ? (
            <HashLoader size={20} color={COLORS.WHITE} />
          ) : lang == "ar" ? (
            "إنشاء"
          ) : (
            "Create"
          )}
        </Button>

      </Form>
    </Modal>
  );
};
