import { Box, IconButton, TableBody, TableCell, TableRow } from "@mui/material";
import { useState } from "react";
import { ROLES } from "@/constants/roles";
import { RemoveRedEye } from "@mui/icons-material";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import useLocalStorageToken from "@shared/Helpers/axios/useLocalStorageToken";
import DeleteSupervisor from "@/modules/supervisor/components/DeleteSupervisor";
import UpdateSupervisor from "@/modules/supervisor/components/UpdateSupervisor";
import SupervisorTeacher from "@/modules/supervisor/pages/SupervisorTeacher";
import { useSelector } from "react-redux";

export default function CustomTableRowSupervisor({
  refetch,
  id,
  number,
  name,
  phone,
  role,
  password,
}) {
  const User = useLocalStorageToken();
  const lang = useSelector((state) => state?.global?.lang);

  const [noteBook, setNoteBook] = useState(false);
  const [supervisorId, setSupervisorId] = useState();

  const openNote = (note) => {
    setNoteBook(note);
  };

  const closeNote = () => {
    setNoteBook("");
  };

  const getSupervisorId = (id) => {
    setSupervisorId(id);
    // console.log(id);
  };

  return (
    <>
      <Box display={"flex"}>
        <DeleteSupervisor
          supervisorId={supervisorId}
          closeNote={closeNote}
          noteBook={noteBook}
          refetch={refetch}
        />
        <UpdateSupervisor
          closeNote={closeNote}
          noteBook={noteBook}
          refetch={refetch}
          name={name}
          supervisorId={supervisorId}
        />
        <SupervisorTeacher
          closeNote={closeNote}
          noteBook={noteBook}
          refetch={refetch}
          name={name}
          supervisorId={supervisorId}
        />
      </Box>
      <TableBody>
        <TableRow >
          <TableCell align="center" component="th" scope="row">
            {number}
          </TableCell>
          <TableCell align="center">{name}</TableCell>
          <TableCell align="center">{phone || "-"}</TableCell>
          <TableCell align="center">{role}</TableCell>
          <TableCell align="center">
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              {ROLES.ADMIN === User?.role && (
                <>
                  <IconButton
                    onClick={() => {
                      openNote("del-book");
                      getSupervisorId(id);
                    }}
                    className="settingIcon"
                    aria-label="delete"
                    size="small"
                    sx={{
                      bgcolor: "#db3a3c",
                      position: "relative",
                      "&:hover": {
                        bgcolor: "#db3a3c",
                      },
                    }}
                  >
                    <span className="settingHover">
                      {lang == "ar" ? "حذف" : "Delete"}
                    </span>
                    <DeleteIcon fontSize="inherit" sx={{ color: "#fff" }} />
                  </IconButton>
                  <IconButton
                    className="settingIcon"
                    aria-label="delete"
                    size="small"
                    onClick={() => {
                      openNote("update-supervisor");
                      getSupervisorId(id);
                    }}
                    sx={{
                      bgcolor: "#3adb44",
                      marginRight: "10px",
                      "&:hover": {
                        bgcolor: "#3adb44",
                      },
                    }}
                  >
                    <span className="settingHover">
                      {lang == "ar" ? "تعديل" : "Edit"}
                    </span>
                    <EditIcon fontSize="inherit" sx={{ color: "#fff" }} />
                  </IconButton>
                </>
              )}

              <IconButton
                className="settingIcon"
                onClick={() => {
                  openNote("teachers");
                  getSupervisorId(id);
                }}
                size="small"
                sx={{
                  bgcolor: "black",
                  marginRight: "10px",
                  "&:hover": {
                    bgcolor: "black",
                  },
                }}
              >
                <span className="settingHover">
                  {lang == "ar" ? "المدرسين" : "Teachers"}
                </span>
                <RemoveRedEye fontSize="inherit" sx={{ color: "#fff" }} />
              </IconButton>
            </Box>
          </TableCell>
        </TableRow>
      </TableBody>
    </>
  );
}
