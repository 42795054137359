import { api_url } from "@/constants/base_url";
import { Close } from "@mui/icons-material";
import { Box, Button, Typography } from "@mui/material";
import { currentUser } from "@shared/Helpers/encryptionHelpers";
import { notifyError, notifySuccess } from "@shared/Toast/toast";
import { useMutation } from "@tanstack/react-query";
import axios from "axios";
import React from "react";
import HashLoader from "react-spinners/HashLoader";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import { COLORS } from "@/constants/Colors/colors";
import { FONTS } from "@/constants/Fonts/fonts";

function DeleteSubscribtion({
  closeSubNote,
  noteSubscribe,
  subscribtionId,
  refetch,
}) {
 const lang = useSelector((state) => state?.global?.lang);
  const globalState = useSelector((state) => state?.global);
  const { mutate, isPending, error, isError, status } = useMutation({
    mutationFn: (subscribtionId) =>
      axios.delete(
        `${api_url}subscription/deleteUserSubscriptions?subscription_id=${subscribtionId}`,
        {
          headers: {
            // 'Content-Type': 'application/json',
            Authorization: `Bearer ${currentUser()?.token}`,
            lang: lang,
          },
        }
      ),
    onSuccess: (res) => {
      console.log(res);
      closeSubNote();
      refetch();
      notifySuccess(
        lang == "ar"
          ? "تم حذف اشتراك الطالب من هذا الكورس"
          : "The student's subscription has been deleted from this course"
      );
    },
    onError: (error) => {
      console.log(error);
      notifyError(
        lang == "ar"
          ? "حدث خطأ اثناء تنفيذ المهمة"
          : "An error occurred while executing the task"
      );
    },
  });
  return (
    <>
      <Box
        sx={{
          position: "fixed",
          top: "0",
          left: "0",
          width: "100%",
          height: "100%",
          bgcolor: "#000000ab",
          zIndex: "1501",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          transition: "0.3s",
          opacity: noteSubscribe === "subscribtion" ? "1" : "0",
          visibility: noteSubscribe === "subscribtion" ? "visible" : "hidden",
        }}
      >
        <Box
          sx={{
            bgcolor: globalState?.isDarkMode ? COLORS.DARK_GRAY : "white",
            padding: "40px 30px",
            borderRadius: "10px",
            width: "450px",
            height: "auto",
            textAlign: "center",
            position: "relative",
            transition: "0.3s",
            opacity: noteSubscribe === "subscribtion" ? "1" : "0",
            transform:
              noteSubscribe === "subscribtion" ? "scale(1)" : "scale(0)",
          }}
        >
          <div
            className="close-btn"
            style={{
              position: "absolute",
              top: 10,
              right: 10,
              zIndex: 100,
            }}
          >
            <Close
              onClick={closeSubNote}
              sx={{
                color: "black",
                width: 30,
                height: 30,
                transition: "0.4s",
                cursor: "pointer",
                "&:hover": {
                  color: "#2f8bc8",
                },
              }}
            />
          </div>

          <Typography
            fontFamily={lang === 'ar' ? "Noor-bold" : FONTS.ENGLISH}
            fontSize={30}
            sx={{ fontWeight: "bold" }}
          >
            {lang == "ar"
              ? " هل تريد حذف اشتراك الطالب من هذا الكورس"
              : "Do you want to delete the student's subscription from this course?"}
          </Typography>

          <Box
            mt={5}
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alidngItems: "center",
            }}
          >
            <Button
              onClick={() => mutate(subscribtionId)}
              sx={{
                padding: "7px 20px",
                width: "49%",
                bgcolor: "darkred",
                color: "white",
                trnsition: "0.4s",
                "&:hover": {
                  bgcolor: "red",
                },
              }}
            >
              {isPending ? (
                <HashLoader color="#ffffff" size={24} />
              ) : lang == "ar" ? (
                "أوافق"
              ) : (
                "Accept"
              )}
            </Button>
            <Button
              onClick={closeSubNote}
              sx={{
                padding: "7px 20px",
                width: "49%",
                bgcolor: "gray",
                color: "white",
                trnsition: "0.4s",
                "&:hover": {
                  bgcolor: "lightgray",
                },
              }}
            >
              {lang == "ar" ? " الغاء" : "Cancel"}
            </Button>
          </Box>
        </Box>
      </Box>
    </>
  );
}

DeleteSubscribtion.propTypes = {
  refetch: PropTypes.func,
  noteSubscribe: PropTypes.bool,
  closeSubNote: PropTypes.func,
  subscribtionId: PropTypes.number,
};

export default DeleteSubscribtion;
