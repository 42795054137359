import {
  Box,
  FormControl,
  IconButton,
  InputAdornment,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useMutation } from "@tanstack/react-query";
import { api_url } from "@/constants/base_url";
import axios from "axios";
import { Close, Visibility, VisibilityOff } from "@mui/icons-material";
import { Controller, FormProvider, useForm } from "react-hook-form";
import { notifyError, notifySuccess } from "@shared/Toast/toast";
import { COLORS } from "@/constants/Colors/colors";
import ColorButton from "@shared/Buttons/ColorButton/ColorButton";
import { zodResolver } from "@hookform/resolvers/zod";
import { currentUser } from "@shared/Helpers/encryptionHelpers";
import { updateStudentSchema } from "../../../shema/updateStudent.schema";
import { governorates } from "../../../hooks/governorates";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import { FONTS } from "@/constants/Fonts/fonts";
export default function UpdateStudent({
  noteBook,
  closeNote,
  refetch,
  name,
  city,
  id,
}) {
  const [showPassword, setShowPassword] = React.useState(false);
  const globalState = useSelector((state) => state?.global);
  const lang = useSelector((state) => state?.global?.lang);

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const methods = useForm({
    resolver: zodResolver(updateStudentSchema),
  });

  const { mutate, isPending, error, isError } = useMutation({
    mutationFn: (formData) =>
      axios.post(`${api_url}users/update`, formData, {
        headers: {
          // 'Content-Type': 'application/json',
          Authorization: `Bearer ${currentUser()?.token}`,
          lang: lang,
        },
      }),
    onSuccess: (data) => {
      console.log(data);
      refetch();
      notifySuccess(
        lang == "ar"
          ? "تم تعديل بيانات الطالب"
          : "Student data has been modified"
      );
      reset();
      closeNote();
    },
    onError: (error) => {
      console.log(error);
      notifyError(
        lang == "ar"
          ? "حدث خطأ اثناء تنفيذ المهمة"
          : "An error occurred while executing the task"
      );
    },
  });

  const {
    register,
    setValue,
    handleSubmit,
    formState,
    clearErrors,
    getValues,
    control,
    reset,
  } = useForm({
    defaultValues: {
      name: name || "",
      password: "",
      city: "",
    },
  });

  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const onSubmit = () => {
    const { name, password, city } = getValues();
    const formData = {
      name: name,
      password: password.toString(),
      city: city,
      id: id,
    };

    console.log(`form`, formData);
    mutate(formData);
  };

  return (
    <div
      className=""
      style={{
        position: "fixed",
        top: "0",
        left: "0",
        background: "#000000ab",
        width: "100%",
        height: "100vh",
        zIndex: "1300",
        display: "flex",
        // justifyContent: 'center',
        padding: "80px 0px",
        alignItems: "center",
        justifyContent: "center",
        flexDirection: "column",
        transition: "0.3s",
        scrollbarColor: COLORS.PRIMARY.MAIN,
        scrollbarWidth: "thin",
        overflow: "hidden",
        opacity: noteBook === "update-student" ? 1 : 0,
        visibility: noteBook === "update-student" ? "visible" : "hidden",
      }}
    >
      <Box
        className="device-box"
        px={3}
        sx={{
          padding: "20px 30px",
          background: globalState?.isDarkMode ? COLORS.DARK_GRAY : "#F5F5F5",
          borderRadius: "10px",
          width: "80%",
          height: "auto",
          // overflowY: 'auto',
          display: "flex",
          flexDirection: "column",
          transition: "0.3s",
          opacity: noteBook === "update-student" ? 1 : 0,
          transform: noteBook === "update-student" ? "scale(1)" : "scale(0)",
        }}
      >
        <div
          className="close-btn"
          style={{
            position: "absolute",
            top: 5,
            right: 5,
            zIndex: 100,
          }}
        >
          <Close
            onClick={() => {
              closeNote();
              // reset();
            }}
            sx={{
              color: "white",
              padding: "10px 10px",
              width: "40px",
              height: "40px",
              borderRadius: "5px",
              transition: "0.4s",
              bgcolor: "primary.main",
              cursor: "pointer",
              "&:hover": {
                bgcolor: "white",
                color: COLORS.PRIMARY.MAIN,
              },
            }}
          />
        </div>
        <Typography
          fontFamily={lang === 'ar' ? "Noor-bold" : FONTS.ENGLISH}
          mb={6}
          mt={4}
          fontSize={32}
          sx={{ fontWeight: "bold", textAlign: "center" }}
        >
          {lang == "ar" ? "تعديل بيانات الطالب" : "Edit student data"}
        </Typography>

        <FormProvider {...methods}>
          <form onSubmit={handleSubmit(onSubmit)}>
            <Box sx={{ marginY: "20px" }}>
              <InputLabel
                // htmlFor="outlined-adornment-password"
                sx={{
                  left: lang == "ar" ? "-1.5rem" : "unset",
                  right: lang == "ar" ? "unset" : "-1.5rem",
                  top: "0.50rem",
                  transformOrigin: "right",
                  fontSize: "1.rem",
                  color: globalState?.isDarkMode ? "#F5F5F5" : COLORS.DARK_GRAY,
                  backgroundColor: !globalState?.isDarkMode
                    ? "#F5F5F5"
                    : COLORS.DARK_GRAY,
                  borderRadius: "5px",
                  zIndex: "10",
                  width: "50px",
                  textAlign: 'center'
                }}
              >
                {lang == "ar" ? "الاسم" : "Name"}
              </InputLabel>
              <TextField
                inputProps={{
                  style: {
                    WebkitTextFillColor: globalState?.isDarkMode
                      ? COLORS.WHITE
                      : COLORS.DARK_GRAY,
                    color: globalState?.isDarkMode
                      ? COLORS.WHITE
                      : COLORS.DARK_GRAY,
                  },
                }}
                // {...methods.register("name")}
                // onChange={(e) => setValue(e.target.value)}
                {...register("name", {
                  required: lang == "ar" ? "هذا الحقل مطلوب" : "Required",
                })}
                registername="name"
                placeholder={
                  lang == "ar" ? "ادخل الاسم بالكامل" : "Enter full name"
                }
                defaultValue={name}
                size="small"
                fullWidth
                sx={{
                  "& .MuiFormLabel-root.Mui-focused": {
                    color: globalState?.isDarkMode
                      ? COLORS.WHITE
                      : COLORS.DARK_GRAY,
                  },
                  "& label": {
                    left: lang == "ar" ? "unset" : "0",
                    right: lang == "ar" ? "1.75rem" : "unset",
                    transformOrigin: "right",
                    fontSize: "0.8rem",
                    color: "#2f8bc8",
                  },
                  "& legend": {
                    textAlign: lang == "ar" ? "right" : "left",
                    fontSize: "0.8rem",
                  },
                  direction: lang == "ar" ? "rtl" : "ltr",
                }}
                InputProps={{
                  startAdornment: (
                    <InputAdornment
                      position={lang == "ar" ? "end" : "start"}
                    ></InputAdornment>
                  ),
                }}
              />
              <span
                style={{
                  color: "red",
                  fontSize: "14px",
                  my: "0px",
                  textAlign: "end",
                }}
              >
                {formState.errors.name?.message}
              </span>
            </Box>

            <FormControl fullWidth variant="outlined">
              <InputLabel
                shrink={false}
                margin=""
                id="city-label"
                sx={{
                  right: lang == "ar" ? "2rem" : "unset",
                  left: lang == "ar" ? "unset" : "2rem",
                  top: "-2rem",
                  transformOrigin: "right",
                  fontSize: "1.rem",
                  color: globalState?.isDarkMode ? "white" : COLORS.DARK_GRAY,
                  backgroundColor: !globalState?.isDarkMode
                    ? "#F5F5F5"
                    : COLORS.DARK_GRAY,
                  zIndex: "10",
                  width: "90px",
                  textAlign: 'center'
                }}
              >
                {lang == "ar" ? "المحافظة" : "City"}
              </InputLabel>
              <Controller
                name="city"
                control={control}
                defaultValue={city}
                render={({ field }) => (
                  <Select
                    label={lang == "ar" ? "المحافظة" : "city"}
                    labelId="city-label"
                    {...field}
                    style={{
                      background: globalState?.isDarkMode
                        ? COLORS.DARK_GRAY
                        : "#F5F5F5",
                      border: "1px solid #2f8bc8",
                      borderRadius: "5px",
                      outline: "none",
                      fontSize: "20px",
                      width: "100%",
                      color: globalState?.isDarkMode ? "White" : "black",
                      transition: "0.4s",
                      // zIndex: "1501",
                    }}
                  >
                    <MenuItem disabled value={city} defaultValue={city}>
                      {lang == "ar" ? "اختر المحافظة" : "Choose city"}
                    </MenuItem>
                    {governorates?.map((i, index) => (
                      <MenuItem
                        {...register("city", {
                          required:
                            lang == "ar"
                              ? "هذا الحقل مطلوب"
                              : "This field is required",
                        })}
                        key={index}
                        value={i.value}
                        style={{ zIndex: 1590 }}
                      >
                        {i.label}
                      </MenuItem>
                    ))}
                  </Select>
                )}
              />
            </FormControl>
            <span
              style={{
                color: "red",
                fontSize: "14px",
                my: "0px",
                textAlign: "end",
              }}
            >
              {formState.errors.city?.message}
            </span>

            <Box sx={{ marginY: "30px" }}>
              <InputLabel
                htmlFor="outlined-adornment-password"
                sx={{
                  right: lang == "ar" ? "unset" : "-1.5rem",
                  left: lang == "ar" ? "-1.5rem" : "unset",
                  top: "0.50rem",
                  transformOrigin: "right",
                  fontSize: "0.8rem",
                  color: globalState?.isDarkMode ? "White" : "black",
                  backgroundColor: globalState?.isDarkMode
                    ? COLORS.DARK_GRAY
                    : "#F5F5F5",
                  zIndex: "2",
                  width: "70px",
                }}
              >
                {lang == "ar" ? "كلمه السر" : "Password"}
              </InputLabel>
              <OutlinedInput
                id="outlined-adornment-password"
                type={showPassword ? "text" : "password"}
                placeholder={
                  lang == "ar" ? "ادخل كلمة السر " : "Enter password"
                }
                fullWidth
                {...register("password", {
                  required: lang == "ar" ? "هذا الحقل مطلوب" : "Required",
                })}
                sx={{
                  "& input": {
                    WebkitBoxShadow: `0 0 0 1000px ${globalState?.isDarkMode ? COLORS.DARK_GRAY : "#F5F5F5"
                      } inset`,
                    WebkitTextFillColor: globalState?.isDarkMode
                      ? COLORS.WHITE
                      : COLORS.DARK_GRAY,
                  },
                  "& .MuiInput-root.Mui-focused": {
                    color: COLORS.DARK_GRAY,
                  },
                  "& label": {
                    left: lang == "ar" ? "unset" : "1.75rem",
                    right: lang == "ar" ? "1.75rem" : "unset",
                    transformOrigin: "right",
                    fontSize: "0.8rem",
                    color: COLORS.DARK_GRAY,
                  },
                  "& legend": {
                    textAlign: lang == "ar" ? "right" : "left",
                    fontSize: "0.6rem",
                  },
                  direction: lang == "ar" ? "rtl" : "ltr",
                }}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword}
                      onMouseDown={handleMouseDownPassword}
                    >
                      {showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                }
              />
              <span
                style={{
                  color: "red",
                  fontSize: "14px",
                  my: "0px",
                  textAlign: "end",
                }}
              >
                {formState.errors.password?.message}
              </span>
            </Box>

            <Box
              sx={{
                marginTop: "50px",
                marginBottom: "30px",
                display: "flex",
                justifyContent: "end",
                alignItems: "end",
              }}
            >
              <ColorButton
                variant="contained"
                type="submit"
                sx={{
                  px: "80px",
                  bgcolor: COLORS.SECONDARY,
                  marginLeft: "20px",
                  textAlign: "center",
                  width: "auto",
                  fontSize: "17px",
                }}
                onSubmit={handleSubmit(onSubmit)}
              >
                {lang == "ar" ? "حفظ" : "Save"}
                {/* {isPending ? <ClipLoader color="#000" size={20} /> : "حفظ"} */}
              </ColorButton>
            </Box>
          </form>
        </FormProvider>
      </Box>
    </div>
  );
}

UpdateStudent.propTypes = {
  refetch: PropTypes.func,
  noteBook: PropTypes.bool,
  closeNote: PropTypes.func,
  city: PropTypes.string,
  name: PropTypes.string,
  id: PropTypes.number,
};
