import { COLORS } from "@/constants/Colors/colors";
import { Box, Typography } from "@mui/material";
import React from "react";
import { useSelector } from "react-redux";
import style from "./UserCourseDescription.module.css"
import { FONTS } from "@/constants/Fonts/fonts";

export const UserCourseDescription = ({ description }) => {
  const globalState = useSelector((state) => state?.global);
  const lang = globalState?.lang;
  return (
    <div className={style.container} style={{direction: lang==='ar' ? 'rtl' : 'ltr', fontFamily: lang==='en' ? FONTS.ENGLISH : ""}}>
      <h1>
        {lang == "ar" ? "محتوي الكورس" : "Course content"}
      </h1>
      <p>
        {description}
      </p>
    </div>
  );
};
