import React from "react";
import { useParams, useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { useQueryClient } from "@tanstack/react-query";
import { Box } from "@mui/material";
import { CourseExam } from "../Module/CourseExamsList/atoms/CourseExam";

export const CourseOverallExam = () => {
  const { id, eid } = useParams();
  const [started, setStarted] = useState(true);
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  useEffect(() => {
    if (!started) {
      navigate(`/course/${id}`);
      queryClient.invalidateQueries(["courseExamsForStudent"]);
    }
  }, [started]);
  return (
    <Box sx={{ overflowX: "hidden" }}>
      <CourseExam
        examId={eid}
        setStarted={setStarted}
        overallExam={true}
        courseId={id}
      />
    </Box>
  );
};
