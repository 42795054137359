import { useMutation, useQueryClient } from "@tanstack/react-query";
import { notifyError, notifySuccess } from "@shared/Toast/toast";
import useAxiosConfig from "@shared/Helpers/axios/useAxiosConfig";
import { useState } from "react";
import { Box, Button, Typography } from "@mui/material";
import { Close } from "@mui/icons-material";
import { COLORS } from "@/constants/Colors/colors";
import axios from "axios";
import { currentUser } from "@shared/Helpers/encryptionHelpers";
import { api_url } from "@/constants/base_url";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import { FONTS } from "@/constants/Fonts/fonts";

export default function DeleteStudent({ id, noteBook, closeNote, refetch }) {
 const lang = useSelector((state) => state?.global?.lang);
  const globalState = useSelector((state) => state?.global);
  const { mutate, isPending } = useMutation({
    mutationFn: (formData) =>
      axios.post(`${api_url}users/delete`, formData, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${currentUser()?.token}`,
          lang: lang,
        },
      }),
    onSuccess: () => {
      closeNote();
      refetch();
      notifySuccess(
        lang == "ar" ? "تم حذف الطالب" : "The student has been deleted"
      );
    },
    onError: (err) => {
      console.log(id);
      console.log(err);
      notifyError(
        lang == "ar"
          ? "حدث خطأ اثناء تنفيذ المهمة"
          : "An error occurred while executing the task"
      );
    },
  });
  const [isLoading, setIsLoading] = useState(false);

  const handleDelete = () => {
    if (id) {
      const formData = {
        id: id.toString(),
      };
      mutate(formData);
    } else {
      console.error("User id is missing or invalid.");
    }
  };

  return (
    <>
      <Box
        sx={{
          position: "fixed",
          top: "0",
          left: "0",
          width: "100%",
          height: "100%",
          bgcolor: "#000000ab",
          zIndex: "1501",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          transition: "0.3s",
          opacity: noteBook === "del-student" ? "1" : "0",
          visibility: noteBook === "del-student" ? "visible" : "hidden",
        }}
      >
        <Box
          sx={{
            bgcolor: globalState?.isDarkMode ? COLORS.SOFT_DARK : "white",
            padding: "30px",
            borderRadius: "10px",
            width: "400px",
            height: "auto",
            textAlign: "center",
            position: "relative",
            transition: "0.3s",
            opacity: noteBook === "del-student" ? "1" : "0",
            transform: noteBook === "del-student" ? "scale(1)" : "scale(0)",
          }}
        >
          <div
            className="close-btn"
            style={{
              position: "absolute",
              top: 10,
              right: 10,
              zIndex: 100,
            }}
          >
            <Close
              onClick={closeNote}
              sx={{
                color: "black",
                width: 30,
                height: 30,
                transition: "0.4s",
                cursor: "pointer",
                "&:hover": {
                  color: COLORS.PRIMARY.MAIN,
                },
              }}
            />
          </div>

          <Typography
            fontFamily={lang === 'ar' ? "Noor-bold" : FONTS.ENGLISH}
            fontSize={30}
            sx={{
              fontWeight: 'bold',
              color: globalState?.isDarkMode ? COLORS.WHITE : COLORS.SOFT_DARK,
            }}
          >
            {lang == "ar"
              ? " هل تريد حذف هذا الطالب"
              : "Do you want to delete this student?"}
          </Typography>

          <Box
            mt={5}
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alidngItems: "center",
            }}
          >
            <Button
              onClick={handleDelete}
              sx={{
                padding: "7px 20px",
                width: "49%",
                bgcolor: "darkred",
                color: "white",
                trnsition: "0.4s",
                "&:hover": {
                  bgcolor: "red",
                },
              }}
            >
              {lang == "ar" ? " أوافق" : "Accept"}
              {/* {isPending ? <HashLoader color="#ffffff" size={24} /> : "أوافق"} */}
            </Button>
            <Button
              onClick={closeNote}
              sx={{
                padding: "7px 20px",
                width: "49%",
                bgcolor: "gray",
                color: "white",
                trnsition: "0.4s",
                "&:hover": {
                  bgcolor: "lightgray",
                },
              }}
            >
              {lang == "ar" ? "الغاء" : "Cancel"}
            </Button>
          </Box>
        </Box>
      </Box>
    </>
  );
}
DeleteStudent.propTypes = {
  noteBook: PropTypes.bool,
  closeNote: PropTypes.func,
  refetch: PropTypes.func,
  id: PropTypes.number,
};
