import { COLORS } from "@/constants/Colors/colors";
import { Paper } from "@mui/material";
import { useSelector } from 'react-redux';
import CloseButton from "@/components/Buttons/CloseButton/CloseButton";
import { useState } from 'react';

export default function Empty({ children, sx, close, onClose }) {
  const isDarkMode = useSelector((state) => state?.global?.isDarkMode);
  const [width, setWidth] = useState("0");

  return (
    <Paper
      sx={{
        height: "86vh",
        width: close ? "400px" : width,
        transition: `width 0.3s ease-out`,
        mx: 2,
        backgroundColor: isDarkMode ? COLORS.SOFT_DARK : COLORS.WHITE,
        ...sx,
      }}
    >
      {close && <CloseButton onClick={() => {onClose(); setWidth("0")}} />}
      {children}
    </Paper>
  );
}
