import { Box, Button, Typography } from "@mui/material";
import React from "react";
import { useNavigate } from "react-router-dom";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import { useRecoilState } from "recoil";
import { BookActiveAtom } from "@/modules/bookStore/services/BookActive.atom";
import { COLORS } from "@/constants/Colors/colors";
import { useSelector } from "react-redux";

export function BookCard({
  id,
  img,
  title,
  price,
  teacherName,
  isOpenForReservation,
  reserveNum,
  bookLink,
  delFunc,
  editFunc,
  editData,
  getIdFunc,
  availableAmount,
}) {
  // const dispatch = useDispatch();

  const lang = useSelector((state) => state?.global?.lang);
  const bookData = {
    id,
    img,
    title,
    price,
    isOpenForReservation,
    reserveNum,
    teacherName,
    availableAmount,
  };
  const [activeBook, setActiveBook] = useRecoilState(BookActiveAtom);
  const globalState = useSelector((state) => state.global);
  const navigate = useNavigate();
  return (
    <>
      <Box
        sx={{
          width: "95%",
          padding: "10px",
          bgcolor: globalState?.isDarkMode ? COLORS?.SOFT_DARK : COLORS?.WHITE,
          borderRadius: "10px",
          marginBottom: "20px",
          position: "relative",
        }}
      >
        <Box
          sx={{
            height: "230px",
            borderRadius: "5px",
            position: "relative",
            cursor: "pointer",
          }}
          onClick={() => {
            navigate(`/dashboard/book-store/order/${id}`),
              setActiveBook(bookData);
            localStorage.setItem("activeBook", JSON.stringify(bookData));
          }}
        >
          <Box
            sx={{
              position: "absolute",
              top: "10px",
              left: "0",
              bgcolor:
                isOpenForReservation && availableAmount > 0
                  ? COLORS.SECONDARY.MAIN
                  : "#eb3636",
              zIndex: "100",
              color: "white",
              paddingX: "10px",
              paddingY: "5px",
              borderTopRightRadius: "5px",
              borderBottomRightRadius: "5px",
            }}
          >
            {isOpenForReservation && availableAmount > 0
              ? lang == "ar"
                ? "متاح للحجز"
                : "Available for booking"
              : lang == "ar"
              ? "غير متاح للحجز"
              : "Not available for booking"}
          </Box>

          <img
            src={img}
            alt="title"
            style={{
              width: "100%",
              borderRadius: "5px",
              height: "100%",
              cursor: "pointer",
            }}
          />
        </Box>

        <Box px={1} py={1}>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Typography
              color={globalState?.isDarkMode ? COLORS.WHITE : COLORS.DARK_GRAY}
              fontSize={20}
              fontFamily={"Noor-bold"}
              sx={{
                transition: "0.4s",
                "&:hover": { color: "primary.main", cursor: "pointer" },
              }}
              mb={0.5}
              onClick={() => {
                navigate(`/dashboard/book-store/order/${id}`),
                  setActiveBook(bookData);
                localStorage.setItem("activeBook", JSON.stringify(bookData));
              }}
            >
              {title?.length > 15 ? `${title?.slice(0, 15)}...` : title}
            </Typography>

            <Typography
              fontSize={14}
              fontFamily={"Noor-bold"}
              sx={{
                padding: "5px 10px",
                bgcolor: "primary.main",
                color: "white",
                borderRadius: "5px",
              }}
            >
              {price} {lang == "ar" ? "جنية" : "EGP"}
            </Typography>
          </Box>

          {/* <Typography fontSize={14} sx={{ opacity: "0.6" }} mb={2}>
            رقم الحجز : {reserveNum}
          </Typography> */}
          <Typography fontSize={14} sx={{ opacity: "0.6" }} mb={2}>
            {lang == "ar" ? "الكمية المتاحة :" : "Available quantity : "}{" "}
            {availableAmount}
          </Typography>
          <Typography fontSize={18} color={"primary.main"} mb={1}>
            {teacherName}
          </Typography>

          <Box
            sx={{
              display: "flex",
              marginTop: "30px",
              justifyContent: "end",
              left: "5px",
              top: "5px",
            }}
          >
            <Typography
              onClick={() => {
                editFunc("edit-book");
                getIdFunc(id);
                editData(
                  title,
                  price,
                  teacherName,
                  reserveNum,
                  img,
                  isOpenForReservation,
                  availableAmount
                );
              }}
              sx={{
                bgcolor: "lightgreen",
                padding: "5px",
                borderRadius: "5px",
                color: "white",
                display: "flex",
                marginLeft: "10px",
                cursor: "pointer",
                transition: "0.4s",
                "&:hover": {
                  bgcolor: "darkgreen",
                },
              }}
            >
              <EditIcon />
            </Typography>

            <Typography
              onClick={() => {
                delFunc("del-book"), getIdFunc(id);
              }}
              sx={{
                bgcolor: "lightcoral",
                padding: "5px",
                borderRadius: "5px",
                color: "white",
                display: "flex",
                cursor: "pointer",
                transition: "0.4s",
                "&:hover": {
                  bgcolor: "darkred",
                },
              }}
            >
              <DeleteIcon />
            </Typography>
          </Box>
        </Box>
      </Box>
    </>
  );
}
