import Button from "@mui/material/Button";
import { FONT_SIZES } from "@/constants/Fonts/font_sizes";
import { COLORS } from "@/constants/Colors/colors";
import { FONTS } from "@/constants/Fonts/fonts";
import Hash from "../../Spinners/Hash/hash";
import PropTypes from "prop-types";
import { useSelector } from 'react-redux';

const Types = {
  primary: {
    backgroundColor: COLORS.PRIMARY.MAIN,
    color: COLORS.WHITE,
    "&:hover": { backgroundColor: COLORS.WHITE, color: COLORS.PRIMARY.MAIN },
  },
  danger: {
    backgroundColor: COLORS.DANGER,
    color: COLORS.WHITE,
    "&:hover": { backgroundColor: COLORS.WHITE, color: COLORS.DANGER },
  },
  secondary: {
    backgroundColor: COLORS.LIGHT_GRAY,
    color: COLORS.PRIMARY.MAIN,
    "&:hover": { backgroundColor: COLORS.WHITE, color: COLORS.PRIMARY.MAIN },
  },
};

export default function ColorButton(props) {
  const globalState = useSelector((state) => state?.global);
  const lang = globalState?.lang;
  const isDarkMode = globalState?.isDarkMode;

  const {
    children,
    isLoading,
    sx,
    width,
    colorType = "primary",
    ...rest
  } = props;
  return (
    <Button
      disabled={isLoading}
      sx={{
        py: 1.5,
        fontFamily: lang === 'ar' ? FONTS.REGULAR : FONTS.ENGLISH,
        fontSize: FONT_SIZES.SMALL,
        cursor: "pointer",
        width: width ? width : "100%",
        ...Types[colorType],
        ...sx,
      }}
      variant="contained"
      {...rest}
      style={sx}
    >
      {isLoading ? (
        <div data-testid="hash-spinner">
          <Hash />
        </div>
      ) : (
        children
      )}
    </Button>
  );
}

ColorButton.prototype = {
  children: PropTypes.any,
  isLoading: PropTypes.bool,
  sx: PropTypes.object,
  width: PropTypes.string,
  colorType: PropTypes.oneOf(["primary", "danger", "secondary"]),
};
