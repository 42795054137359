import { COLORS } from "@/constants/Colors/colors";
import { Box, Button, Grid } from "@mui/material";
import useCustomQuery from "@/hooks/useCustomQuery";
import NoItemsText from "@shared/NoItemText/NoItemText";
import Paginator from "@/components/Pagination/Paginator";
import React, { useEffect, useState } from "react";
import { HashLoader } from "react-spinners";
import { UserBookCard } from "./UserBookCard/UserBookCard";
import { api_url } from "@/constants/base_url";
import CollectionsBookmarkIcon from "@mui/icons-material/CollectionsBookmark";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import BookTeachers from './../../bookStore/pages/BookTeachers';
import pageStyle from "./UserBookStore.module.css"
import NoDataFound from "@/components/NoDataFound/NoDataFound";

export const UserBookStore = () => {
  const globalState = useSelector((state) => state?.global);
  const lang = globalState?.lang;
  const isDarkMode = globalState?.isDarkMode;

  const [selectedTeacher, setSelectedTeacher] = useState("");
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState();
  const { data, isPending, isSuccess } = useCustomQuery({
    url: `book/getAllBooks?page=${page}`,
    key: ["books", page, lang],
    lang,
  });

  const navigate = useNavigate();
  useEffect(() => {
    if (isSuccess) {
      setTotalPages(data?.data?.last_page);
    }
  }, [isSuccess, data]);
  return (
    <Box
      sx={{
        paddingX: 2,
        direction: lang == "ar" ? "rtl" : "ltr",
      }}
    >
      <div className={`${pageStyle.paginatorContainer} pageHeader`}>
        {/* <BookTeachers
          selectedTeacher={selectedTeacher}
          getSelectedTeacher={setSelectedTeacher}
        /> */}
        <Paginator page={page} setPage={setPage} totalPages={totalPages} />
      </div>

      {isPending ? (
        <Box sx={{ width: "100%", display: "flex", justifyContent: "center" }}>
          <HashLoader color={COLORS.PRIMARY.MAIN} />
        </Box>
      ) : data?.data?.data?.length > 0 ? (
        <div className="cardsContainer books">
          {data?.data?.data?.map((book) => (
            <UserBookCard
              id={book.id}
              img={`${api_url.slice(0, -7)}${book.image}`}
              title={book.title}
              price={book.price}
              teacherName={book.instructor_name}
              available_amount={book.available_amount}
              isOpenForReservation={book.isOpenForReservation}
              key={book.id}
              lang={lang}
              isDarkMode={isDarkMode}
              color={COLORS.SECONDARY.MAIN}
            />
          ))}
        </div>
      ) : (
        // <NoItemsText text= />
        <div style={{ position: 'relative', width: 'calc(100vw - 100px)' }}>
          <NoDataFound title={lang == "ar" ? "لا يوجد كتب" : "There is no books"} />
        </div>
      )}
    </Box>
  );
};
