import { Box, Button, FormHelperText, TextField } from "@mui/material";
import { COLORS } from "@/constants/Colors/colors";
import { useState } from "react";
import Modal from "@shared/Modal/Modal";
import { FormProvider, useForm } from "react-hook-form";
import ColorButton from "@shared/Buttons/ColorButton/ColorButton";
import { ClipLoader } from "react-spinners";
import { zodResolver } from "@hookform/resolvers/zod";
import useAddCourseFile from "../../CourseModules/ModuleDetailsCollapse/hook/useAddFile";
import addFileSchema from "../../CourseModules/schema/addFile.schema";
import { useSelector } from 'react-redux';
import style from "./CreateCourseFile.module.css"
import Form from "@shared/Form/Form";

export const CreateCourseFile = ({ id }) => {
  const globalState = useSelector((state) => state?.global);
  const lang = globalState?.lang;
  const isDarkMode = globalState?.isDarkMode;

  const [open, setOpen] = useState(false);
  const methods = useForm({
    resolver: zodResolver(addFileSchema),
  });
  const {
    register,
    formState: { errors },
    handleSubmit,
    reset,
  } = useForm({
    defaultValues: {
      name: "",
      link: "",
      type: "course ",
      id: "",
    },
  });

  const { mutate, isLoading } = useAddCourseFile({
    lang,
    onSettled: close,
  });

  function submit(data) {
    const formData = {
      name: data?.name,
      link: data?.file,
      id: id,
      type: "course ",
    };

    mutate(formData);

    setOpen(false);
  }
  return (
    <>
      <button
        className={style.button}
        onClick={() => setOpen(true)}
        style={{ color: isDarkMode ? COLORS.SOFT_DARK : COLORS.WHITE }}
      >
        {lang == "ar" ? "أضف ملف" : "Add File"}
      </button>
      <Modal open={open} closeFn={() => setOpen(false)} maxWidth={"sm"}>
        <FormProvider {...methods}>
          <Form title={lang === 'ar' ? "اضف ملف" : "Add File"} onSubmit={handleSubmit(submit)}>
            <Form.TextController
              register={register}
              registername="name"
              placeholder={lang === 'ar' ? 'ادخل اسم الملف' : 'Enter file name'}
              validationRules={{
                required: lang === 'ar' ? 'اسم الملف مطلوب' : 'File name is required',
              }}
              errorMessage={errors?.name && errors?.name?.message}
            />

            <Form.TextController
              register={register}
              registername="file"
              placeholder={lang == "ar" ? "رابط الملف" : "File link"}
              validationRules={{
                required: lang == "ar" ? `رابط الملف مطلوب` : `File link is required`,
                validate: (value) =>
                  /^https?:\/\/drive\.google\.com\/(?:file\/d\/|open\?id=)([\w-]+)(?:\/.+)?$/.test(value) || (lang === 'ar'
                    ? 'يجب ان يكون رابط الملف من جوجل درايف'
                    : 'The file must be a valid Google Drive link'),
              }}
              errorMessage={errors?.file && errors?.file?.message}
            />

            <Form.ButtonController
              isPending={isLoading}
              type="submit"
            >
              {isLoading ? (
                <ClipLoader color="#000" size={20} />
              ) : lang == "ar" ? (
                "حفظ"
              ) : (
                "Add"
              )}
            </Form.ButtonController>
          </Form>
        </FormProvider >
      </Modal >
    </>
  );
};
