// import { Avatar, Button, Container } from "@mui/material";
// import {
//   Box,
//   TextField,
//   MenuItem,
//   FormControl,
//   FormHelperText,
//   Select,
// } from "@mui/material";
// import { useForm, Controller } from "react-hook-form";
// import PersonOutlineOutlinedIcon from "@mui/icons-material/PersonOutlineOutlined";
// import { z } from "zod";
// import { zodResolver } from "@hookform/resolvers/zod";
// import { MailOutline } from "@mui/icons-material";
// import { governorates } from "@/hooks/governorates";
// import useCustomMutation from "@/hooks/useCustomMutation";
// import { notifySuccess } from "@shared/Toast/toast";
// import React, { useState, useEffect } from "react";
// import boyImage from "@assets/images/boy.png";
// import { useQueryClient } from "@tanstack/react-query";
// import useCustomQuery from "@/hooks/useCustomQuery";
// import { HashLoader } from "react-spinners";
// import { COLORS } from "@/constants/Colors/colors";
// import { updateCurrentUser } from "@shared/Helpers/encryptionHelpers";
// import { useSelector } from "react-redux";
// import { Remove } from './../NavBar/UserAcc/Remove';

// export const MyDetails = () => {
//   const globalState = useSelector((state) => state?.global);
//   const lang = globalState?.lang;
//   const isDarkMode = globalState?.isDarkMode;

//   const queryClient = useQueryClient();
//   const { data } = useCustomQuery({
//     url: `users/getCurrent`,
//     key: ["user current", lang],
//     select: (data) => data?.data?.data,
//   });

//   const [username, setUserName] = useState(data?.name);
//   const [email, setEmail] = useState(data?.email);
//   const [city, setCity] = useState(data?.city);
//   const [remove, setRemove] = useState(false);

//   const openDelAcc = () => setRemove(true);

//   const {
//     mutate,
//     isPending: isLoading,
//     isSuccess,
//   } = useCustomMutation({
//     url: `users/updateCurrent`,
//     onSuccess: () => {
//       notifySuccess(
//         lang == "ar" ? "تم تعديل البيانات بنجاح" : "Edit data succefully"
//       );
//       queryClient.invalidateQueries(["user current"]);
//       updateCurrentUser({ name: username });
//     },
//   });

//   const [formData, setFormData] = useState({});
//   const [reloadLoggedInNav, setReloadLoggedInNav] = useState(false);
//   const [loading, setLoading] = useState(true);
//   useEffect(() => {
//     setUserName(data?.name);
//     setEmail(data?.email);
//     setCity(data?.city);
//   }, [data]);
//   const validateName = (value) => {
//     const words = value.trim().split(/\s+/);
//     return words.length >= 3;
//   };
//   const signUpSchema = z.object({
//     name: z
//       .string()
//       .nonempty(lang == "ar" ? "الإسم الاول مطلوب" : "Name is required")
//       .refine(validateName, {
//         message:
//           lang == "ar"
//             ? "يجب ان يحتوي الاسم علي 3 كلمات علي الاقل"
//             : "The name must contain at least 3 words",
//       }),
//     city: z
//       .string(lang == "ar" ? "المدينة مطلوبة" : "City is required")
//       .nonempty(lang == "ar" ? "المدينة مطلوبة" : "City is required"),
//     email: z
//       .string()
//       .email(lang == "ar" ? "الايميل غير صحيح" : "Email is incorrect")
//       .nonempty(lang == "ar" ? "البريد الالكتروني مطلوب" : "Email is required"),
//   });
//   const {
//     register,
//     handleSubmit,
//     control,
//     formState: { errors },
//     setValue,
//   } = useForm({
//     resolver: zodResolver(signUpSchema),
//     defaultValues: {
//       name: data?.name || "",
//       email: data?.email || "",
//       city: data?.city || "",
//     },
//   });
//   useEffect(() => {
//     setUserName(data?.name);
//     setEmail(data?.email);
//     setCity(data?.city);
//     setValue("name", data?.name);
//     setValue("email", data?.email);
//     setValue("city", data?.city);
//     if (data) setLoading(false);
//   }, [data]);
//   async function submit(data) {
//     setFormData(data);
//     mutate(data);
//   }

//   useEffect(() => {
//     if (isSuccess) {
//       const localData = decrypt(localStorage.getItem("mark-lms-user"));
//       const updatedLocalData = { name: formData.name };
//       const updatedObject = {
//         ...localData,
//         ...updatedLocalData,
//       };
//       const newLocalObject = encrypt(updatedObject);
//       window.localStorage.setItem("mark-lms-user", newLocalObject);
//       setReloadLoggedInNav(true);
//       setTimeout(() => {
//         setReloadLoggedInNav(false);
//       }, 1000);
//     }
//   }, [isSuccess]);
//   return (
//     <Container
//       maxWidth="xl"
//       sx={{
//         alignItems: "center",
//         justifyContent: "center",
//         display: "flex",
//         mt: 4,
//         minHeight: "calc(100vh - 187px)",
//       }}
//     >
//       {loading ? (
//         <div
//           style={{
//             width: "100%",
//             display: "flex",
//             justifyContent: "center",
//           }}
//         >
//           <HashLoader color={COLORS.PRIMARY.MAIN} />
//         </div>
//       ) : (
//         <Box
//           sx={{
//             display: "flex",
//             gap: 2,
//             flexDirection: "column",
//             alignItems: "center",
//             justifyContent: "center",
//           }}
//         >
//           <Avatar
//             alt="Remy Sharp"
//             src={boyImage}
//             sx={{
//               width: 150,
//               height: 150,
//               boxShadow: 3,
//               border: 2,
//             }}
//           />

//           <Box sx={{ width: "90vw" }}>
//             <form
//               onSubmit={handleSubmit(submit)}
//               style={{
//                 display: "flex",
//                 flexDirection: "column",
//                 justifyContent: "center",
//                 alignItems: "center",
//                 gap: 20,
//                 width: "100%",
//                 marginTop: 50,
//               }}
//             >
//               <TextField
//                 error={!!errors.name}
//                 helperText={errors.name?.message}
//                 {...register("name", {
//                   required: `يجب ادخال الاسم`,
//                   validate: {
//                     lessThanThreeWords: (value) =>
//                       value.trim().split(" ").length >= 3 ||
//                       "يجب أن لا يحتوي الاسم علي اقل من 3 كلمات",
//                   },
//                 })}
//                 color="primary"
//                 placeholder={lang == "ar" ? "الاسم بالكامل" : "Full name"}
//                 sx={{
//                   width: "60%",
//                   "& .MuiFormLabel-root.Mui-focused": {
//                     color: COLORS.PRIMARY.MAIN,
//                   },
//                   "& label": {
//                     left: lang == "ar" ? "unset" : "1.75rem",
//                     right: lang == "ar" ? "1.75rem" : "unset",
//                     transformOrigin: lang == "ar" ? "right" : "left",
//                     fontSize: "0.8rem",
//                     color: COLORS.PRIMARY.MAIN,
//                   },
//                   "& legend": {
//                     textAlign: "right",
//                     fontSize: "0.6rem",
//                   },
//                   direction: lang == "ar" ? "rtl" : "ltr",
//                 }}
//                 size="small"
//                 InputProps={{
//                   endAdornment: (
//                     <span style={{ marginLeft: 10, marginTop: 5 }}>
//                       <PersonOutlineOutlinedIcon />
//                     </span>
//                   ),
//                 }}
//                 value={username}
//                 onChange={(e) => {
//                   setValue("name", e.target.value);
//                   setUserName(e.target.value);
//                 }}
//               />

//               <TextField
//                 error={!!errors.email}
//                 helperText={errors.email?.message}
//                 {...register("email")}
//                 color="primary"
//                 placeholder={lang == "ar" ? "الايميل" : "Email"}
//                 sx={{
//                   width: "60%",
//                   "& .MuiFormLabel-root.Mui-focused": {
//                     color: COLORS.PRIMARY.MAIN,
//                   },
//                   "& label": {
//                     left: lang == "ar" ? "unset" : "1.75rem",
//                     right: lang == "ar" ? "1.75rem" : "unset",
//                     transformOrigin: lang == "ar" ? "right" : "left",
//                     fontSize: "0.8rem",
//                     color: COLORS.PRIMARY.MAIN,
//                   },
//                   "& legend": {
//                     textAlign: lang == "ar" ? "right" : "left",
//                     fontSize: "0.6rem",
//                   },
//                   direction: lang == "ar" ? "rtl" : "ltr",
//                 }}
//                 size="small"
//                 InputProps={{
//                   endAdornment: (
//                     <span style={{ marginLeft: 10, marginTop: 5 }}>
//                       <MailOutline />
//                     </span>
//                   ),
//                 }}
//                 value={email}
//                 onChange={(e) => {
//                   setValue("email", e.target.value);
//                   setEmail(e.target.value);
//                 }}
//               />

//               <FormControl
//                 size="small"
//                 sx={{
//                   width: "60%",
//                   fontSize: 12,
//                   direction: lang == "ar" ? "rtl" : "ltr",
//                 }}
//                 value={city}
//                 onChange={(e) => {
//                   setValue("city", e.target.value);
//                   setCity(e.target.value);
//                 }}
//               >
//                 <Controller
//                   control={control}
//                   name="city"
//                   render={({ field }) => (
//                     <>
//                       <Select
//                         {...field}
//                         displayEmpty
//                         color="primary"
//                         renderValue={(selected) => {
//                           if (!selected) {
//                             return (
//                               <MenuItem
//                                 sx={{
//                                   fontSize: 12,
//                                 }}
//                                 value=""
//                               >
//                                 {lang == "ar" ? "المحافظة" : "City"}
//                               </MenuItem>
//                             );
//                           }
//                           return governorates.find(
//                             (item) => item.value === selected
//                           ).label;
//                         }}
//                       >
//                         {governorates.map((item) => (
//                           <MenuItem key={item.value} value={item.value}>
//                             {item.label}
//                           </MenuItem>
//                         ))}
//                       </Select>
//                       {errors.city && (
//                         <FormHelperText error>
//                           {errors.city.message}
//                         </FormHelperText>
//                       )}
//                     </>
//                   )}
//                 />
//               </FormControl>

//               <Button
//                 type="submit"
//                 sx={{
//                   backgroundColor: COLORS.PRIMARY.MAIN,
//                   color: "white",
//                   py: 1.5,
//                   width: "60%",
//                   fontSize: 12,
//                   fontFamily: "Noor-bold",
//                   boxShadow: `0px 3px 0px white, 0px 6px 0px black`,
//                   "&:hover": {
//                     backgroundColor: COLORS.PRIMARY.MAIN,
//                   },
//                 }}
//               >
//                 {isLoading ? (
//                   <HashLoader color="#ffffff" size={22} />
//                 ) : lang == "ar" ? (
//                   "تعديل"
//                 ) : (
//                   "Edit"
//                 )}
//               </Button>
//               <Button onClick={openDelAcc} style={{ borderColor: isDarkMode ? COLORS.DARK_GRAY : "" }}>
//                 {lang == "ar" ? "حذف الحساب" : "Delete account"}
//               </Button>
//             </form>
//           </Box>
//         </Box>
//       )}
//       <Remove open={remove} setOpen={setRemove} />
//     </Container>
//   );
// };













import { Avatar, Button, Container } from "@mui/material";
import {
  Box,
  TextField,
  MenuItem,
  FormControl,
  FormHelperText,
  Select,
} from "@mui/material";
import { useForm, Controller } from "react-hook-form";
import PersonOutlineOutlinedIcon from "@mui/icons-material/PersonOutlineOutlined";
import { z } from "zod";
import { zodResolver } from "@hookform/resolvers/zod";
import { MailOutline } from "@mui/icons-material";
import { governorates } from "@/hooks/governorates";
import useCustomMutation from "@/hooks/useCustomMutation";
import { notifySuccess } from "@shared/Toast/toast";
import React, { useState, useEffect } from "react";
import boyImage from "@assets/images/boy.png";
import { useQueryClient } from "@tanstack/react-query";
import useCustomQuery from "@/hooks/useCustomQuery";
import { HashLoader } from "react-spinners";
import { COLORS } from "@/constants/Colors/colors";
import { updateCurrentUser } from "@shared/Helpers/encryptionHelpers";
import { useSelector } from "react-redux";
import { Remove } from './../NavBar/UserAcc/Remove';
import pageStyle from "./MyDetails.module.css"
import { FONTS } from "@/constants/Fonts/fonts";
import { BsPersonFill } from "react-icons/bs";
import { FaLocationDot } from "react-icons/fa6";
import { IoIosMail } from "react-icons/io";
import IconHuawei from './../../../Admin/Shared/Icons/HuaweiIcon';


export const MyDetails = () => {
  const globalState = useSelector((state) => state?.global);
  const lang = globalState?.lang;
  const isDarkMode = globalState?.isDarkMode;

  const queryClient = useQueryClient();
  const { data } = useCustomQuery({
    url: `users/getCurrent`,
    key: ["user current", lang],
    select: (data) => data?.data?.data,
  });

  const [username, setUserName] = useState(data?.name);
  const [email, setEmail] = useState(data?.email);
  const [city, setCity] = useState(data?.city);
  const [remove, setRemove] = useState(false);
  const [isDisabled, setIsDisabled] = useState(true);

  const enableInputs = () => {
    setIsDisabled(false);
  };



  const openDelAcc = () => setRemove(true);

  const {
    mutate,
    isPending: isLoading,
    isSuccess,
  } = useCustomMutation({
    url: `users/updateCurrent`,
    onSuccess: () => {
      notifySuccess(
        lang == "ar" ? "تم تعديل البيانات بنجاح" : "Edit data succefully"
      );
      setIsDisabled(true);
      queryClient.invalidateQueries(["user current"]);
      updateCurrentUser({ name: username });

    },
  });

  const [formData, setFormData] = useState({});
  const [reloadLoggedInNav, setReloadLoggedInNav] = useState(false);
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    setUserName(data?.name);
    setEmail(data?.email);
    setCity(data?.city);
  }, [data]);
  const validateName = (value) => {
    const words = value.trim().split(/\s+/);
    return words.length >= 3;
  };
  const signUpSchema = z.object({
    name: z
      .string()
      .nonempty(lang == "ar" ? "الإسم الاول مطلوب" : "Name is required")
      .refine(validateName, {
        message:
          lang == "ar"
            ? "يجب ان يحتوي الاسم علي 3 كلمات علي الاقل"
            : "The name must contain at least 3 words",
      }),
    city: z
      .string(lang == "ar" ? "المدينة مطلوبة" : "City is required")
      .nonempty(lang == "ar" ? "المدينة مطلوبة" : "City is required"),
    email: z
      .string()
      .nonempty(lang == "ar" ? "البريد الالكتروني مطلوب" : "Email is required")
      .email(lang == "ar" ? "الايميل غير صحيح" : "Email is incorrect"),
  });
  const {
    register,
    handleSubmit,
    control,
    formState: { errors },
    setValue,
    reset,
  } = useForm({
    resolver: zodResolver(signUpSchema),
    defaultValues: {
      name: data?.name || "",
      email: data?.email || "",
      city: data?.city || "",
    },
  });
  useEffect(() => {
    setUserName(data?.name);
    setEmail(data?.email);
    setCity(data?.city);
    setValue("name", data?.name);
    setValue("email", data?.email);
    setValue("city", data?.city);
    if (data) setLoading(false);
  }, [data]);
  async function submit(data) {
    setFormData(data);
    mutate(data);
  }

  useEffect(() => {
    if (isSuccess) {
      const localData = decrypt(localStorage.getItem("mark-lms-user"));
      const updatedLocalData = { name: formData.name };
      const updatedObject = {
        ...localData,
        ...updatedLocalData,
      };
      const newLocalObject = encrypt(updatedObject);
      window.localStorage.setItem("mark-lms-user", newLocalObject);
      setReloadLoggedInNav(true);
      setTimeout(() => {
        setReloadLoggedInNav(false);
      }, 1000);
    }
  }, [isSuccess]);

  const disableInputs = () => {
    reset({
      name: data?.name || "",
      email: data?.email || "",
      city: data?.city || "",
    });

    setIsDisabled(true);
  }

  return (
    <>
      <main style={{ direction: lang === 'ar' ? 'rtl' : 'ltr', fontFamily: lang === 'en' ? FONTS.ENGLISH : "", backgroundColor: isDarkMode ? "" : COLORS.LIGHT_GRAY }}>

        {loading ? (
          <div
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "center",
            }}
          >
            <HashLoader color={COLORS.PRIMARY.MAIN} />
          </div>
        ) : (
          <form className={pageStyle.gridContainer} onSubmit={handleSubmit(submit)} style={{ backgroundColor: isDarkMode ? COLORS.SOFT_DARK : COLORS.WHITE }}>
            <div className={pageStyle.imageSection}>
              <div className={pageStyle.image}>
                <Avatar
                  alt="Remy Sharp"
                  src={boyImage}
                  sx={{
                    width: 150,
                    height: 150,
                    borderRadius: '0'
                  }}
                />
              </div>

              <div>

                <button type='button' onClick={enableInputs} className={pageStyle.edit} style={{ color: COLORS.SECONDARY.MAIN }}>
                  {lang === 'ar' ? "تعديل البيانات" : "Edit Data"}
                </button>


                <button type="button" className={pageStyle.delete} style={{ color: isDarkMode ? COLORS.BLACK : COLORS.WHITE }} onClick={openDelAcc}>{lang === 'ar' ? "حذف الحساب" : "Delete Account"}</button>
              </div>
            </div>

            <div className={pageStyle.inputsSection}>

              <CustomEditableInputs name='name' isDisabled={isDisabled} Icon={<BsPersonFill />} placeholder={lang == "ar" ? "الاسم بالكامل" : "Full Name"}
                errors={errors} register={register}
                lang={lang} value={username}

                onChange={(e) => {
                  setValue("name", e.target.value);
                  setUserName(e.target.value);
                }}
              />
              <CustomEditableInputs name='email' isDisabled={isDisabled} Icon={<IoIosMail />} placeholder={lang == "ar" ? "الايميل" : "Email"}
                errors={errors} register={register}
                lang={lang} value={email}

                onChange={(e) => {
                  setValue("email", e.target.value);
                  setEmail(e.target.value);
                }}
              />
              <CustomSelect city={city} isDisabled={isDisabled} setValue={setValue} setCity={setCity} control={control} lang={lang} errors={errors} />

              {/* <button type='button' className={pageStyle.save} style={{ color: isDarkMode ? COLORS.BLACK : COLORS.WHITE, backgroundColor: COLORS.SECONDARY.MAIN }}>{lang === 'ar' ? "حفظ التغيرات" : "Save Changes"}</button> */}

              <button type="submit" disabled={isDisabled} className={pageStyle.save} style={{ color: isDarkMode ? COLORS.BLACK : COLORS.WHITE, backgroundColor: isDisabled ? COLORS.GRAY : COLORS.SECONDARY.MAIN }}>
                {isLoading ?
                  (<HashLoader color="#ffffff" size={22} />) :
                  lang === 'ar' ? "حفظ التغيرات" : "Save Changes"}
              </button>

              {!isDisabled && <button type='button' onClick={disableInputs} className={pageStyle.edit} style={{ color: COLORS.DANGER }}>
                {lang === 'ar' ? "إلغاء" : "Cancel"}
              </button>}
            </div>
          </form>
        )}


      </main>
      <Remove open={remove} setOpen={setRemove} />
    </>
  );
};



const CustomEditableInputs = ({ name, Icon, isDisabled, placeholder, requirements, validation, errors, register, lang, value, onChange }) => {
  return (
    <TextField
      error={!!errors[name]}
      helperText={errors[name]?.message}
      {...register(name, {
        required: requirements,
        validate: validation,
      })}
      color="primary"
      placeholder={placeholder}
      disabled={isDisabled}

      sx={{
        width: "100%",
        "& .MuiFormLabel-root.Mui-focused": {
          color: COLORS.SECONDARY.MAIN,
        },
        "& label": {
          left: lang == "ar" ? "unset" : "1.75rem",
          right: lang == "ar" ? "1.75rem" : "unset",
          transformOrigin: lang == "ar" ? "right" : "left",
          fontSize: "0.8rem",
          color: COLORS.SECONDARY.MAIN,
        },
        "& legend": {
          textAlign: "right",
          fontSize: "0.6rem",
        },
        direction: lang == "ar" ? "rtl" : "ltr",
      }}
      size="small"
      InputProps={{
        startAdornment: (
          <span style={{
            marginRight: 10, marginTop: 5, fontSize: '25px', color: COLORS.SECONDARY.MAIN
          }}>
            {Icon}
          </span>
        ),
      }}
      value={value}
      onChange={onChange}
    />

  )
}


const CustomSelect = ({ city, isDisabled, setValue, setCity, control, lang, errors }) => {
  return (
    <FormControl
      sx={{ width: '100%' }}
      size="small"
      value={city}
      onChange={(e) => {
        setValue("city", e.target.value);
        setCity(e.target.value);
      }}
    >
      <Controller
        control={control}
        name="city"
        render={({ field }) => (
          <>
            <Select
              // disabled={isDisabled}
              {...field}
              displayEmpty
              color="primary"
              renderValue={(selected) => {
                if (!selected) {
                  return (
                    <MenuItem
                      sx={{
                        fontSize: 12,
                      }}
                      value=""
                    >
                      {lang == "ar" ? "المحافظة" : "City"}
                    </MenuItem>
                  );
                }
                return governorates.find(
                  (item) => item.value === selected
                ).label;
              }}
            >
              {governorates.map((item) => (
                <MenuItem key={item.value} value={item.value} disabled={isDisabled}>
                  {item.label}
                </MenuItem>
              ))}
            </Select>
            {errors.city && (
              <FormHelperText error>
                {errors.city.message}
              </FormHelperText>
            )}
          </>
        )}
      />
    </FormControl>
  )
}

