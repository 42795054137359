import { COLORS } from "@/constants/Colors/colors";
import { Box, Button, TextField, InputAdornment } from "@mui/material";
import useCustomMutation from "@/hooks/useCustomMutation";
import { notifySuccess } from "@shared/Toast/toast";
import { useQueryClient } from "@tanstack/react-query";
import React, { useState } from "react";
import { HashLoader } from "react-spinners";
import { useSelector } from "react-redux";
import editStyle from "./EditCourseHeader.module.css"

export const EditCourseHeader = ({ id, setEditing, course }) => {
  const globalState = useSelector((state) => state?.global);
  const lang = globalState?.lang;
  const isDarkMode = globalState?.isDarkMode;

  const [submitted, setSubmitted] = useState(false);
  const [courseData, setCourseData] = useState({
    name: course?.name,
    description: course?.description,
    price: +course?.price,
  });
  const queryClient = useQueryClient();
  const { mutate, isPending } = useCustomMutation({
    url: `course/update`,
    onSuccess: () => {
      notifySuccess(
        lang == "ar" ? "تم تعديل الكورس بنجاح" : "Edit course succefully"
      );
      setEditing(false);
      queryClient.invalidateQueries(["course", "courses", lang]);
    },
  });
  const submit = () => {
    if (!courseData.name) return;
    if (!courseData.description) return;
    if (!courseData.price && courseData.price != 0) return;
    mutate({
      ...courseData,
      id: id,
    });
  };
  return (
    <form className={editStyle.content}>
      <div>
        <CustomTextField
          type="text"
          label={lang === 'ar' ? 'اسم الكورس' : 'Course name'}
          value={courseData?.name}
          onChange={(e) => setCourseData({ ...courseData, name: e.target.value })}
          error={!courseData.name && submitted}
          helperText={!courseData.name && submitted && lang === 'ar' ? 'يجب إضافة اسم للكورس' : "You must add a course name"}
          lang={lang}
        />

        <CustomTextField
          type="number"
          label={lang === 'ar' ? 'السعر' : 'Price'}
          value={courseData?.price}
          onChange={(e) => {
            if (e.target.value >= 0) {
              setCourseData({ ...courseData, price: e.target.value });
            }
          }}
          error={!courseData.price && submitted}
          helperText={!courseData.price && submitted && lang === 'ar' ? 'يجب إضافة سعر للكورس' : "You must add a price for course"}
          lang={lang}
        />

        <CustomTextField
          type="text"
          label={lang === 'ar' ? 'الوصف' : 'Description'}
          value={courseData?.description}
          onChange={(e) => setCourseData({ ...courseData, description: e.target.value })}
          error={!courseData.description && submitted}
          helperText={!courseData.description && submitted && lang === 'ar' ? 'يجب إضافة وصف للكورس' : "You must add a description for course"}
          lang={lang}
        />
      </div>

      <div className={editStyle.buttons}>
        <button
          type="button"
          onClick={() => {
            setSubmitted(true);
            submit();
          }}
          style={{ color: isDarkMode ? COLORS.DARK_GRAY : COLORS.WHITE }}
        >
          {isPending ? (
            <HashLoader size={20} color={COLORS.WHITE} />
          ) : lang == "ar" ? (
            "تعديل"
          ) : (
            "Edit"
          )}
        </button>
        <button
          type="button"
          onClick={() =>
            setCourseData({
              name: course?.name,
              description: course?.description,
              price: +course.price,
            })
          }
          style={{ color: isDarkMode ? COLORS.DARK_GRAY : COLORS.WHITE }}
        >
          {lang == "ar" ? "استعادة" : "Resset"}
        </button>
        <button
          type="button"
          onClick={() => setEditing(false)}
          style={{ color: isDarkMode ? COLORS.DARK_GRAY : COLORS.WHITE }}
        >
          {lang == "ar" ? " الغاء" : "Cancel"}
        </button>
      </div>
    </form>
  );
};



const CustomTextField = ({
  type = 'text',
  label,
  value,
  onChange,
  error,
  helperText,
  lang,
}) => {
  return (
    <div className={editStyle.inputContainer}>
      {error && <p className={editStyle.errorMessage}>* {helperText}</p>}

      <input
        style={{ borderColor: error ? COLORS.DANGER : "" }}
        type={type}
        value={value}
        onChange={onChange}
        lang={lang}
        placeholder={label}
      />
    </div>
  );
};
