import { COLORS } from "@/constants/Colors/colors";
import { Box, Button, TextField, Typography } from "@mui/material";
import InputAdornment from "@mui/material/InputAdornment";
import Modal from "@shared/Modal/Modal";
import { useEffect, useRef, useState } from "react";
import { notifySuccess, notifyError } from "@shared/Toast/toast";
import axios from "axios";
import { currentUser } from "@shared/Helpers/encryptionHelpers";
import { api_url } from "@/constants/base_url";
import { HashLoader } from "react-spinners";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import { FONTS } from '@/constants/Fonts/fonts';

export default function AddNotifications({
  modalNotification,
  closeModalNotification,
  refetch,
}) {
  const lang = useSelector((state) => state?.global?.lang);
  const [title, setTitle] = useState("");
  const [body, setBody] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [errorMessagebody, setErrorMessagebody] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const timerRef = useRef();
  const globalState = useSelector((state) => state?.global);

  const handleSubmit = async () => {
    try {
      setIsLoading(true);
      if (title.trim().length === 0) {
        setErrorMessage(
          lang == "ar" ? "عنوان الاشعار مطلوب" : "Notice title required"
        );
        return;
      }
      if (title.length < 8) {
        setErrorMessage(
          lang == "ar"
            ? "يجب ان يكون العنوان علي الاقل 8 حروف"
            : "The title must be at least 8 characters"
        );
        return;
      }

      if (body.trim().length === 0) {
        setErrorMessagebody(
          lang == "ar" ? "نص الاشعار مطلوب" : "Notice text required"
        );
        return;
      }
      const formData = new FormData();
      formData.append("title", title);
      formData.append("body", body);
      const config = {
        headers: {
          Authorization: `Bearer ${currentUser()?.token}`,
          "Content-Type": "application/json",
          lang: lang,
        },
      };
      const response = await axios.post(
        `${api_url}sendTopicNotification`,
        formData,
        config
      );
      notifySuccess(lang == "ar" ? "تمت الاضافه بنجاح" : "Added successfully");
      closeModalNotification();
      // reset();
      refetch();
      timerRef.current = setTimeout(() => {
        closeModalNotification();
      }, 2000);
    } catch (error) {
      notifyError(
        lang == "ar"
          ? "حدث خطا اثناء التنفيذ"
          : "An error occurred during execution"
      );
      closeModalNotification();
      console.error("Error sending notification:", error);
    } finally {
      setIsLoading(false);
      modalNotification = true;
    }
  };

  useEffect(() => {
    const timer = timerRef.current;

    return () => {
      clearTimeout(timer);
    };
  }, []);
  return (
    <Modal
      maxWidth={"sm"}
      open={modalNotification}
      closeFn={closeModalNotification}
    >
      <Typography
        fontFamily={lang === 'ar' ? "Noor-bold" : FONTS.ENGLISH}
        fontSize={30}
        sx={{
          color: globalState?.isDarkMode ? COLORS.WHITE : COLORS.BLACK,
          fontWeight: "bold"
        }}
      >
        {lang == "ar" ? "أدخل بيانات الإشعار" : "Enter Notice data"}
      </Typography>

      <Box sx={{ width: "65%", margin: "auto" }}>
        <Box sx={{ marginY: "20px" }}>
          <TextField
            inputProps={{
              style: {
                WebkitBoxShadow: `0 0 0 1000px ${globalState?.isDarkMode ? "transparent" : COLORS.WHITE
                  } inset`,
                WebkitTextFillColor: `${globalState?.isDarkMode ? COLORS.WHITE : "#2a2a2a"
                  }`,
              },
            }}
            value={title}
            onChange={(event) => {
              setTitle(event.target.value);
              setErrorMessage("");
            }}
            placeholder={
              lang == "ar" ? "ادخل عنوان الإشعار " : "Enter the Notice title"
            }
            label={lang == "ar" ? " عنوان الإشعار" : " Notice title"}
            size="small"
            fullWidth
            sx={{
              "& .MuiFormLabel-root.Mui-focused": {
                color: globalState?.isDarkMode
                  ? COLORS.WHITE
                  : COLORS.DARK_GRAY,
              },
              "& label": {
                left: lang == "ar" ? "unset" : "0",
                right: lang == "ar" ? "1.75rem" : "unset",
                transformOrigin: lang == "ar" ? "right" : "left",
                fontSize: "0.8rem",
                color: COLORS.PRIMARY.MAIN,
              },
              "& legend": {
                textAlign: lang == "ar" ? "right" : "left",
                fontSize: "0.6rem",
              },
              direction: lang == "ar" ? "rtl" : "ltr",
            }}
            InputProps={{
              startAdornment: <InputAdornment position="end"></InputAdornment>,
            }}
          />
          <span
            style={{
              color: "red",
              fontSize: "14px",
              my: "0px",
              textAlign: "end",
            }}
          >
            {errorMessage}
          </span>
        </Box>

        <Box sx={{ marginY: "30px" }}>
          <TextField
            inputProps={{
              style: {
                WebkitBoxShadow: `0 0 0 1000px ${globalState?.isDarkMode ? "transparent" : COLORS.WHITE
                  } inset`,
                WebkitTextFillColor: `${globalState?.isDarkMode ? COLORS.WHITE : "#2a2a2a"
                  }`,
              },
            }}
            value={body}
            onChange={(event) => {
              setBody(event.target.value);
              setErrorMessagebody("");
            }}
            placeholder={
              lang == "ar" ? "ادخل نص الإشعار " : "Enter the text of the Notice"
            }
            label={lang == "ar" ? " نص الإشعار" : "Notice text"}
            fullWidth
            multiline
            sx={{
              "& .MuiFormLabel-root.Mui-focused": {
                color: globalState?.isDarkMode
                  ? COLORS.WHITE
                  : COLORS.DARK_GRAY,
              },
              "& label": {
                left: lang == "ar" ? "unset" : "0",
                right: lang == "ar" ? "1.75rem" : "unset",
                transformOrigin: lang == "ar" ? "right" : "left",
                fontSize: "0.8rem",
                color: COLORS.PRIMARY.MAIN,
              },
              "& legend": {
                textAlign: lang == "ar" ? "right" : "left",
                fontSize: "0.6rem",
              },
              direction: lang == "ar" ? "rtl" : "ltr",
            }}
            InputProps={{
              startAdornment: <InputAdornment position="end"></InputAdornment>,
            }}
          />
          <span
            style={{
              color: "red",
              fontSize: "14px",
              my: "0px",
              textAlign: "end",
            }}
          >
            {errorMessagebody}
          </span>
        </Box>

        <Box
          sx={{
            marginTop: "50px",
            marginBottom: "30px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Button
            type="submit"
            onClick={handleSubmit}
            sx={{
              background: COLORS.PRIMARY.MAIN,
              width: "250px",
              padding: "10px 25px",
              color: "white",
              cursor: "pointer",
              transition: "0.4s",
              "&:hover": {
                background: "white",
                color: COLORS.PRIMARY.MAIN,
              },
            }}
          >
            {isLoading ? (
              <HashLoader color="#ffffff" size={20} />
            ) : lang == "ar" ? (
              "ارسال"
            ) : (
              "Send"
            )}
          </Button>
        </Box>
      </Box>
    </Modal>
  );
}

AddNotifications.propTypes = {
  refetch: PropTypes.func,
  modalNotification: PropTypes.bool,
  closeModalNotification: PropTypes.func,
};
