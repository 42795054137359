import {
  Box,
  Button,
  IconButton,
  InputAdornment,
  TextField,
  Typography,
} from "@mui/material";
import Column from "@/components/Layout/Column/Column";
import Row from "@/components/Layout/Row/Row";
import Paginator from "@/components/Pagination/Paginator";
import { useState } from "react";
import SearchIcon from "@mui/icons-material/Search";
import { COLORS } from "@/constants/Colors/colors";
import useCustomQuery from "@/hooks/useCustomQuery";
import CourseCard from "../components/CourseCard";
import { FONT_SIZES } from "@/constants/Fonts/font_sizes";
import { useSelector } from "react-redux";
import { FONTS } from "@/constants/Fonts/fonts";

export default function CoursesCodesPage() {
  const lang = useSelector((state) => state?.global?.lang);
  const [page, setPage] = useState(1);
  const [keyQuery, setKeyQuery] = useState("");
  const [inputValue, setInputValue] = useState("");
  const globalState = useSelector((state) => state?.global);

  const { data: courses } = useCustomQuery({
    url: `course/search?query=${keyQuery}&page=${page}`,
    key: ["courses", keyQuery, lang],
    page: page,
    lang,
  });

  const searchHandler = () => {
    setKeyQuery(inputValue);
  };

  return (
    <>
      <Row spacing={"space-between"} align={"flex-start"}>
        <Column
          gap={1}
          spacing={"space-between"}
          align={"flex-start"}
          width={"100%"}
        >
          <Row spacing={"space-between"} mb={2}>
            <Typography
              fontFamily={lang === 'ar' ? FONTS.BOLD : FONTS.ENGLISH}
              fontWeight={"bold"}
              fontSize={30}
              sx={{
                color: globalState?.isDarkMode ? COLORS.WHITE : COLORS.PRIMARY.MAIN,
              }}
            >
              {lang == "ar"
                ? "اختر كورس لعرض الأكواد الخاصة به"
                : "Choose a course to view its codes"}
            </Typography>
            <Box>
              <TextField
                sx={{
                  direction: lang == "ar" ? "rtl" : "ltr",
                  borderRadius: "5px",
                  display: "flex",
                  justifyContent: "flex-end",
                  color: "white",
                  backgroundColor: globalState?.isDarkMode ?COLORS.SOFT_DARK:"white",
                  "& .MuiInputBase-input": {
                    padding: "7px",
                  },
                  "& .MuiOutlinedInput-notchedOutline": {
                    border: "none",
                  },
                }}
                placeholder={lang == "ar" ? "ابحث عن كورس" : "Find a course"}
                InputProps={{
                  style: {
                    fontSize: FONT_SIZES.REGULAR,
                    color: globalState?.isDarkMode
                      ? COLORS.WHITE
                      : COLORS.BLACK,
                    paddingRight: lang == "ar" ? "0" : "unset",
                  },
                  endAdornment: (
                    <InputAdornment position={lang == "ar" ? "start" : "end"}>
                      <IconButton></IconButton>
                      <Button
                        sx={{
                          color: "white",
                          backgroundColor: COLORS.PRIMARY.MAIN,
                          padding: "8px",
                          "&:hover": {
                            backgroundColor: "green",
                          },
                        }}
                      >
                        <SearchIcon />
                      </Button>
                    </InputAdornment>
                  ),
                }}
                onChange={(e) => {
                  setInputValue(e.target.value);
                  if (e.target.value == "") {
                    setKeyQuery("");
                  }
                }}
                // inputRef={searchInputRef}
                onClick={searchHandler}
              />
            </Box>
          </Row>
          <Row>
            <Column
              gap={1}
              spacing={"space-between"}
              align={"flex-start"}
              width={"100%"}
            >
              <Paginator
                page={page}
                totalPages={courses?.last_page}
                setPage={setPage}
              />
            </Column>
          </Row>
          <Box
            sx={{
              display: "grid",
              gridTemplateColumns: "repeat(3,1fr)",
              gap: 2,
            }}
          >
            {courses?.data
              ?.filter((course) => course?.price > 1)
              .map((course) => (
                <CourseCard
                  parent={"codes"}
                  key={course?.id}
                  courseId={course?.id}
                />
              ))}
          </Box>
        </Column>
      </Row>
    </>
  );
}
