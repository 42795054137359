import { Box, Typography } from "@mui/material";
import loggedinHero from "@assets/images/cuate.svg";
import homeImg from "@assets/images/homeImage-back.png"
import homeImgDark from "@assets/images/homeImage-back-dark.png"

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import useCustomQuery from "@/hooks/useCustomQuery";
import { useSelector } from 'react-redux';
import heroStyles from "./Hero.module.css"
import { FONTS } from "@/constants/Fonts/fonts";
import { currentUser } from "@shared/Helpers/encryptionHelpers";

import { GiFootsteps } from "react-icons/gi";
import { IoFootstepsSharp } from "react-icons/io5";
import { BsStars } from "react-icons/bs";
import { Link as ScrollLink } from "react-scroll";

import { COLORS } from "@/constants/Colors/colors";


export const Hero = () => {
  const globalState = useSelector((state) => state?.global);
  const lang = globalState?.lang;
  const isDarkMode = globalState?.isDarkMode;

  const userData = currentUser();

  const handleName = (name) => {
    if (typeof name !== 'string') {
      return '';
    }
    const words = name.trim().split(' ');
    return words[0];
  };


  const { data } = useCustomQuery({
    key: ["bar"],
    url: "bar/getAll",
    select: (data) => data,
  });

  return (
    <>

      <div className={heroStyles.container} style={{
        direction: lang === 'ar' ? 'rtl' : 'ltr',
        fontFamily: lang === 'ar' ? "" : FONTS.ENGLISH,
        backgroundColor: isDarkMode ? COLORS.SOFT_DARK : COLORS.WHITE
      }}>

        <div className={heroStyles.content}>
          <p>{lang === 'ar' ? "مرحباً" : "Welcome"} <span style={{ color: isDarkMode ? COLORS.WHITE : COLORS.BLACK, paddingRight: lang === 'ar' ? '0' : "", paddingLeft: lang === 'ar' ? '5px' : "" }}>{handleName(userData?.name)}</span></p>
          <h1 style={{ fontSize: lang === 'ar' ? "6vw" : "" , lineHeight: lang==='ar'? "normal" :""}}>
            <div>{lang === 'ar' ? "هذه هي" : "This is Your"}</div>
            {lang === 'ar' ? <>خطوتك <span>الأولى</span></> : <><span>First </span>Step</>}

            <div>{lang === 'ar' ? "للنجاح" : "to Success"}</div>
          </h1>

          <div>
            <div className={heroStyles.icon}><IoFootstepsSharp style={lang === 'ar' ? { marginRight: '0', marginLeft: '10px' } : {}} /></div>

            <div className={heroStyles.slider}>
              <Slider
                autoplay
                infinite
                speed={1000}
                slidesToShow={1}
                slidesToScroll={1}
                arrows={false}
              >
                {data?.data?.data?.length >= 2 &&
                  data?.data?.data?.map((item, i) => {
                    return (
                      <Typography
                        key={item.id}
                        fontSize={24}
                        sx={{
                          fontFamily: lang === 'ar' ? FONTS.BOLD : FONTS.ENGLISH,
                          textAlign: lang==='ar'? 'right':'',
                        }}
                      >
                        {item?.body || ""}
                      </Typography>
                    );
                  })}
                {data?.data?.data?.length === 1 &&
                  Array(2).fill(
                    <Typography
                      key={data?.data?.data?.[0].id}
                      fontSize={24}
                      sx={{
                        fontFamily: lang === 'ar' ? FONTS.BOLD : FONTS.ENGLISH,
                        textAlign: lang==='ar'? 'right':'',
                      }}
                    >
                      {data?.data?.data?.[0]?.body || ""}
                    </Typography>
                  )}
              </Slider>
            </div>
          </div>

          <ScrollLink to="landingCourses" smooth={true} duration={500} style={{ color: isDarkMode ? COLORS.BLACK : COLORS.WHITE }}>{lang === 'ar' ? "استكشف الدورات" : "Explore Courses"}</ScrollLink>
        </div>

        <div className={heroStyles.img} style={{ backgroundImage: `url(${isDarkMode ? homeImgDark : homeImg})` }}>
        </div>
      </div>
    </>
  );
};
