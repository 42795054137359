import React, { useState, useEffect } from "react";
import { Typography } from "@mui/material";
import { Box, TextField, InputAdornment } from "@mui/material";
import { useSelector } from "react-redux";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import useCustomQuery from "@/hooks/useCustomQuery";
import { COLORS } from "@/constants/Colors/colors";
import ColorButton from "@shared/Buttons/ColorButton/ColorButton";
import { notifyError, notifySuccess } from "@shared/Toast/toast";
import axios from "axios";
import { useMutation } from "@tanstack/react-query";
import { queryClient } from "@config/queryClient.config";
import { api_url } from "@/constants/base_url";
import { currentUser } from "@shared/Helpers/encryptionHelpers";
import Modal from "@shared/Modal/Modal";
import QRCode from "react-qr-code";
import { useNavigate } from "react-router-dom";
import { HashLoader } from "react-spinners";
import style from "./Wallet.module.css"

import mobile from "@/assets/images/Wallet/MobileWallets.png"
import fawry from "@/assets/images/Wallet/Fawry.png"
import master from "@/assets/images/Wallet//MasterVisa.png"
import aman from "@/assets/images/Wallet/Aman.png"
import masary from "@/assets/images/Wallet/Masary.png"
import { FONTS } from "@/constants/Fonts/fonts";
import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { z } from "zod";
import { FormProvider } from 'react-hook-form';
import { useFormContext } from 'react-hook-form';

const methodsImages =
{
  4: mobile,
  3: fawry,
  2: master,
  12: aman,
  14: masary
}

export default function PaymentMethods() {
  const globalState = useSelector((state) => state?.global);
  const lang = globalState?.lang;
  const isDarkMode = globalState?.isDarkMode;

  const [selectedValue, setSelectedValue] = useState("");
  const [submitted, setSubmitted] = useState(false)

  const [paymentData, setPaymentData] = useState([]);
  const [open, setOpen] = useState(false);
  const [qrData, setQrData] = useState(false);
  const navigate = useNavigate();

  const { data } = useCustomQuery({
    url: `payment/getMethods`,
    key: ["getMethods", lang],
  });



  const handleChange = (event) => {
    setSelectedValue(event.target.value);
    setValue("paymentMethod", event.target.value);
    console.log(event.target.value)
  };

  const paymentSchema = z.object({
    paymentMethod: z
      .string()
      .nonempty(lang === "ar" ? "يجب اختيار طريقة دفع" : "You must choose a payment method"),
    paymentNumber: z.string()
      .optional()
      .refine(value => {
        const { paymentMethod } = getValues();

        if (paymentMethod === "4") {
          return value && /^(011|010|012|015)\d{8}$/.test(value);
        }
        return true;
      }, {
        message: lang === "ar" ? "رقم الدفع يجب أن يكون رقم هاتف صالح" : "Payment number must be a valid phone number",
      }),
    amount: z.string()
      .nonempty(lang === "ar" ? "يجب إضافة المبلغ" : "Amount must be added")
      .regex(/^\d+$/, lang === 'ar' ? "رقم الدفع يجب ان يكون رقم صالح" : "Amount must be a valid number"),
  });

  const methods = useForm({
    resolver: zodResolver(paymentSchema),
    defaultValues: {
      paymentMethod: '',
      paymentNumber: '',
      amount: ''
    }
  });

  const { handleSubmit, setValue, watch, getValues, formState: { errors } } = methods;
  const selectedPaymentMethod = watch("paymentMethod");

  const { mutate, isPending } = useMutation({
    mutationFn: (data) =>
      axios.post(`${api_url}payment/createInvoice`, data, {
        headers: {
          Authorization: `Bearer ${currentUser()?.token}`,
          lang: lang,
        },
      }),
    onSuccess: (data) => {
      queryClient.invalidateQueries(["shipped", lang]);
      notifySuccess(lang === "ar" ? "تم الشحن بنجاح" : "Shipped successfully");
      console.log("Response Data:", data?.data);
      setPaymentData(data?.data);
      setQrData(data?.data?.payment_data?.meezaQrCode);
      if (data?.data?.payment_data?.redirectTo) {
        navigate(`//${data?.data?.payment_data?.redirectTo}`);
      }
      setOpen(true);
    },
    onError: (err) => {
      console.log(err);
      notifyError(
        lang === "ar"
          ? "حدث خطأ ما, الرجاء المحاولة لاحقا"
          : "Something went wrong. Please try again later."
      );
    },
  });

  const submitHandler = (data) => {
    mutate({
      payment_method_id: data.paymentMethod,
      payment_number: selectedValue === "4" ? data.paymentNumber : undefined,
      amount: data.amount,
    });
  };

  console.log("selected value: ", selectedValue)


  return (
    <Box sx={{ width: "100%" }}>
      <FormControl
        sx={{
          direction: lang === "ar" ? "rtl" : "ltr",
          width: "100%",
        }}
      >
        <FormLabel id="demo-radio-buttons-group-label">
          <p
            className={style.chargeWalletText}
            style={{
              color: COLORS.SECONDARY.MAIN,
              fontFamily: lang === 'en' ? FONTS.ENGLISH : ""
            }}>
            {lang === "ar" ? "اشحن محفظتك" : "Charge your wallet"}
          </p>
        </FormLabel>

        <FormProvider {...methods}>
          <form onSubmit={handleSubmit(submitHandler)}>
            <p
            style={{color: COLORS.DANGER, marginBottom: "15px", fontSize: "14px", textAlign: "center"}}
            >{submitted && (selectedValue ==='') ? lang === "ar" ? "يجب اختيار طريقة دفع" : "You must choose a payment method" : ""}</p>
            <div className={style.radioContainer}>
              {data?.data?.data.map((method) => (
                <CustomRadioButton
                  key={method.paymentId}
                  name="radio-buttons-group"
                  registerName="paymentMethod"
                  value={method.paymentId}
                  selectedValue={selectedValue}
                  onChange={handleChange}
                />
              ))}
            </div>

            {selectedValue === "4" && (
              <CustomTextField
                registerName="paymentNumber"
                placeholder={lang === "ar" ? "رقم الدفع" : "Payment Number"}
                isDarkMode={isDarkMode}
              />
            )}

            <CustomTextField
              registerName="amount"
              placeholder={lang === "ar" ? "المبلغ" : "Amount"}
              isDarkMode={isDarkMode}
            />

            <button type="submit" onClick={() => setSubmitted(true)} className={style.submitButton} style={{ color: isDarkMode ? COLORS.BLACK : COLORS.WHITE }}>
              {isPending ? (
                <HashLoader color="#ffffff" size={24} />
              ) : lang === "ar" ? (
                "اشحن"
              ) : (
                "Charge"
              )}
            </button>
          </form>
        </FormProvider>

        {paymentData && (
          <Modal maxWidth={"sm"} open={open} closeFn={() => setOpen(false)}>
            {paymentData?.invoice_id && (
              <Typography
                h1
                sx={{ fontSize: "1.3rem", textAlign: "center" }}
              >
                {lang == "ar" ? "رقم الفاتورة :" : "invoice number :" + " "}
                {paymentData?.invoice_id}{" "}
              </Typography>
            )}
            {paymentData?.payment_data?.meezaReference && (
              <Typography
                h1
                sx={{ fontSize: "1.3rem", textAlign: "center" }}
              >
                {lang == "ar"
                  ? "meeza Reference:"
                  : "meeza Reference :" + " "}
                {paymentData?.payment_data?.meezaReference}{" "}
              </Typography>
            )}
            {paymentData?.payment_data?.expireDate && (
              <Typography
                h1
                sx={{ fontSize: "1.3rem", textAlign: "center" }}
              >
                {lang == "ar" ? "expire Date :" : "expire Date :" + " "}
                {paymentData?.payment_data?.expireDate}{" "}
              </Typography>
            )}
            {paymentData?.payment_data?.fawryCode && (
              <Typography
                h1
                sx={{ fontSize: "1.3rem", textAlign: "center" }}
              >
                {lang == "ar" ? "كود فوري : " : "Fawry Code : " + " "}{" "}
                {paymentData?.payment_data?.fawryCode}
              </Typography>
            )}
            {paymentData?.payment_data?.amanCode && (
              <Typography
                h1
                sx={{ fontSize: "1.3rem", textAlign: "center" }}
              >
                {lang == "ar" ? "كود امان : " : "Aman Code : " + " "}{" "}
                {paymentData?.payment_data?.amanCode}
              </Typography>
            )}
            {paymentData?.payment_data?.masaryCode && (
              <Typography
                h1
                sx={{ fontSize: "1.3rem", textAlign: "center" }}
              >
                {lang == "ar" ? "كود مصاري : " : "Masary Code : " + " "}{" "}
                {paymentData?.payment_data?.masaryCode}
              </Typography>
            )}
            {qrData && <QRCode value={qrData} />}
          </Modal>
        )}
      </FormControl>
    </Box>
  );
}

const CustomRadioButton = ({ registerName, name, value, selectedValue, onChange }) => {
  const { register, formState: { errors } } = useFormContext();
  const isSelected = selectedValue == value;

  return (
    <>
      <div className={style.radioButton} style={{ borderColor: isSelected ? COLORS.SECONDARY.MAIN : 'transparent' }}>
        <input
          {...register(registerName, { required: true })}
          type="radio"
          id={`${name}-${value}`}
          name={name}
          value={value}
          checked={isSelected}
          onChange={onChange}
        />
        <label htmlFor={`${name}-${value}`} className={style.customRadio}>
          <img src={methodsImages[value]} alt={`Option ${value}`} />
        </label>
      </div>
    </>
  );
};




const CustomTextField = ({
  type = "text",
  lang,
  registerName,
  placeholder,
  isDarkMode
}) => {
  const { register, formState: { errors } } = useFormContext();

  const [isFocused, setIsFocused] = useState(false);
  const [isNotEmpty, setIsNotEmpty] = useState(false);

  useEffect(() => {
    const input = document.querySelector(`input[name="${registerName}"]`);
    setIsNotEmpty(input.value !== '');
  }, [registerName]);

  const handleBlur = (event) => {
    setIsFocused(false);
    setIsNotEmpty(event.target.value !== '');
  };

  return (
    <div className={style.container}>
      {errors[registerName] && (
        <div className={style.errorMessage}>
          {errors[registerName]?.message}
        </div>
      )}
      <div>
        <div className={`${style.inputContainer}`}
          style={{
            borderColor: errors[registerName] ? COLORS.DANGER : isFocused || isNotEmpty ? COLORS.SECONDARY.MAIN : '',
          }}
        >
          <input
            {...register(registerName)}
            type={type}
            placeholder={placeholder}
            style={{
              direction: lang === 'ar' ? 'rtl' : 'ltr',
              color: isDarkMode ? COLORS.WHITE : COLORS.BLACK,
            }}
            onFocus={() => setIsFocused(true)}
            onBlur={handleBlur}
          />

        </div>
      </div>
    </div>
  )
}