import learner from "@assets/images/Online learning-bro.png";
import { COLORS } from "@/constants/Colors/colors";
import { useSelector } from "react-redux";
import landingStyle from "../../styles/landingPage.module.css"
import FacebookOutlinedIcon from "@mui/icons-material/FacebookOutlined";
import InstagramIcon from "@mui/icons-material/Instagram";
import SocialMediaIcon from "../SocialMediaIcon/SocialMediaIcon";
import useCustomQuery from "@/hooks/useCustomQuery";
import YouTubeIcon from "@mui/icons-material/YouTube";
import XIcon from "@mui/icons-material/X";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import TelegramIcon from "@mui/icons-material/Telegram";
import AppleIcon from "@mui/icons-material/Apple";
import IconHuawei from "@shared/Icons/HuaweiIcon";
import AndroidSharpIcon from "@mui/icons-material/AndroidSharp";
import { Link as ScrollLink } from "react-scroll";





export default function Landing() {
  const globalState = useSelector((state) => state?.global);
  const lang = globalState?.lang;
  const isDarkMode = globalState?.isDarkMode;

  const { data: socials } = useCustomQuery({
    url: `socials`,
    key: ["socials", lang],
    lang,
  });


  return (
    <>
      <div className={`${landingStyle.landing} ${lang === 'ar' ? "" : "eng"}`}
        style={{ direction: lang === "ar" ? 'rtl' : "ltr", backgroundColor: isDarkMode ? COLORS.SOFT_DARK : COLORS.WHITE }}
      >
        <div className={landingStyle.landingContent} style={{ transform: lang === 'ar' ? "scale(0.87)" : "", margin: lang === "ar" ? "0 -30px" : "" }}>
          {lang === 'ar' ? <h1>المستقبل المشرق<br />للتعليم<span style={{ color: COLORS.SECONDARY.MAIN }}> الإلكتروني</span> في مصر</h1> :
            <h1>Bright Future for<br /><span style={{ color: COLORS.SECONDARY.MAIN }}>E-Learning</span> in Egypt</h1>}


          {lang === 'ar' ? <p>اكتشف قوة التعليم الإلكتروني في مصر مع مجموعة متنوعة من الدورات. <br />مستقبل التعلم هنا — انضم إلينا وكن جزءاً من التغيير.</p> :
            <p>Discover the power of e-learning in Egypt with our diverse range of courses. <br />the future of learning is here — join us and be part of the change.</p>}

          <ScrollLink to="introduction" smooth={true} duration={500}><button style={{ backgroundColor: COLORS.SECONDARY.MAIN, color: COLORS.WHITE }}>{lang === 'ar' ? "اكتشف الآن" : "Discover"}</button></ScrollLink>

          <div className={landingStyle.socialMedia}>
            {socials?.data.map((social, index) =>
              social.type == "facebook" ? (
                <SocialMediaIcon
                  key={index}
                  icon={<FacebookOutlinedIcon />}
                  link={social.link}
                />
              ) : social.type == "instagram" ? (
                <SocialMediaIcon icon={<InstagramIcon />} link={social.link} />
              ) : social.type == "youtube" ? (
                <SocialMediaIcon icon={<YouTubeIcon />} link={social.link} />
              ) : social.type == "x" ? (
                <SocialMediaIcon icon={<XIcon />} link={social.link} />
              ) : social.type == "whatsapp" ? (
                <SocialMediaIcon icon={<WhatsAppIcon />} link={social.link} />
              ) : social.type == "telegram" ? (
                <SocialMediaIcon icon={<TelegramIcon />} link={social.link} />
              ) : social.type == "tiktok" ? (
                <SocialMediaIcon
                  icon={
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="1em"
                      height="1em"
                      viewBox="0 0 24 24"
                    >
                      <path
                        fill="currentColor"
                        d="M11 2v6.414a6.85 6.85 0 1 0 5.6 6.736v-3.736a8.603 8.603 0 0 0 3.4.686h1V6.5h-1c-1.903 0-3.4-1.537-3.4-3.5V2zm2 2h1.688c.394 2.22 2.08 3.996 4.312 4.41v1.618c-1.038-.152-1.975-.542-2.843-1.123L14.6 7.863v7.287a4.85 4.85 0 1 1-4.6-4.844v1.604a3.25 3.25 0 1 0 3 3.24zM8.5 15.15a1.25 1.25 0 1 1 2.5 0a1.25 1.25 0 0 1-2.5 0"
                      ></path>
                    </svg>
                  }
                  link={social.link}
                />
              ) : social.type == "apple" ? (
                <SocialMediaIcon icon={<AppleIcon />} link={social.link} />
              ) : social.type == "huawei" ? (
                <SocialMediaIcon icon={<IconHuawei />} link={social.link} />
              ) : (
                social.type == "android" && (
                  <SocialMediaIcon icon={<AndroidSharpIcon />} link={social.link} />
                )
              )
            )}
          </div>
        </div>
        <img src={learner} alt="" />
      </div>
    </>
  );
}
