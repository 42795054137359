import {
  Box,
  Container,
  ListItem,
  ListItemText,
  Typography,
} from "@mui/material";
import useCustomQuery from "@/hooks/useCustomQuery";
import { HashLoader } from "react-spinners";
import { COLORS } from "@/constants/Colors/colors";
import { useSelector } from "react-redux";
import Tabs from '../../../components/TabbedPages/Tabs';
import pageStyle from "./MyStats.module.css"
import { PieChart } from '@mui/x-charts/PieChart';
import examImage from "../../../assets/images/ExamImage.png"
import NoDataFound from "@/components/NoDataFound/NoDataFound";


function MyStats() {
  const globalState = useSelector((state) => state?.global);
  const lang = globalState?.lang;
  const isDarkMode = globalState?.isDarkMode;

  const { data: stats, isPending: isLoadingStats } = useCustomQuery({
    key: ["stats", lang],
    url: `stats`,
    lang,
    select: (data) => data,
  });

  const pages = [
    {
      page: <Lessons lang={lang} stats={stats} isDarkMode={isDarkMode} />,
      title: lang === 'ar' ? "الدروس" : "Lessons"
    },
    {
      page: <Exams lang={lang} stats={stats} isDarkMode={isDarkMode} />,
      title: lang === 'ar' ? "الإمتحانات" : "Exams"
    }
    ,
    {
      page: <HomeWork lang={lang} stats={stats} isDarkMode={isDarkMode} />,
      title: lang === 'ar' ? "الواجبات" : "Homework"
    }
  ];

  return (
    <>
      <div>
        {isLoadingStats ? (
          <div
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "center",
              marginTop: "30px",
              marginBottom: "30px",
            }}
          >
            <HashLoader color={COLORS.PRIMARY.MAIN} />
          </div>
        ) : (
          <>
            <Tabs isDarkMode={isDarkMode} color={COLORS.SECONDARY.MAIN} pages={pages} lang={lang} />
          </>
        )}
      </div>
    </>
  );
}

export default MyStats;





const Lessons = ({ lang, stats, isDarkMode }) => {
  return (
    <div className={`${pageStyle.container} ${pageStyle.lessonsContainer}`}>
      {stats?.data?.data.length > 0 ? (
        <>

          <div className={pageStyle.lessonsCard} style={{ boxShadow: isDarkMode ? '0 0 20px 0px #8080801a inset' : '' }}>
            {stats?.data?.data.map(
              ({ name, description, progress_percentage }, index) => {
                return (
                  <div key={index}>
                    <div className={pageStyle.cart}>
                      <PieChart
                        series={[
                          {
                            data: [{ value: progress_percentage, color: COLORS.SECONDARY.MAIN }, { value: 100 - progress_percentage, color: "rgba(128, 128, 128, 0.15)" }],
                            innerRadius: 70,
                            outerRadius: 100,
                            paddingAngle: 5,
                            cornerRadius: 50,
                            startAngle: -90,
                            endAngle: 270,
                            cx: 150,
                            cy: 150,
                          }
                        ]}
                      />
                      <p>{progress_percentage}%</p>
                    </div>
                    <h2 style={{ color: COLORS.SECONDARY.MAIN }}>{name}</h2>
                    <p>{description}</p>
                  </div>
                );
              }
            )}
          </div>
        </>
      ) : (
        <div style={{ position: 'relative', width: 'calc(100vw - 100px)' }}>
          <NoDataFound title={lang == "ar" ? "لا توجد دروس بعد" : "No Lessons Yet"} />
        </div>
      )}
    </div>
  )
}


const Exams = ({ lang, stats, isDarkMode }) => {
  return (
    <div className={`${pageStyle.container} ${pageStyle.ExamsContainer}`}>

      {stats?.data?.exams?.filter((x) => x.exam.type === "Exam").length > 0 ?
        (
          <div>
            {stats?.data?.exams
              ?.filter((x) => x.exam.type === "Exam")
              .map(
                ({ user_grade, status, exam: { title, grade } }, index) => {
                  return (
                    <div className={pageStyle.examCard} key={index} style={isDarkMode ? { backgroundColor: 'rgba(18, 18, 18)', boxShadow: 'none' } : {}}>
                      <div>
                        <h2>{title}</h2>
                        <img src={examImage} alt="" />
                      </div>

                      <div>
                        <div>{user_grade || 0}<span>{lang === 'ar' ? "درجتك" : "Grade"}</span></div>
                        <div>{grade || 0}<span>{lang === 'ar' ? "إجمالي" : "Total"}</span></div>
                        <div>{((user_grade / grade) * 100).toFixed(2)}%<span>{lang === 'ar' ? "النسبة" : "Rate"}</span></div>
                      </div>

                      <div className={pageStyle.status} style={{ color: isDarkMode ? "rgba(18, 18, 18)" : COLORS.WHITE, backgroundColor: status === "Success" ? COLORS.SUCCESS : COLORS.DANGER }}>
                        {status === "Success"
                          ? lang == "ar"
                            ? "ناجح"
                            : "Success"
                          : lang == "ar"
                            ? "راسب"
                            : "Fail"}
                      </div>

                    </div>
                  );
                }
              )}
          </div>
        ) : (
          <div style={{ position: 'relative', width: 'calc(100vw - 100px)' }}>
            <NoDataFound title={lang == "ar" ? "لا توجد امتحانات بعد" : "No Exams Yet"} />
          </div>
        )}

    </div>
  )
}


const HomeWork = ({ lang, stats, isDarkMode }) => {
  return (
    <div className={`${pageStyle.container} ${pageStyle.ExamsContainer}`}>

      {stats?.data?.exams.length > 0 ? (
        <Box>
          {stats?.data?.exams
            ?.filter((x) => x.exam.type === "HomeWork")
            .map(
              ({ user_grade, status, exam: { title, grade } }, index) => {
                return (
                  <div className={pageStyle.examCard} key={index} style={isDarkMode ? { backgroundColor: 'rgba(18, 18, 18)', boxShadow: 'none' } : {}}>
                    <div>
                      <h2>{title}</h2>
                      <img src={examImage} alt="" />
                    </div>

                    <div>
                      <div>{user_grade || 0}<span>{lang === 'ar' ? "درجتك" : "Grade"}</span></div>
                      <div>{grade || 0}<span>{lang === 'ar' ? "إجمالي" : "Total"}</span></div>
                      <div>{((user_grade / grade) * 100).toFixed(2)}%<span>{lang === 'ar' ? "النسبة" : "Rate"}</span></div>
                    </div>

                    <div className={pageStyle.status} style={{ color: isDarkMode ? "rgba(18, 18, 18)" : COLORS.WHITE, backgroundColor: status === "Success" ? COLORS.SUCCESS : COLORS.DANGER }}>
                      {status === "Success"
                        ? lang == "ar"
                          ? "ناجح"
                          : "Success"
                        : lang == "ar"
                          ? "راسب"
                          : "Fail"}
                    </div>

                  </div>
                );
              }
            )}
        </Box>
      ) : (
        <div style={{ position: 'relative', width: 'calc(100vw - 100px)' }}>
          <NoDataFound title={lang == "ar" ? "لا توجد واجبات بعد" : "No Homework Yet"} />
        </div>
      )}
    </div>
  )
}