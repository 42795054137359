import { TableCell, TableHead, TableRow, Typography } from "@mui/material";
import React from "react";
import { useSelector } from 'react-redux';
import { COLORS } from '@/constants/Colors/colors';
import { FONTS } from '@/constants/Fonts/fonts';

export default function CustomTableHead({ tableHeaders, headBackground }) {
  const lang = useSelector((state) => state?.global?.lang);
  const isDarkMode = useSelector((state) => state?.global?.isDarkMode);

  return (
    <TableHead sx={{ bgcolor: headBackground, color: "white", width: "100%" }}>
      <TableRow>
        {tableHeaders != 0 ? (
          <>
            {tableHeaders?.map((head, index) => (
              <TableCell key={index} align="center"
                sx={{
                  '&:first-of-type': lang === 'ar' ? {borderTopRightRadius: '35px'}:{ borderTopLeftRadius: '35px' },
                  '&:last-of-type': lang === 'ar' ? {borderTopLeftRadius: '35px'} : { borderTopRightRadius: '35px' },
                }}
              >
                <Typography
                  color={isDarkMode ? COLORS.SOFT_DARK : COLORS.WHITE}
                  sx={{
                    fontFamily: lang === 'ar' ? FONTS.BOLD : FONTS.ENGLISH,
                    fontWeight: 'bold'
                  }}
                >
                  {head}
                </Typography>
              </TableCell>
            ))}
          </>
        ) : (
          ""
        )}
      </TableRow>
    </TableHead>
  );
}
