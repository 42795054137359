import {
  Box,
  IconButton,
  TableBody,
  TableCell,
  TableRow,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import { ROLES } from "@/constants/roles";
import { Devices, RemoveRedEye } from "@mui/icons-material";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import BlockIcon from "@mui/icons-material/Block";
import RemoveCircleOutlineIcon from "@mui/icons-material/RemoveCircleOutline";
import useLocalStorageToken from "@shared/Helpers/axios/useLocalStorageToken";
import DeleteStudent from "@/modules/students/components/DeleteStudent";
import UpdateStudent from "@/modules/students/components/UpdateStudent";
import BanStudent from "@/modules/students/components/BanStudent";
import RemoveBan from "@/modules/students/components/RemoveBan";
import StudentSubscribtions from "@/modules/students/components/StudentSubscribtions";
import DevicesBox from "@/modules/students/components/DevicesBox";
import StudentWallet from "@/modules/students/components/StudentWallet";
import { features } from "@config/features";
import { useSelector } from 'react-redux';
import { COLORS } from "@/constants/Colors/colors";

export default function CustomTableRow({
  cell,
  index,
  page,
  name,
  phone,
  parent_phone,
  city,
  email,
  banned_until,
  id,
  refetch,
  money,
}) {
 const lang = useSelector((state) => state?.global?.lang);
  const User = useLocalStorageToken();

  const [noteBook, setNoteBook] = useState(false);
  const [studentId, setStudentId] = useState();

  const openNote = (note) => {
    setNoteBook(note);
  };

  const closeNote = () => {
    setNoteBook("");
  };

  const getStudentId = (id) => {
    setStudentId(id);
    // console.log(id);
  };

  return (
    <>
      <Box>
        <DeleteStudent
          id={studentId}
          closeNote={closeNote}
          noteBook={noteBook}
          refetch={refetch}
        />
        <UpdateStudent
          id={studentId}
          closeNote={closeNote}
          noteBook={noteBook}
          refetch={refetch}
          name={name}
          city={city}
        />
        <BanStudent
          id={studentId}
          closeNote={closeNote}
          noteBook={noteBook}
          refetch={refetch}
        />
        <RemoveBan
          id={studentId}
          closeNote={closeNote}
          noteBook={noteBook}
          refetch={refetch}
        />
        <StudentSubscribtions
          id={studentId}
          closeNote={closeNote}
          noteBook={noteBook}
        />
        {/* {features.payment && (
          <StudentWallet
            id={studentId}
            closeNote={closeNote}
            noteBook={noteBook}
          />
        )} */}
        <DevicesBox id={studentId} closeNote={closeNote} noteBook={noteBook} />
      </Box>
      <TableBody>
        <TableRow>
          <TableCell align="center">{index + 1}</TableCell>
          <TableCell align="center" component="th" scope="row">
            {name}
          </TableCell>
          <TableCell align="center">{phone}</TableCell>
          <TableCell align="center">{parent_phone || "-"}</TableCell>
          <TableCell align="center">{city}</TableCell>
          <TableCell align="center">{email}</TableCell>

          <TableCell align="center">
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                gap: '10px',
              }}
            >
              {ROLES.ADMIN === User?.role && (
                <div style={{display: 'flex', alignItems: 'center', gap: '10px'}}>
                  <IconButton
                    onClick={() => {
                      openNote("del-student");
                      getStudentId(id);
                    }}
                    className="settingIcon"
                    aria-label="delete"
                    size="small"
                    sx={{
                      bgcolor: "#db3a3c",
                      position: "relative",
                      "&:hover": {
                        bgcolor: "#db3a3c",
                      },
                    }}
                  >
                    <span className="settingHover">
                      {lang == "ar" ? "حذف" : "Delete"}
                    </span>
                    <DeleteIcon fontSize="inherit" sx={{ color: "#fff" }} />
                  </IconButton>

                  <IconButton
                    className="settingIcon"
                    onClick={() => {
                      openNote("update-student");
                      getStudentId(id);
                    }}
                    aria-label="delete"
                    size="small"
                    sx={{
                      bgcolor: "#3adb44",
                      "&:hover": {
                        bgcolor: "#3adb44",
                      },
                    }}
                  >
                    <span className="settingHover">
                      {lang == "ar" ? "تعديل" : "Edit"}
                    </span>
                    <EditIcon fontSize="inherit" sx={{ color: "#fff" }} />
                  </IconButton>

                  {banned_until != null ? (
                    <IconButton
                      className="settingIcon"
                      onClick={() => {
                        openNote("remove-ban"), getStudentId(id);
                      }}
                      aria-label="delete"
                      size="small"
                      sx={{
                        bgcolor: "red",
                        "&:hover": {
                          bgcolor: "red",
                        },
                      }}
                    >
                      <span className="settingHover">
                        {lang == "ar" ? "فك الحظر" : "Remove block"}
                      </span>
                      <BlockIcon fontSize="inherit" sx={{ color: "#fff" }} />
                    </IconButton>
                  ) : (
                    <IconButton
                      className="settingIcon"
                      onClick={() => {
                        openNote("ban"), getStudentId(id);
                      }}
                      aria-label="delete"
                      size="small"
                      sx={{
                        bgcolor: "#008de2",
                        "&:hover": {
                          bgcolor: "#008de2",
                        },
                      }}
                    >
                      <span className="settingHover">
                        {lang == "ar" ? "حظر" : "Block"}
                      </span>
                      <RemoveCircleOutlineIcon
                        fontSize="inherit"
                        sx={{ color: "#fff" }}
                      />
                    </IconButton>
                  )}
                </div>
              )}
              <IconButton
                className="settingIcon"
                onClick={() => {
                  openNote("courses"), getStudentId(id);
                }}
                aria-label="delete"
                size="small"
                sx={{
                  bgcolor: "black",
                  "&:hover": {
                    bgcolor: "black",
                  },
                }}
              >
                <span className="settingHover">
                  {lang == "ar" ? "الكورسات" : "Courses"}
                </span>
                <RemoveRedEye fontSize="inherit" sx={{ color: "#fff" }} />
              </IconButton>

              <IconButton
                className="settingIcon"
                onClick={() => {
                  openNote("devices"), getStudentId(id);
                }}
                aria-label="delete"
                size="small"
                sx={{
                  bgcolor: COLORS.SECONDARY.MAIN,
                  "&:hover": {
                    bgcolor: COLORS.SECONDARY.MAIN,
                  },
                }}
              >
                <span className="settingHover">
                  {lang == "ar" ? "الأجهزة" : "Devices"}
                </span>
                <Devices fontSize="inherit" sx={{ color: "#fff" }} />
              </IconButton>
              {features.payment && <StudentWallet money={money} id={id} />}
            </Box>
          </TableCell>
        </TableRow>
      </TableBody>
    </>
  );
}
