import PropTypes from "prop-types";

export default function WhyUsStatement({ text, counter }) {
  return (
    <div className={"why-us-container"}>
      uuuuuuuuuuuuuuuuuuuuuuuuuuuuuuuuuuuuuuuuuuuuu
      {counter ? (
        <div className="n-out-container">
          <div className="n-in-container">{counter}</div>
        </div>
      ) : (
        <div className="dot"></div>
      )}
      <div>
        <p>{text}</p>
      </div>
    </div>
  );
}
WhyUsStatement.propTypes = {
  text: PropTypes.string,
  parent: PropTypes.string,
};
