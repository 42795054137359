import Modal from "@shared/Modal/Modal";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { TextField, InputAdornment, Box } from "@mui/material";
import { COLORS } from "@/constants/Colors/colors";
import { Search } from "@mui/icons-material";
import Select from "@mui/material/Select";
import LoadingButton from "@mui/lab/LoadingButton";
import MenuItem from "@mui/material/MenuItem";
import { useMutate } from "@/hooks/useMutate";
import { useParams } from "react-router-dom";
import PropTypes from "prop-types";
import { useSelector } from 'react-redux';
import Form from "@shared/Form/Form";

export const AddStudent = ({ open, setOpen, closeParent, refetch }) => {
  const lang = useSelector((state) => state?.global?.lang);
  const { handleSubmit, register, formState: { errors }, reset } = useForm({
    defaultValues: {
      days: null,
    },
  });
  const [searchData, setSearchData] = useState({
    phoneQuery: "",
    page: 1,
  });
  const [loading, setLoading] = useState({
    search: false,
  });
  const [users, setUsers] = useState(null);

  const [userId, setUserId] = useState("");
  const { mutateAsync, isPending } = useMutate();

  const { id } = useParams();

  const [submitted, setSubmitted] = useState(false);

  const onSubmit = (data) => {
    mutateAsync({
      url: `subscription/AdminSubscription`,
      body: { ...data, course_id: id, user_id: userId },
      method: "POST",
      lang,
    })
      .then(() => {
        closeParent();
        setOpen(false);
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => refetch());
  };

  return (
    <Modal open={open} closeFn={() => { setUsers(null); setOpen(false) }} zIndex={1}>
      {(users?.length) ?
        <>
          <Form
            onSubmit={handleSubmit(onSubmit)}
          >
            <h1>{lang === 'ar' ? "رقم الطالب" : "Student Phone"} : {searchData?.phoneQuery}</h1>
            <Form.TextController
              placeholder={lang == "ar" ? "عدد الايام" : "Number of days"}
              register={register}
              registername="days"
              validationRules={{
                required: lang == "ar" ? `عدد الايام مطلوب` : `Number of days is required`,
                pattern: {
                  value: /^[0-9]+$/,
                  message: lang == "ar" ? "يجب أن يكون عدد الأيام رقماً صحيحاً" : "Number of days must be a valid number",
                },
                min: {
                  value: 1,
                  message: lang == "ar" ? "يجب أن يكون عدد الأيام أكبر من 0" : "Number of days must be more than 0",
                },
              }}
              errorMessage={errors?.days && errors?.days?.message}
            />

            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={userId}
              onChange={(e) => setUserId(e.target.value)}
              displayEmpty
              sx={{

                width: "100%",
                "& .MuiOutlinedInput-root": {
                  "& fieldset": {
                    borderColor: "transparent", // Remove border initially
                    outlineColor: "transparent"
                  },
                  "&:hover fieldset": {
                    borderColor: "transparent", // Remove border on hover
                    outlineColor: "transparent"
                  },
                  "&.Mui-focused fieldset": {
                    borderColor: "green", // Green border when focused
                    outlineColor: "transparent"
                  },
                },
                "& .MuiSelect-root": {
                  "&:focus": {
                    outline: "none", // Remove outline on focus
                  },
                },
                "& .MuiSelect-placeholder": {
                  color: "gray",
                },
              }}
            >
              <MenuItem value="" disabled>
                Choose Student
              </MenuItem>
              {users?.map((user) => (
                <MenuItem key={user?.id} value={user?.id}>
                  {user?.name}
                </MenuItem>
              ))}
            </Select>

            <Form.ButtonController
              type="submit"
              isPending={isPending}
            >
              {lang == "ar" ? "اشتراك" : "subscription"}
            </Form.ButtonController>
          </Form>
        </>
        :
        <Form
          onSubmit={(e) => {
            e.preventDefault();
            setLoading((prev) => ({ ...prev, search: true }));
            mutateAsync({
              url: `users/search?phone=${searchData?.phoneQuery}&page=${searchData?.page}`,
              method: "get",
            })
              .then((res) => {
                setUsers(res?.data?.data);
              })
              .catch((error) => {
                console.log(error);
              })
              .finally(() => {
                setLoading((prev) => ({ ...prev, search: false }));
              });
          }}
        >
          <Form.TextController
            placeholder={lang == "ar" ? "رقم الطالب" : "Student number"}
            value={searchData?.phoneQuery}
            onChange={(e) =>
              setSearchData((prev) => {
                return { ...prev, phoneQuery: e.target.value };
              })
            }
            error={(!users?.length || !/^(011|010|012|015)\d{8}$/.test(searchData?.phoneQuery)) && submitted}
            helperText={submitted && (!users?.length ? lang == "ar"
              ? "رقم الطالب مطلوب"
              : "Student number is required" :
              !/^(011|010|012|015)\d{8}$/.test(searchData?.phoneQuery)
                && lang === 'ar' ? "رقم الهاتف غير صحيح" : "Phone Number invalid")}
            submitted={submitted}
          />

          <Form.ButtonController
            isPending={loading?.search}
            type="submit"
            onClick={() => setSubmitted(true)}
          >
            <Search />
            {lang === 'ar' ? "بحث" : "Search"}
          </Form.ButtonController>

        </Form>
      }
    </Modal>
  );
};

AddStudent.propTypes = {
  open: PropTypes.any,
  setOpen: PropTypes.any,
  closeParent: PropTypes.any,
  refetch: PropTypes.any,
};
