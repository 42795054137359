import useCustomQuery from "@/hooks/useCustomQuery";
import { useState } from "react";
import { useParams } from "react-router-dom";
import { Box, Typography, Button } from "@mui/material";
import { COLORS } from "@/constants/Colors/colors";
import { HashLoader } from "react-spinners";
import { ExamCreator } from "../../../../Admin/Pages/Course/ExamCreator/ExamCreator";
import { CourseExamBox } from "../../../../Admin/Pages/Course/CourseExams/CourseExamBox/CourseExamBox";
import { useSelector } from "react-redux";
import style from "./CourseExams.module.css"

export const CourseExams = () => {
  const lang = useSelector((state) => state?.global?.lang);
  const isDarkMode = useSelector((state) => state?.global?.isDarkMode);

  const { id } = useParams();
  const [open, setOpen] = useState(false);
  const { data, isPending, isError } = useCustomQuery({
    url: `exam/getCourseExamsAdmin?course_id=${id}`,
    key: [`exam of course`, id, lang],
    lang,
    select: (data) => data?.data?.data?.data,
  });

  return (
    <>
      <div className={style.buttonContainer}>
        <button
          onClick={() => setOpen(true)}
          style={{color: isDarkMode ? COLORS.SOFT_DARK : COLORS.WHITE}}
        >
          {lang == "ar" ? "أضف إمتحان" : "Add Exam"}
        </button>
      </div>
      {isPending ? (
        <Box marginTop={2}>
          <HashLoader size={20} color={COLORS.PRIMARY.MAIN} />
        </Box>
      ) : isError ? (
        <></>
      ) : data?.length == 0 ? (
        <Typography sx={{ fontSize: 30, fontFamily: "Noor-bold" }}>
          {lang == "ar" ? "لا يوجد إمتحانات" : "There are no exams"}
        </Typography>
      ) : (
        <div className={style.filesContainer}>
          {data?.map((item, index) => (
            <CourseExamBox key={index} item={item} index={index} />
          ))}
        </div>
      )}
      <ExamCreator
        type={"Course"}
        open={open}
        setOpen={setOpen}
        closeParent={() => setOpen(false)}
        id={id}
      />
    </>
  );
};
