import { useMutation } from "@tanstack/react-query";
import { useNavigate } from "react-router-dom";
import { notifyError, notifySuccess } from "../Admin/Shared/Toast/toast";
import { encrypt } from "../Admin/Shared/Helpers/encryptionHelpers";
import { USER_TOKEN } from "@/constants/tokens";
import useAxiosConfig from "../Admin/Shared/Helpers/axios/useAxiosConfig";
import { ROLES } from "@/constants/roles";
import { features } from "@config/features";
import axios from "axios";
import { api_url } from "./../constants/base_url";

export function useLogin() {
  const navigate = useNavigate();
  // const axios = useAxiosConfig();

  const { mutate, error, isError, isPending, status } = useMutation({
    mutationFn: (data) => axios.post(`${api_url}login`, data),
    onSuccess: (data) => {
      const user = data?.data?.data?.user;

      const userData = encrypt(user);
      window.localStorage.setItem(USER_TOKEN, userData);

      switch (user?.role) {
        case ROLES.ADMIN:
          notifySuccess("تم تسجيل الدخول ك ادمن");
          navigate("/dashboard");
          break;
        case ROLES.TEACHER:
          notifySuccess("تم تسجيل الدخول");
          navigate(`/dashboard/courses/${user?.id}`);
          break;
        case ROLES.SUPERVISOR:
          notifySuccess("تم تسجيل الدخول");
          navigate("/dashboard/courses");
          break;
        default:
          if (!features.student) {
            notifyError("لا يمكنك تسجيل الدخول كطالب");
          } else {
            notifySuccess("تم تسجيل الدخول");
            navigate("/home");
          }
          break;
        // notifyError("لا يمكنك تسجيل الدخول بحساب طالب");
      }
    },
    onError: (error) => {
      if (error.response.data.message == 'Undefined array key "deviceId"') {
        notifyError("لا يمكن تسجيل الدخول الى لوحه التحكم من خلال هاتف");
      } else {
        notifyError(error.response.data.message);
      }
    },
  });

  const onSubmit = (data) => {
    mutate(data);
  };
  return {
    status,
    isPending,
    error,
    isError,
    onSubmit,
  };
}
