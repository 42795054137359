import { COLORS } from "@/constants/Colors/colors";
import { FONTS } from "@/constants/Fonts/fonts";
import { Box, Typography } from "@mui/material";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { useState } from 'react';
import { useEffect } from 'react';

export default function DisplayAllHeader({ text, link, color }) {
  const globalState = useSelector((state) => state?.global);
  const lang = globalState?.lang;
  const [isHovered, setIsHovered] = useState(false);

  const [isSmallScreen, setIsSmallScreen] = useState(window.innerWidth < 600);

  useEffect(() => {
    const handleResize = () => {
      setIsSmallScreen(window.innerWidth < 600);
    };

    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);
  
  return (
    <>
      <Box
        px={6}
        mb={6}
        display={"flex"}
        justifyContent={"space-between"}
        alignItems={"center"}
      >
        <Typography
          color={color ? color : "background.main"}
          fontSize={isSmallScreen ? lang==='ar'? "28px" : '35px' : "50px"}
          fontFamily={lang === 'ar' ? FONTS.BOLD : FONTS.ENGLISH}
          fontWeight={lang === 'en' ? "bold" : ""}
        >
          {text}
        </Typography>

        <Link
          to={link}
          style={{
            fontFamily: lang === 'ar' ? FONTS.BOLD : FONTS.ENGLISH,
            fontSize: isSmallScreen ? lang==='ar'? "14px" : "16px":"18px",
            color: isHovered ? COLORS.WHITE : color ? color : "background.main",
            border: `2px solid ${color ? color : "background.main"}`,
            padding: isSmallScreen ? "2px 5px":'3px 10px',
            borderRadius: '5px',
            backgroundColor: isHovered ? color ? color : "background.main": ""
          }}

          onMouseEnter={() => setIsHovered(true)}
          onMouseLeave={() => setIsHovered(false)}
        >
          {lang == "ar" ? " عرض الكل" : "Show All"}
        </Link>
      </Box>
    </>
  );
}
